import { useTranslation } from "react-i18next";

import CContainer from "../components/container";

function SAbout() {
  const { t } = useTranslation();

  return (
    <CContainer>
      <div className="flex flex-col items-center justify-center h-[100%] w-[100%] bg-background">
        <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
          <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
            <p className="text-[20px] font-[500] self-center">{t("about")}</p>
            <div className="h-[20px]" />
            <p className="text-[16px] font-[500]">
              {t("app_version")}: {process.env.REACT_APP_VERSION}
            </p>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SAbout;
