function CCollapse({ label, isSelected, children, onClick }) {
  return (
    <>
      <p
        className={`w-[100%] text-[1.1rem] font-[600] p-[0.6rem] rounded-[0.5rem] cursor-pointer ${
          isSelected ? "bg-[#EBEBED]" : ""
        } ${
          isSelected ? "" : "border-solid border-[0.15rem] border-[#EBEBED]"
        }`}
        onClick={onClick}
      >
        {label}
      </p>
      {isSelected ? children : null}
    </>
  );
}

export default CCollapse;
