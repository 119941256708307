import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import MCamera from "../screens/camera";

import InputCameraSVG from "../assets/images/input_camera.svg";
import InputImageSVG from "../assets/images/input_image.svg";
import CancelSVG from "../assets/images/cancel.svg";

const cameraModes = {
  front: "user",
  back: "environment",
};

function CAddImage({
  label,
  image,
  className,
  pClassName,
  labelClassName,
  imageClassName,
  setImage,
  disabled,
  hideLabel,
}) {
  const [facingMode, setFacingMode] = useState(cameraModes.back);
  const [mediaStream, setMediaStream] = useState(null);

  const startCamera = async () => {
    try {
      if (mediaStream) {
        return true;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode },
      });
      setMediaStream(stream);

      if (stream) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Error accessing camera.");
      return false;
    }
  };

  const stopCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  const toggleCamera = () => {
    stopCamera();
    setFacingMode(
      facingMode == cameraModes.back ? cameraModes.front : cameraModes.back
    );
    startCamera();
  };

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, []);

  return (
    <div
      className={`w-[100%] h-[12rem] mb-[1rem] flex flex-col flex-none ${
        className ?? ""
      }`}
    >
      {label && !hideLabel ? (
        <p className={`text-sm ${pClassName ?? ""}`}>{label}</p>
      ) : (
        <></>
      )}
      {image ? (
        <div className="w-[100%] h-[100%] flex justify-center items-center relative">
          <img
            src={
              image && typeof image == "object"
                ? URL.createObjectURL(image)
                : image
            }
            className="w-[100%] h-[100%] object-cover"
          />
          <img
            src={CancelSVG}
            className="absolute top-[1rem] right-[0.5rem] h-[2rem] w-[2rem] cursor-pointer"
            onClick={() => {
              setImage(null);
            }}
          />
        </div>
      ) : (
        <div className="w-[100%] h-[100%] flex flex-row justify-center items-center">
          <div className="w-[50%] h-[100%] flex flex-col justify-center items-center border border-solid border-[#5F6368]">
            <label
              htmlFor={label}
              className={`h-[100%] w-[100%] flex flex-col justify-center items-center cursor-pointer ${
                labelClassName ?? ""
              }`}
            >
              <img src={InputImageSVG} className={`h-[2.5rem] object-cover`} />
            </label>
            <input
              id={label}
              accept="image/*"
              className="hidden"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  setImage(file);
                }
              }}
              type="file"
              value={""}
              disabled={disabled}
            />
          </div>
          <div
            className="w-[50%] h-[100%] flex flex-col justify-center items-center border border-solid border-[#5F6368]"
            onClick={async () => {
              const pass = await startCamera();
              if (pass) {
                setTimeout(() => {
                  window[`modal_camera_${label}`].showModal();
                }, 400);
              }
            }}
          >
            <img src={InputCameraSVG} className="h-[2.5rem]" />
          </div>
          {mediaStream ? (
            <MCamera
              id={label}
              callback={(_) => setImage(_)}
              mediaStream={mediaStream}
              stopCamera={stopCamera}
              toggleCamera={toggleCamera}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default CAddImage;
