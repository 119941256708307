import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { nullValidator, validate } from "../utils/validators";
import toast from "react-hot-toast";
import { maintenanceTypes } from "../utils/constants";
import { PagesInfo } from "../utils/models";

const initialState = {
  isLoading: false,
  maintenanceProjects: [],
  pagesInfo: PagesInfo.parse({
    current: 1,
  }),
  maintenanceProject: null,
};

const readMaintenanceProjects = createAsyncThunk(
  "maintenanceProjects/readMaintenanceProjects",
  async (args, { getState }) => {
    try {
      const state = getState();

      const { page_no = state.maintenanceProjects.pagesInfo.current } =
        args ?? {};

      let _body = {
        page_no,
      };

      const [pagesInfo, maintenanceProjects] =
        await AppNetworkRest.readMaintenanceProjects(_body);
      return [pagesInfo, maintenanceProjects];
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const readMaintenanceProject = createAsyncThunk(
  "maintenanceProjects/readMaintenanceProject",
  async (args, { getState }) => {
    const { id } = args;

    try {
      const maintenanceProject = await AppNetworkRest.readMaintenanceProject(
        args
      );
      return maintenanceProject;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createMaintenance = createAsyncThunk(
  "maintenanceProjects/createMaintenance",
  async (args, { getState }) => {
    try {
      const {
        type,
        departure_place,
        departure_time,
        start_time,
        end_time,
        return_place,
        return_time,
        system_type,
        system_type_other,
        object_of_maintenance,
        causes_of_fault,
        causes_of_fault_image,
        eom_problem_resolved,
        eom_problem_resolved_note,
        eom_another_maintenance_required,
        eom_another_maintenance_required_note,
        eom_replacement_material_used,
        eom_replacement_material_used_note,
        eom_has_customer_seen_work,
        eom_has_customer_seen_work_note,
        eom_no_of_photos_of_worked_carried_before,
        eom_photos_of_worked_carried_before,
        eom_no_of_photos_of_worked_carried_after,
        eom_photos_of_worked_carried_after,
        getEOMTechnicianSignature,
        getEOMClientSignature,
        page_1,
      } = args;

      if (type == maintenanceTypes.form) {
        const valError = validate([
          () => nullValidator("1. Departure place", departure_place),
          () => nullValidator("1. Departure time", departure_time),
          () => nullValidator("1. Start time", start_time),
          () => nullValidator("1. End time", end_time),
          () => nullValidator("1. Return place", return_place),
          () => nullValidator("1. Return time", return_time),
          () =>
            nullValidator("1. Object of maintenance", object_of_maintenance),
          () => nullValidator("1. Causes of fault", causes_of_fault),
          () =>
            nullValidator("1. Causes of fault image", causes_of_fault_image),
          () => nullValidator("1. Problem resolved", eom_problem_resolved),
          () =>
            nullValidator(
              "1. Another maintenance required",
              eom_another_maintenance_required
            ),
          () =>
            nullValidator(
              "1. Replacement material used",
              eom_replacement_material_used
            ),
          () =>
            nullValidator(
              "1. Has customer seen work",
              eom_has_customer_seen_work
            ),
          () =>
            nullValidator(
              "1. No of photos of worked carried before",
              eom_no_of_photos_of_worked_carried_before
            ),
          ...(eom_no_of_photos_of_worked_carried_before > 0
            ? Object.keys(eom_photos_of_worked_carried_before).map((_) => {
                return () =>
                  nullValidator(
                    `2. Photos of worked carried before - ${_}`,
                    eom_photos_of_worked_carried_before[_]
                  );
              })
            : []),
          () =>
            nullValidator(
              "1. No of photos of worked carried after",
              eom_no_of_photos_of_worked_carried_after
            ),
          ...(eom_no_of_photos_of_worked_carried_after > 0
            ? Object.keys(eom_photos_of_worked_carried_after).map((_) => {
                return () =>
                  nullValidator(
                    `2. Photos of worked carried after - ${_}`,
                    eom_photos_of_worked_carried_after[_]
                  );
              })
            : []),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      } else if (type == maintenanceTypes.images) {
        const valError = validate([() => nullValidator("Page 1", page_1)]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      }

      let eomTechnicianSignature;
      if (getEOMTechnicianSignature) {
        eomTechnicianSignature = await getEOMTechnicianSignature();
      }
      let eomClientSignature;
      if (getEOMClientSignature) {
        eomClientSignature = await getEOMClientSignature();
      }

      const str = await AppNetworkRest.createMaintenance({
        ...args,
        eom_technician_signature: eomTechnicianSignature,
        eom_client_signature: eomClientSignature,
        draft: false,
      });

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const maintenanceProjectsSlice = createSlice({
  name: "maintenanceProjects",
  initialState,
  reducers: {
    clearMaintenanceProjects: (state, action) => {
      state.maintenanceProjects = [];
      state.pagesInfo = PagesInfo.parse({
        current: 1,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readMaintenanceProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readMaintenanceProjects.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        const [pagesInfo, maintenanceProjects] = action.payload;

        state.pagesInfo = pagesInfo;

        const array = [...state.maintenanceProjects, ...maintenanceProjects];
        state.maintenanceProjects = array.filter(
          (v, i, self) => i == self.findIndex((_) => _.id == v.id)
        );
      }
    });
    builder.addCase(readMaintenanceProject.fulfilled, (state, action) => {
      if (action.payload) {
        state.maintenanceProject = action.payload;
      }
    });
    builder.addCase(createMaintenance.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createMaintenance.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export { readMaintenanceProjects, readMaintenanceProject, createMaintenance };

export const { clearMaintenanceProjects } = maintenanceProjectsSlice.actions;

export default maintenanceProjectsSlice.reducer;
