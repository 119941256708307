import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { noValidator, nullValidator, validate } from "../utils/validators";
import toast from "react-hot-toast";
import { inspectionTypes, installationTypes } from "../utils/constants";
import { PagesInfo } from "../utils/models";

const initialState = {
  isLoading: false,
  projects: [],
  pagesInfo: PagesInfo.parse({
    current: 1,
  }),
  project: null,
};

const readProjects = createAsyncThunk(
  "projects/readProjects",
  async (args, { getState }) => {
    try {
      const state = getState();

      const { page_no = state.projects.pagesInfo.current } = args ?? {};

      let _body = {
        page_no,
      };

      const [pagesInfo, projects] = await AppNetworkRest.readProjects(_body);
      return [pagesInfo, projects];
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const readProject = createAsyncThunk(
  "projects/readProject",
  async (args, { getState }) => {
    const { id } = args;

    try {
      const project = await AppNetworkRest.readProject(args);
      return project;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createInspection = createAsyncThunk(
  "projects/createInspection",
  async (args, { getState }) => {
    try {
      const {
        type,
        property_type,
        property_type_2,
        property_use,
        property_use_other,
        property_landscaping,
        es_meter,
        es_supply,
        es_supply_other,
        es_earthing_system,
        es_earthing_system_other,
        es_complaint_electrical_system,
        es_complaint_electrical_system_other,
        installation_type,
        installation_type_other,
        installation_deviation_from_south,
        installation_panel_inclination,
        cota_type,
        cota_type_other,
        cota_attic_type,
        cota_attic_type_other,
        cota_attic_height,
        cota_roof_access,
        cota_roof_access_other,
        cota_roof_measurement,
        cota_roof_picture,
        dotrapl_image,
        mr_no_of_profiles,
        mr_ac_cable,
        mr_dc_cable,
        mr_solar_cable,
        mr_blast_number,
        mr_no_of_triangles,
        mr_no_of_breakers_and_power,
        mr_extra,
        psf_power,
        psf_no_of_panels_and_types,
        psf_dc_cable,
        psf_dc_cable_no_of_strings,
        psf_ac_cable,
        psf_inverter_type,
        psf_inverter_position_image,
        psf_inverter_phase_type,
        psf_niche_for_production_counter,
        psf_system_connection_type,
        psf_system_anomalies_type,
        psf_system_anomalies,
        psf_battery_location_image,
        psf_ta_cable_length,
        psf_internet_connection,
        psf_internet_connection_type,
        psf_internet_connection_notes,
        psf_wifi_signals_near_inverter,
        pf_alternative_solution,
        extra_work,
        extra_images,
        consent_provider,
        consent_acceptance_date,
        consent_delegate_name,
        consent_delegate_attachment,
        page_1,
        page_2,
        page_3,
        getConsentClientSignature,
      } = args;

      if (type == inspectionTypes.form) {
        const valError = validate([
          () => nullValidator("1. Property", property_type),
          () => nullValidator("1. Property type", property_type_2),
          () => nullValidator("1. Property use", property_use),
          () => nullValidator("1. Property landscaping", property_landscaping),
          () => nullValidator("2. Meter", es_meter),
          () => nullValidator("2. Supply", es_supply),
          () => nullValidator("2. Earthing system", es_earthing_system),
          () =>
            nullValidator(
              "2. Complaint electrical system",
              es_complaint_electrical_system
            ),
          () => nullValidator("3. Type", installation_type),
          () =>
            nullValidator(
              "3. Deviation from south",
              installation_deviation_from_south
            ),
          () =>
            nullValidator(
              "3. Panel inclination",
              installation_panel_inclination
            ),
          () => nullValidator("4. Type", cota_type),
          () => nullValidator("4. Attic type", cota_attic_type),
          () => nullValidator("4. Roof access", cota_roof_access),
          () => nullValidator("4. Picture of the roof", cota_roof_picture),
          () =>
            nullValidator(
              "5. Drawing of the roof and possible layout image",
              dotrapl_image
            ),
          () => nullValidator("6. No. of profiles", mr_no_of_profiles),
          () => nullValidator("6. AC cable", mr_ac_cable),
          () => nullValidator("6. DC cable", mr_dc_cable),
          () => nullValidator("6. Solar cable", mr_solar_cable),
          () => nullValidator("6. Blast number", mr_blast_number),
          () => nullValidator("6. No. of triangles", mr_no_of_triangles),
          () =>
            nullValidator(
              "6. No. of breakers and power",
              mr_no_of_breakers_and_power
            ),
          () => nullValidator("7. Power", psf_power),
          () =>
            nullValidator(
              "7. No. of panels and types",
              psf_no_of_panels_and_types
            ),
          () => nullValidator("7. DC cable", psf_dc_cable),
          () =>
            nullValidator(
              "7. DC cable no. of strings",
              psf_dc_cable_no_of_strings
            ),
          () => nullValidator("7. AC cable", psf_ac_cable),
          () => nullValidator("7. Inverter type", psf_inverter_type),
          () =>
            nullValidator(
              "7. Photovoltaic system features - inverter postion image",
              psf_inverter_position_image
            ),
          () =>
            nullValidator("7. Inverter phase type", psf_inverter_phase_type),
          () =>
            nullValidator(
              "7. Niche for production counter",
              psf_niche_for_production_counter
            ),
          () =>
            nullValidator(
              "7. System connection type",
              psf_system_connection_type
            ),
          () =>
            nullValidator(
              "7. Photovoltaic system features - battery location image",
              psf_battery_location_image
            ),
          () => nullValidator("7. T/A cable length", psf_ta_cable_length),
          () => nullValidator("10. Consent provider", consent_provider),
          () =>
            consent_provider == "Delegate"
              ? nullValidator(
                  "10. Consent - delegate attachment",
                  consent_delegate_attachment
                )
              : noValidator(),
          () =>
            nullValidator(
              "10. Consent acceptance date",
              consent_acceptance_date
            ),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      } else if (type == inspectionTypes.images) {
        const valError = validate([
          () => nullValidator("Picture of the roof", cota_roof_picture),
          () =>
            nullValidator(
              "Inverter postion image",
              psf_inverter_position_image
            ),
          () =>
            nullValidator("Battery location image", psf_battery_location_image),
          () => nullValidator("Page 1", page_1),
          () => nullValidator("Page 2", page_2),
          () => nullValidator("Page 3", page_3),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      }

      let consentClientSignature;
      if (getConsentClientSignature) {
        consentClientSignature = await getConsentClientSignature();
      }

      const str = await AppNetworkRest.createInspection({
        ...args,
        consent_client_signature: consentClientSignature,
        draft: false,
      });

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createInstallation = createAsyncThunk(
  "projects/createInstallation",
  async (args, { getState }) => {
    try {
      const {
        type,
        eow_customer_present,
        eow_construction_end_date,
        eow_missing_jobs,
        eoi_photovoltaic_field_images,
        eoi_technical_room_images,
        eoi_no_of_panels_installed,
        eoi_panel_label_images,
        eoi_inverter_label_images,
        eoi_battery_installed,
        eoi_battery_serial_no_images,
        eoi_self_test,
        eoi_no_of_self_test_images,
        eoi_self_test_images,
        declaration_notatory_declarations_of_end_of_work,
        consent_client_name,
        consent_acceptance_date,
        getConsentClientSignature,
        page_1,
      } = args;

      if (type == installationTypes.form) {
        const valError = validate([
          () => nullValidator("1. Customer present", eow_customer_present),
          () =>
            nullValidator(
              "1. Construction end date",
              eow_construction_end_date
            ),
          ...Object.keys(eoi_photovoltaic_field_images).map((_) => {
            return () =>
              nullValidator(
                `2. Photovoltaic Field Image - ${_}`,
                eoi_photovoltaic_field_images[_]
              );
          }),
          ...Object.keys(eoi_technical_room_images).map((_) => {
            return () =>
              nullValidator(
                `2. Technical Room Image - ${_}`,
                eoi_technical_room_images[_]
              );
          }),
          ...(eoi_no_of_panels_installed > 0
            ? Object.keys(eoi_panel_label_images).map((_) => {
                return () =>
                  nullValidator(
                    `2. Panel Label Image - ${_}`,
                    eoi_panel_label_images[_]
                  );
              })
            : []),
          ...Object.keys(eoi_inverter_label_images).map((_) => {
            return () =>
              nullValidator(
                `2. Inverter Label Image - ${_}`,
                eoi_inverter_label_images[_]
              );
          }),
          ...(["Yes", "SÌ"].includes(eoi_battery_installed)
            ? Object.keys(eoi_battery_serial_no_images).map((_) => {
                return () =>
                  nullValidator(
                    `2. Battery Serial No Image - ${_}`,
                    eoi_battery_serial_no_images[_]
                  );
              })
            : []),
          ...(["Yes", "SÌ"].includes(eoi_self_test) &&
          eoi_no_of_self_test_images > 0
            ? Object.keys(eoi_self_test_images).map((_) => {
                return () =>
                  nullValidator(
                    `2. Self Test Image - ${_}`,
                    eoi_self_test_images[_]
                  );
              })
            : []),
          () =>
            nullValidator(
              "3. Notatory declarations of end of work",
              declaration_notatory_declarations_of_end_of_work
            ),
          () => nullValidator("4. Client name", consent_client_name),
          () => nullValidator("4. Acceptance date", consent_acceptance_date),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      } else if (type == installationTypes.images) {
        const valError = validate([() => nullValidator("Page 1", page_1)]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      }

      let consentClientSignature;
      if (getConsentClientSignature) {
        consentClientSignature = await getConsentClientSignature();
      }

      const str = await AppNetworkRest.createInstallation({
        ...args,
        consent_client_signature: consentClientSignature,
        draft: false,
      });

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    clearProjects: (state, action) => {
      state.projects = [];
      state.pagesInfo = PagesInfo.parse({
        current: 1,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readProjects.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        const [pagesInfo, projects] = action.payload;

        state.pagesInfo = pagesInfo;

        const array = [...state.projects, ...projects];
        state.projects = array.filter(
          (v, i, self) => i == self.findIndex((_) => _.id == v.id)
        );
      }
    });
    builder.addCase(readProject.fulfilled, (state, action) => {
      if (action.payload) {
        state.project = action.payload;
      }
    });
    builder.addCase(createInspection.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createInspection.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createInstallation.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createInstallation.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export { readProjects, readProject, createInspection, createInstallation };

export const { clearProjects } = projectsSlice.actions;

export default projectsSlice.reducer;
