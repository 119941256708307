import { setFile, uploadFile } from "../controllers/files";
import { filesURLSplitSequence, locales } from "./constants";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function getFileExt(filename) {
  return filename.split(".").pop();
}

function uploadNamedFileMaker({ dispatch, preName }) {
  const uploadNamedFile = async ({ file, name }) => {
    await dispatch(setFile(file));
    const _file = await dispatch(
      uploadFile({
        name: `${
          preName ? `${preName} - ` : ""
        }${name} - ${Date.now()}.${getFileExt(file.name)}`,
      })
    );
    return _file.payload;
  };
  return uploadNamedFile;
}
function uploadNamedFileOrParseMaker({ dispatch, preName }) {
  const uploadNamedFileOrParse = async ({ file, name }) => {
    const uploadNamedFile = uploadNamedFileMaker({
      dispatch,
      preName,
    });

    let _file;
    if (!file) {
      _file = file;
    } else if (file && typeof file == "object") {
      _file = await uploadNamedFile({
        file,
        name,
      });
    } else if (file && typeof file == "string") {
      _file = file;
    }

    return _file;
  };
  return uploadNamedFileOrParse;
}

function mergeObjects(obj1, obj2) {
  const merged = {};

  obj1 = obj1 ?? {};
  obj2 = obj2 ?? {};

  const obj1Keys = Object.keys(obj1);
  for (let i = 0; i < obj1Keys.length; i++) {
    const key = obj1Keys[i];
    merged[key] = obj1[key];
  }

  const obj2Keys = Object.keys(obj2);
  for (let i = 0; i < obj2Keys.length; i++) {
    const key = obj2Keys[i];
    if (!merged[key]) {
      merged[key] = obj2[key];
    }
  }

  return merged;
}

function arrayToObject(arr) {
  const obj = {};
  arr.forEach((v, i) => {
    obj[i] = v;
  });
  return obj;
}

const locale = localStorage.getItem("locale") ?? locales.italian;

function booleanToString(bool) {
  let str = null;

  if (bool == true) {
    if (locale == locales.italian) {
      str = "SÌ";
    } else {
      str = "Yes";
    }
  } else if (bool == false) {
    if (locale == locales.italian) {
      str = "NO";
    } else {
      str = "No";
    }
  }

  return str;
}

function stringToBoolean(string) {
  let bool = null;

  if (["SÌ", "Yes"].includes(string)) {
    bool = true;
  } else if (["NO", "No"].includes(string)) {
    bool = false;
  }

  return bool;
}

export {
  dataURLtoFile,
  getFileExt,
  uploadNamedFileMaker,
  uploadNamedFileOrParseMaker,
  mergeObjects,
  arrayToObject,
  booleanToString,
  stringToBoolean,
};
