import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import { i18n } from "../utils/i18n";
import { OtherProjectInstallation } from "../utils/models";

const initialState = {
  isLoading: false,
  otherProjectInstallationDraft: null,
  otherProjectInstallation: OtherProjectInstallation.parse({}),
};

const readOtherProjectInstallationDraft = createAsyncThunk(
  "otherProjectInstallationDrafts/readOtherProjectInstallationDraft",
  async (args, { getState }) => {
    const { other_project_id, type } = args;

    try {
      const otherProjectInstallationDraft =
        await AppNetworkRest.readOtherProjectInstallationDraft(args);
      return otherProjectInstallationDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createOtherProjectInstallationDraft = createAsyncThunk(
  "otherProjectInstallationDrafts/createOtherProjectInstallationDraft",
  async (args, { getState }) => {
    try {
      const { orotund } = args ?? {};

      const otherProjectInstallationDraft =
        await AppNetworkRest.createOtherProjectInstallation({
          draft: true,
          ...args,
        });

      if (otherProjectInstallationDraft && orotund) {
        toast.success(i18n.t("installation_draft_saved_successfully."));
      }

      return otherProjectInstallationDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const otherProjectInstallationDraftsSlice = createSlice({
  name: "otherProjectInstallationDrafts",
  initialState,
  reducers: {
    setOtherProjectInstallationDraft: (state, action) => {
      state.otherProjectInstallationDraft = action.payload;
    },
    setOtherProjectInstallation: (state, action) => {
      state.otherProjectInstallation = action.payload;
    },
    setOtherProjectInstallationValues: (state, action) => {
      const keys = Object.keys(action.payload ?? {});
      const values = Object.values(action.payload ?? {});

      for (let i = 0; i < keys.length; i++) {
        state.otherProjectInstallation[keys[i]] = values[i];
      }

      state.otherProjectInstallation = OtherProjectInstallation.parse(
        state.otherProjectInstallation.toMap()
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      readOtherProjectInstallationDraft.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.otherProjectInstallationDraft = action.payload;
        }
      }
    );
    builder.addCase(
      createOtherProjectInstallationDraft.pending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      createOtherProjectInstallationDraft.fulfilled,
      (state, action) => {
        state.isLoading = false;
      }
    );
  },
});

export const {
  setOtherProjectInstallationDraft,
  setOtherProjectInstallation,
  setOtherProjectInstallationValues,
} = otherProjectInstallationDraftsSlice.actions;

export {
  readOtherProjectInstallationDraft,
  createOtherProjectInstallationDraft,
};

export default otherProjectInstallationDraftsSlice.reducer;
