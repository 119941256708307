import React, { useState, useEffect, useRef } from "react";

import SCancel from "../assets/images/cancel.svg";
import STakePhoto from "../assets/images/take_photo.svg";
import SFlipCamera from "../assets/images/flip_camera.svg";

import { dataURLtoFile } from "../utils/misc";
import { useTranslation } from "react-i18next";
import CLoader from "../components/loader";

function MCamera({ id, callback, mediaStream, stopCamera, toggleCamera }) {
  const { t } = useTranslation();

  const videoRef = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    if (mediaStream) {
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    }
  }, []);

  const takePicture = async () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      context.drawImage(
        videoRef.current,
        0,
        0,
        window.innerWidth,
        window.innerHeight
      );
      const dataURL = canvasRef.current.toDataURL("image/png");
      const image = dataURLtoFile(
        dataURL,
        `${new Date().getTime() / 1000}.jpeg`
      );

      callback(image);

      window[`modal_camera_${id}`].close();

      stopCamera();
    }
  };

  return (
    <dialog id={`modal_camera_${id}`} className="modal">
      <form
        method="dialog"
        className="modal-box bg-white min-w-[90dvw] min-h-[90dvh] !p-0 !m-0"
      >
        <div className="flex flex-col items-center justify-center h-[90dvh] relative">
          <div className="absolute top-[10px] right-[10px]">
            <img
              src={SCancel}
              className="w-[30px] h-[30px] cursor-pointer"
              onClick={() => {
                window[`modal_camera_${id}`].close();
                stopCamera();
              }}
            />
          </div>
          <p className="text-[16px] font-[500]">{t("take_picture")}</p>
          <div className="mt-[5rem]" />
          {/* <img
            src={SFlipCamera}
            className="w-[30px] h-[30px] cursor-pointer self-start ml-[0.5rem]"
            onClick={toggleCamera}
          />
          <div className="mt-[1rem]" /> */}
          <video ref={videoRef} autoPlay muted />
          <canvas
            ref={canvasRef}
            className="hidden"
            width={window.innerWidth}
            height={window.innerHeight}
          />
          <div className="mt-[4rem]" />
          {mediaStream !== null && (
            <div
              onClick={takePicture}
              className="flex flex-col items-center justify-center w-[4rem] h-[4rem] rounded-full bg-white border-[2px] border-gray-300 focus:outline-none cursor-pointer"
            >
              <img src={STakePhoto} className="w-[100%] h-[100%]" />
            </div>
          )}
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}

export default MCamera;
