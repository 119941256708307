const projectStatuses = {
  created: "created",
  assigned: "assigned",
  inspected: "inspected",
  installed: "installed",
};

const inspectionTypes = {
  form: "form",
  images: "images",
};

const inspectionTechnicianTypes = {
  inspection: "inspection",
  installation: "installation",
  inspection_installation: "inspection_installation",
};

const installationTypes = {
  form: "form",
  images: "images",
};

const _timeFormat = {
  hourCycle: "h23",
  hour: "2-digit",
  minute: "2-digit",
};

const dateTimeFormatter = new Intl.DateTimeFormat("en-GB", {
  timeZone: "Europe/Rome",
  ..._timeFormat,
  day: "numeric",
  month: "numeric",
  year: "numeric",
});

const locales = {
  italian: "it",
  english: "en",
};

const splitSequence = ".&&.";
const filesURLSplitSequence = "amazonaws.com/";

const appResponseActions = {
  logout: "logout",
};

const maintenanceProjectStatuses = {
  created: "created",
  assigned: "assigned",
  submitted: "submitted",
};

const maintenanceTypes = {
  form: "form",
  images: "images",
};

const otherProjectStatuses = {
  created: "created",
  assigned: "assigned",
  inspected: "inspected",
  installed: "installed",
};

const otherProjectTechnicianTypes = {
  inspection: "inspection",
  installation: "installation",
  inspection_installation: "inspection_installation",
};

const otherProjectInspectionTypes = {
  form: "form",
  images: "images",
};

const otherProjectInstallationTypes = {
  form: "form",
  images: "images",
};

const notificationTypes = {
  project_inspection_assigned: "project_inspection_assigned",
  project_installation_assigned: "project_installation_assigned",
};

module.exports = {
  projectStatuses,
  inspectionTypes,
  inspectionTechnicianTypes,
  installationTypes,
  dateTimeFormatter,
  locales,
  splitSequence,
  filesURLSplitSequence,
  appResponseActions,
  maintenanceProjectStatuses,
  maintenanceTypes,
  otherProjectStatuses,
  otherProjectTechnicianTypes,
  otherProjectInspectionTypes,
  otherProjectInstallationTypes,
  notificationTypes,
};
