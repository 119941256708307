import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import localeENUS from "../assets/locales/en_US.json";
import localeITIT from "../assets/locales/it_IT.json";

const initLocales = async () => {
  i18n.use(initReactI18next).init({
    lng: process.env.REACT_APP_LOCALE,
    fallbackLng: "en",
    debug: true,
    resources: {
      en: {
        translation: localeENUS,
      },
      it: {
        translation: localeITIT,
      },
    },
  });

  localStorage.setItem("locale", process.env.REACT_APP_LOCALE);

  return true;
};

export default initLocales;

export { i18n };
