import DateTimePicker from "react-datetime-picker";

function CDateTimePicker({ value, onChange, disabled, className }) {
  return (
    <DateTimePicker
      className={`w-[100%] px-[1rem] py-[0.3rem] mt-[0.3rem] mb-[0.7rem] rounded-[0.5rem] border-[0.1rem] border-solid border-primary ${className}`}
      locale="en-US"
      minDate={new Date()}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default CDateTimePicker;
