import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { projectStatuses } from "../utils/constants";

import { setSelectedRouteIndex } from "../controllers/control_bar";
import { clearProjects, readProjects } from "../controllers/projects";

import CContainer from "../components/container";
import CButton from "../components/button";

function SProjects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.projects.isLoading);
  const pagesInfo = useSelector((state) => state.projects.pagesInfo);
  const projects = useSelector((state) => state.projects.projects);

  useEffect(() => {
    _init();

    return () => {
      dispatch(clearProjects());
    };
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(0));
    dispatch(
      readProjects({
        page_no: 1,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">{t("projects")}</p>
          <div className="h-[20px]" />
          <>
            {projects.length == 0 ? (
              <div className="flex flex-col items-center justify-center h-[100%] w-[100%]">
                <p className="text-[18px] font-[500] self-center">
                  {t("hi")}{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .firstName
                  }{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .lastName
                  }{" "}
                  👋!
                </p>
                <p className="text-[16px] font-[500] self-center">
                  {t("there_are_no_active_projects_for_you.")}
                </p>
              </div>
            ) : (
              <>
                {projects.map((_, index) => (
                  <CProject project={projects[index]} />
                ))}
                {pagesInfo.current < pagesInfo.total ? (
                  <>
                    <div className="mt-[0.5rem]" />
                    <CButton
                      isLoading={isLoading}
                      className="w-[50%] self-center"
                      text={t("load_more")}
                      onClick={async () => {
                        dispatch(
                          readProjects({
                            page_no: pagesInfo.current + 1,
                          })
                        );
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </>
        </div>
      </div>
    </CContainer>
  );
}

function CProject({ project }) {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col p-[10px] my-[5px] w-[100%] bg-slate-100 rounded-[10px] cursor-pointer"
      onClick={() => {
        navigate(`/project_info?id=${project.id}`);
      }}
    >
      <div className="flex flex-row w-[100%]">
        <div className="flex flex-row justify-start items-center w-[50%]">
          <p className="text-[18px] font-[600]">{project.title}</p>
        </div>
        <div className="flex flex-row justify-end items-center w-[50%]">
          <p
            className={`text-[16px] font-[600] ${
              project.status == projectStatuses.created
                ? "text-[#FF9900]"
                : project.status == projectStatuses.inspected
                ? "text-[#4A86E8]"
                : project.status == projectStatuses.installed
                ? "text-[#6AA84F]"
                : ""
            }`}
          >
            {project.status.toUpperCase()}
          </p>
        </div>
      </div>
      <div className="h-[10dvh] overflow-hidden">
        <p className="text-[18px] font-[500]">{project.description}</p>
      </div>
    </div>
  );
}

export default SProjects;
