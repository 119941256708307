import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  createOtherProjectInspection,
  readOtherProject,
} from "../controllers/other_projects";
import { setFile, uploadFile } from "../controllers/files";
import {
  createOtherProjectInspectionDraft,
  readOtherProjectInspectionDraft,
  setOtherProjectInspectionDraft,
  setOtherProjectInspection,
  setOtherProjectInspectionValues,
} from "../controllers/other_project_inspection_drafts";

import CancelSVG from "../assets/images/cancel.svg";
import SaveSVG from "../assets/images/save.svg";
import PlusSVG from "../assets/images/plus.svg";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CInput from "../components/input";
import CButton from "../components/button";
import CDialog from "../components/dialog";
import { CDropDown, CDropDownMenu } from "../components/dropdown";
import CCollapse from "../components/collapse";
import CDateTimePicker from "../components/datetimepicker";
import CAddImage from "../components/add_image";
import CCarousel from "../components/carousel";

import {
  filesURLSplitSequence,
  otherProjectInspectionTypes,
  locales,
} from "../utils/constants";
import {
  dataURLtoFile,
  uploadNamedFileOrParseMaker,
  mergeObjects,
} from "../utils/misc";
import { OtherProjectInspection } from "../utils/models";

const collapseNames = {
  property_features: "property_features",
  installation_type: "installation_type",
  technical_room: "technical_room",
  extra_work: "extra_work",
  declaration_of_consent_technical_solution:
    "declaration_of_consent_technical_solution",
};

const locale = localStorage.getItem("locale") ?? locales.italian;

function SOtherProjectInspectionForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const otherProjectID = searchParams.get("other_project_id");
  const { t } = useTranslation();

  const [
    isSavingOtherProjectInspectionDraft,
    setIsSavingOtherProjectInspectionDraft,
  ] = useState(false);

  const isLoading = useSelector((state) => state.projects.isLoading);
  const otherProject = useSelector((state) => state.otherProjects.otherProject);
  const file = useSelector((state) => state.files.file);
  const isLoadingOtherProjectInspectionDraft = useSelector(
    (state) => state.otherProjectInspectionDrafts.isLoading
  );
  const otherProjectInspectionDraft = useSelector(
    (state) => state.otherProjectInspectionDrafts.otherProjectInspectionDraft
  );
  const otherProjectInspectionState = useSelector(
    (state) => state.otherProjectInspectionDrafts.otherProjectInspection
  );

  const [openedCollapse, setOpenedCollapse] = useState(
    collapseNames.property_features
  );
  const _setOpenedCollapse = (name) => {
    if (name != openedCollapse) {
      setOpenedCollapse(name);
    } else {
      setOpenedCollapse(null);
    }
  };

  const otherProjectInspection = useMemo(() => {
    return OtherProjectInspection.parse(otherProjectInspectionState.toMap());
  }, [otherProjectInspectionState]);

  const [
    hasLoadedOtherProjectInspectionDraft,
    setHasLoadedOtherProjectInspectionDraft,
  ] = useState(false);

  const consentClientSignatureRef = useRef(null);

  // Load data
  useEffect(() => {
    if (otherProjectID) {
      dispatch(
        readOtherProjectInspectionDraft({
          other_project_id: otherProjectID,
          type: otherProjectInspectionTypes.form,
        })
      );
      dispatch(
        readOtherProject({
          id: otherProjectID,
        })
      );
    }
  }, [otherProjectID]);

  // Display load draft data
  useEffect(() => {
    if (
      !hasLoadedOtherProjectInspectionDraft &&
      otherProjectInspectionDraft &&
      otherProjectInspectionDraft.type == otherProjectInspectionTypes.form
    ) {
      window.modal_inspection_draft.showModal();
      setHasLoadedOtherProjectInspectionDraft(true);
    }
  }, [otherProjectInspectionDraft]);

  // Dismount
  useEffect(() => {
    return () => {
      setOtherProjectInspectionDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${otherProject?.client?.firstName} - ${otherProject?.client?.lastName} - Other Project Inspection`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingOtherProjectInspectionDraft(true);

    const trImages = [];
    for (let i = 0; i < otherProjectInspection.trImages.length; i++) {
      const trImage = otherProjectInspection.trImages[i];
      const note = trImage.note;
      const image = trImage.image;

      if (!note) {
        toast.error(`Technical room image - ${i + 1} - note can't be empty`);
        setIsSavingOtherProjectInspectionDraft(false);
        return false;
      }
      if (!image) {
        toast.error(`Technical room image - ${i + 1} - image can't be empty`);
        setIsSavingOtherProjectInspectionDraft(false);
        return false;
      }
    }
    for (let i = 0; i < otherProjectInspection.trImages.length; i++) {
      const trImage = otherProjectInspection.trImages[i];
      const note = trImage.note;
      const image = trImage.image;

      const _image = await uploadNamedFileOrParse({
        file: image,
        name: `Technical Room Images - ${i + 1}`,
      });

      trImages.push({
        note: note,
        image: _image,
      });
    }

    const extraImages = [];
    for (let i = 0; i < otherProjectInspection.extraImages.length; i++) {
      const extraImage = otherProjectInspection.extraImages[i];
      const note = extraImage.note;
      const image = extraImage.image;

      if (!note) {
        toast.error(`Extra image - ${i + 1} - note can't be empty`);
        setIsSavingOtherProjectInspectionDraft(false);
        return false;
      }
      if (!image) {
        toast.error(`Extra image - ${i + 1} - image can't be empty`);
        setIsSavingOtherProjectInspectionDraft(false);
        return false;
      }
    }
    for (let i = 0; i < otherProjectInspection.extraImages.length; i++) {
      const extraImage = otherProjectInspection.extraImages[i];
      const note = extraImage.note;
      const image = extraImage.image;

      const _image = await uploadNamedFileOrParse({
        file: image,
        name: `Extra Images - ${i + 1}`,
      });

      extraImages.push({
        note: note,
        image: _image,
      });
    }

    let consentDelegateAttachment = await uploadNamedFileOrParse({
      file: otherProjectInspection.consentDelegateAttachment,
      name: "Consent - Delegate Attachment",
    });

    dispatch(
      setOtherProjectInspectionValues({
        trImages,
        extraImages,
        consentDelegateAttachment,
      })
    );

    const _otherProjectInspectionDraft = await dispatch(
      createOtherProjectInspectionDraft({
        ...otherProjectInspectionState.toBody(),
        orotund,
        type: otherProjectInspectionTypes.form,
        other_project_id: otherProjectID,
      })
    );

    dispatch(
      setOtherProjectInspection(
        OtherProjectInspection.parse(
          mergeObjects(
            _otherProjectInspectionDraft.payload.toMap(),
            otherProjectInspection.toMap()
          )
        )
      )
    );

    setIsSavingOtherProjectInspectionDraft(false);

    return true;
  }

  async function getConsentClientSignature() {
    const consentClientSignature = consentClientSignatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    if (consentClientSignature) {
      const consentClientSignatureFile = dataURLtoFile(
        consentClientSignature,
        "signature.jpeg"
      );
      await dispatch(setFile(consentClientSignatureFile));
      const _consentClientSignatureFile = await dispatch(
        uploadFile({
          name: `${otherProject?.client?.firstName} - ${
            otherProject?.client?.lastName
          } - Other Project Inspection - Client Signature - ${Date.now()}.jpeg`,
        })
      );

      if (_consentClientSignatureFile.payload) {
        _consentClientSignatureFile.payload =
          _consentClientSignatureFile.payload
            .split(filesURLSplitSequence)
            .pop();
      }

      return _consentClientSignatureFile.payload;
    } else {
      toast.error("Signature can't be empty.");
      return;
    }
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("inspection")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingOtherProjectInspectionDraft ||
              isSavingOtherProjectInspectionDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedOtherProjectInspectionDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[5rem]" />
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            <CCollapse
              label={`1. ${t(collapseNames.property_features)}`}
              isSelected={openedCollapse == collapseNames.property_features}
              onClick={() =>
                _setOpenedCollapse(collapseNames.property_features)
              }
            >
              <CDropDown
                label={t("property")}
                value={otherProjectInspection.propertyType}
              >
                {(locale == locales.italian
                  ? ["Esistente", "Di recente costruzione"]
                  : ["Existing", "Newly Built"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={otherProjectInspection.propertyType == _}
                    onClick={() =>
                      dispatch(
                        setOtherProjectInspectionValues({
                          propertyType: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              <CDropDown
                label={t("property_type")}
                value={otherProjectInspection.propertyType2}
              >
                {(locale == locales.italian
                  ? ["Condominio", "Villa indipendente"]
                  : ["Condominium", "Detached villa"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={otherProjectInspection.propertyType2 == _}
                    onClick={() =>
                      dispatch(
                        setOtherProjectInspectionValues({
                          propertyType2: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              <CDropDown
                label={t("property_use")}
                value={otherProjectInspection.propertyUse}
              >
                {(locale == locales.italian
                  ? ["Casa", "Uffici", "Industria", "Altro"]
                  : ["Home", "Offices", "Industry", "Other"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={otherProjectInspection.propertyUse == _}
                    onClick={() => {
                      dispatch(
                        setOtherProjectInspectionValues({
                          propertyUse: _,
                          propertyUseOther: "",
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {otherProjectInspection.propertyUse == "Other" ||
              otherProjectInspection.propertyUse == "Altro" ? (
                <CInput
                  label={t("property_use")}
                  placeholder={t("enter_property_use")}
                  value={otherProjectInspection.propertyUseOther}
                  onChange={(e) =>
                    dispatch(
                      setOtherProjectInspectionValues({
                        propertyUseOther: e.target.value,
                      })
                    )
                  }
                />
              ) : null}
              <CDropDown
                label={t("property_landscapping")}
                value={otherProjectInspection.propertyLandscaping}
              >
                {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                  (_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={
                        otherProjectInspection.propertyLandscaping == _
                      }
                      onClick={() =>
                        dispatch(
                          setOtherProjectInspectionValues({
                            propertyLandscaping: _,
                          })
                        )
                      }
                    />
                  )
                )}
              </CDropDown>
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`2. ${t(collapseNames.installation_type)}`}
              isSelected={openedCollapse == collapseNames.installation_type}
              onClick={() =>
                _setOpenedCollapse(collapseNames.installation_type)
              }
            >
              <CDropDown
                label={t("installation_type")}
                value={otherProjectInspection.itType}
              >
                {(locale == locales.italian
                  ? ["PDC FULL + ACS", "Daikin Ibrida", "Scaldabagno PDC"]
                  : ["PDC FULL + ACS", "Daikin Ibrida", "Scaldabagno PDC"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={otherProjectInspection.itType == _}
                    onClick={() =>
                      dispatch(
                        setOtherProjectInspectionValues({
                          itType: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              {["PDC FULL + ACS"].includes(otherProjectInspection.itType) ? (
                <CDropDown
                  label={t("installation_type")}
                  value={otherProjectInspection.itTypeOther}
                >
                  {(locale == locales.italian
                    ? ["7kW+200L", "11kW+300L", "11kW+500L", "16kW+500L"]
                    : ["7kW+200L", "11kW+300L", "11kW+500L", "16kW+500L"]
                  ).map((_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={otherProjectInspection.itTypeOther == _}
                      onClick={() =>
                        dispatch(
                          setOtherProjectInspectionValues({
                            itTypeOther: _,
                          })
                        )
                      }
                    />
                  ))}
                </CDropDown>
              ) : null}
              {["Scaldabagno PDC"].includes(otherProjectInspection.itType) ? (
                <CDropDown
                  label={t("installation_type")}
                  value={otherProjectInspection.itTypeOther}
                >
                  {(locale == locales.italian
                    ? ["Ariston 100L", "Daikin 200L", "Daikin 260L"]
                    : ["Ariston 100L", "Daikin 200L", "Daikin 260L"]
                  ).map((_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={otherProjectInspection.itTypeOther == _}
                      onClick={() =>
                        dispatch(
                          setOtherProjectInspectionValues({
                            itTypeOther: _,
                          })
                        )
                      }
                    />
                  ))}
                </CDropDown>
              ) : null}
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`3. ${t(collapseNames.technical_room)}`}
              isSelected={openedCollapse == collapseNames.technical_room}
              onClick={() => _setOpenedCollapse(collapseNames.technical_room)}
            >
              <div className="flex flex-col items-start justify-start w-[100%] h-[auto]">
                <div className="flex flex-row items-center justify-between w-[100%]">
                  <p className="text-[12px]">{t("technical_room_images")}</p>
                  {isLoading &&
                  otherProjectInspection.trImages
                    .map((_) => _.image)
                    .includes(file) ? (
                    <div className="h-[40px] w-[40px]">
                      <CLoader />
                    </div>
                  ) : null}
                </div>
                <CCarousel>
                  {otherProjectInspection.trImages.map((v, i) => (
                    <div className="flex flex-col items-start justify-start w-[100%] mx-[0.5rem] flex-none">
                      <p className="text-[1rem] whitespace-nowrap">{i + 1}.</p>
                      <CInput
                        placeholder={t("enter_note")}
                        value={v.note}
                        onChange={(e) => {
                          const trImages = structuredClone(
                            otherProjectInspection.trImages
                          );
                          trImages[i].note = e.target.value;
                          dispatch(
                            setOtherProjectInspectionValues({
                              trImages: trImages,
                            })
                          );
                        }}
                      />
                      <CAddImage
                        label={`extra_images_${i + 1}`}
                        hideLabel={true}
                        image={v.image}
                        setImage={(_) => {
                          const trImages = structuredClone(
                            otherProjectInspection.trImages
                          );
                          trImages[i].image = _;
                          dispatch(
                            setOtherProjectInspectionValues({
                              trImages: trImages,
                            })
                          );
                        }}
                      />
                    </div>
                  ))}
                  <div
                    className="flex flex-col items-center justify-center w-[100%] h-[18rem] mx-[1rem] bg-[#F1F2F3] rounded-[1rem] flex-none"
                    onClick={() => {
                      const trImages = JSON.parse(
                        JSON.stringify(otherProjectInspection.trImages)
                      );
                      trImages.push({
                        note: null,
                        image: null,
                      });
                      dispatch(
                        setOtherProjectInspectionValues({
                          trImages: trImages,
                        })
                      );
                    }}
                  >
                    <img src={PlusSVG} className="w-[5rem] h-[5rem]" />
                  </div>
                </CCarousel>
              </div>
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`4. ${t(collapseNames.extra_work)}`}
              isSelected={openedCollapse == collapseNames.extra_work}
              onClick={() => _setOpenedCollapse(collapseNames.extra_work)}
            >
              <div className="flex flex-col justify-start items-start w-[100%]">
                <p className="text-[12px]">{t("extra_work_(optional)")}</p>
                <textarea
                  className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[25dvh] resize-none"
                  placeholder={t("enter_extra_work")}
                  value={otherProjectInspection.extraWork}
                  onChange={(e) =>
                    dispatch(
                      setOtherProjectInspectionValues({
                        extraWork: e.target.value,
                      })
                    )
                  }
                  type="text"
                />
              </div>
              <div className="flex flex-col items-start justify-start w-[100%] h-[auto]">
                <div className="flex flex-row items-center justify-between w-[100%]">
                  <p className="text-[12px]">{t("extra_images_(optional)")}</p>
                  {isLoading &&
                  otherProjectInspection.extraImages
                    .map((_) => _.image)
                    .includes(file) ? (
                    <div className="h-[40px] w-[40px]">
                      <CLoader />
                    </div>
                  ) : null}
                </div>
                <CCarousel>
                  {otherProjectInspection.extraImages.map((v, i) => (
                    <div className="flex flex-col items-start justify-start w-[100%] mx-[0.5rem] flex-none">
                      <p className="text-[1rem] whitespace-nowrap">{i + 1}.</p>
                      <CInput
                        placeholder={t("enter_note")}
                        value={v.note}
                        onChange={(e) => {
                          const extraImages = structuredClone(
                            otherProjectInspection.extraImages
                          );
                          extraImages[i].note = e.target.value;
                          dispatch(
                            setOtherProjectInspectionValues({
                              extraImages: extraImages,
                            })
                          );
                        }}
                      />
                      <CAddImage
                        label={`extra_images_${i + 1}`}
                        hideLabel={true}
                        image={v.image}
                        setImage={(_) => {
                          const extraImages = structuredClone(
                            otherProjectInspection.extraImages
                          );
                          extraImages[i].image = _;
                          dispatch(
                            setOtherProjectInspectionValues({
                              extraImages: extraImages,
                            })
                          );
                        }}
                      />
                    </div>
                  ))}
                  <div
                    className="flex flex-col items-center justify-center w-[100%] h-[18rem] mx-[1rem] bg-[#F1F2F3] rounded-[1rem] flex-none"
                    onClick={() => {
                      const extraImages = JSON.parse(
                        JSON.stringify(otherProjectInspection.extraImages)
                      );
                      extraImages.push({
                        note: null,
                        image: null,
                      });
                      dispatch(
                        setOtherProjectInspectionValues({
                          extraImages: extraImages,
                        })
                      );
                    }}
                  >
                    <img src={PlusSVG} className="w-[5rem] h-[5rem]" />
                  </div>
                </CCarousel>
              </div>
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`5. ${t(
                collapseNames.declaration_of_consent_technical_solution
              )}`}
              isSelected={true}
            >
              <CDropDown
                label={t("consent_provider")}
                value={otherProjectInspection.consentProvider}
              >
                {(locale == locales.italian
                  ? ["Proprietario", "Delegare"]
                  : ["Owner", "Delegate"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={otherProjectInspection.consentProvider == _}
                    onClick={() => {
                      dispatch(
                        setOtherProjectInspectionValues({
                          consentProvider: _,
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {otherProjectInspection.consentProvider == "Delegate" ||
              otherProjectInspection.consentProvider == "Delegare" ? (
                <>
                  <CInput
                    label={t("delegate_name")}
                    placeholder={t("enter_name_of_delegate")}
                    value={otherProjectInspection.consentDelegateName}
                    onChange={(e) =>
                      dispatch(
                        setOtherProjectInspectionValues({
                          consentDelegateName: e.target.value,
                        })
                      )
                    }
                  />
                  {isLoading &&
                  file == otherProjectInspection.consentDelegateAttachment ? (
                    <div className="h-[40px] w-[40px]">
                      <CLoader />
                    </div>
                  ) : null}
                  <CAddImage
                    label={t("delegate_attachment")}
                    image={otherProjectInspection.consentDelegateAttachment}
                    setImage={(_) =>
                      dispatch(
                        setOtherProjectInspectionValues({
                          consentDelegateAttachment: _,
                        })
                      )
                    }
                  />
                  <div className="mt-[5px]" />
                </>
              ) : null}
              <p className="text-[12px]">{t("date")}</p>
              <CDateTimePicker
                value={otherProjectInspection.consentAcceptanceDate}
                onChange={(_) => {
                  dispatch(
                    setOtherProjectInspectionValues({
                      consentAcceptanceDate: _,
                    })
                  );
                }}
              />
              <div className="flex flex-row items-center justify-between">
                <p className={`text-[12px]`}>{t("client_signature")}</p>
                <img
                  src={CancelSVG}
                  className="h-[30px] w-[30px] cursor-pointer"
                  onClick={() => {
                    consentClientSignatureRef?.current?.clear();
                  }}
                />
              </div>
              <SignatureCanvas
                penColor="#F1622C"
                canvasProps={{
                  className:
                    "min-h-[200px] w-[100%] border-[1px] border-[#000000] border-solid rounded-[10px]",
                }}
                ref={consentClientSignatureRef}
              />
            </CCollapse>
            <div className="flex-grow" />
            <CButton
              className="w-[100%] my-[14px]"
              text={t("continue")}
              isLoading={isLoading}
              onClick={async () => {
                const pass = await saveAndLoadDraft();

                if (pass) {
                  const res = await dispatch(
                    createOtherProjectInspection({
                      ...otherProjectInspectionState.toBody(),
                      type: otherProjectInspectionTypes.form,
                      other_project_id: otherProjectID,
                      getConsentClientSignature: getConsentClientSignature,
                    })
                  );

                  if (res?.payload) {
                    navigate("/");
                  }
                }
              }}
            />
            <CDialog
              name="modal_inspection_draft"
              title={t("inspection_draft_found")}
              description={t("do_you_want_to_load_data_from_the_saved_draft?")}
              approvalText={t("load_data")}
              onApproval={() => {
                dispatch(
                  setOtherProjectInspection(
                    OtherProjectInspection.parse(
                      mergeObjects(
                        otherProjectInspectionDraft.toMap(),
                        otherProjectInspection.toMap()
                      )
                    )
                  )
                );

                window.modal_inspection_draft.close();
              }}
              rejectionText={t("delete_draft")}
              onRejection={() => {}}
            />
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SOtherProjectInspectionForm;
