import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { forgotPassword1 } from "../controllers/auth";

import CInput from "../components/input";
import CButton from "../components/button";

function SForgotPassword1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [email, setEmail] = useState(null);

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">
          {t("forgot_password")}
        </p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <div className="h-[2%]" />
          <CInput
            label={t("email")}
            type="email"
            className="mb-[14px] mt-[5px]"
            placeholder={t("enter_your_email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="flex-grow" />
          <CButton
            className="my-[14px] w-[100%]"
            text={t("continue")}
            onClick={async () => {
              const res = await dispatch(
                forgotPassword1({
                  email,
                })
              );
              if (res.payload) {
                navigate("/forgot_password_2", { state: { email } });
              }
            }}
            isLoading={isLoading}
          />
          <div className="mt-[2%]" />
        </div>
      </div>
    </div>
  );
}

export default SForgotPassword1;
