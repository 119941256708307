import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { forgotPassword3 } from "../controllers/auth";

import CInput from "../components/input";
import CButton from "../components/button";
import toast from "react-hot-toast";

function SForgotPassword3() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const locationData = location.state;

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    if (!locationData?.email) {
      navigate(-1);
      return;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">
          {t("forgot_password")}
        </p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <div className="h-[2%]" />
          <CInput
            label={t("new_password")}
            type="password"
            className="mb-[14px] mt-[5px]"
            placeholder={t("enter_your_new_password")}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <div className="h-[2%]" />
          <CInput
            label={t("confirm_new_password")}
            type="password"
            className="mb-[14px] mt-[5px]"
            placeholder={t("enter_your_confirm_new_password")}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <div className="flex-grow" />
          <CButton
            className="my-[14px] w-[100%]"
            text={t("continue")}
            onClick={async () => {
              if (newPassword != confirmNewPassword) {
                toast.error("New password doesn't match confirm new password.");
                return;
              }

              const res = await dispatch(
                forgotPassword3({
                  email: locationData?.email,
                  password: newPassword,
                })
              );
              if (res.payload) {
                navigate("/login");
              }
            }}
            isLoading={isLoading}
          />
          <div className="mt-[2%]" />
        </div>
      </div>
    </div>
  );
}

export default SForgotPassword3;
