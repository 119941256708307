import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import controlBarReducer from "./control_bar";
import projectsReducer from "./projects";
import maintenanceProjectsReducer from "./maintenance_projects";
import inspectionDraftsReducer from "./inspection_drafts";
import installationDraftsReducer from "./installation_drafts";
import maintenanceDraftsReducer from "./maintenance_drafts";
import filesReducer from "./files";
import otherProjectsReducer from "./other_projects";
import otherProjectInspectionDraftsReducer from "./other_project_inspection_drafts";
import otherProjectInstallationDraftsReducer from "./other_project_installation_drafts";
import notificationsReducer from "./notifications";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    controlBar: controlBarReducer,
    projects: projectsReducer,
    maintenanceProjects: maintenanceProjectsReducer,
    inspectionDrafts: inspectionDraftsReducer,
    installationDrafts: installationDraftsReducer,
    maintenanceDrafts: maintenanceDraftsReducer,
    files: filesReducer,
    otherProjects: otherProjectsReducer,
    otherProjectInspectionDrafts: otherProjectInspectionDraftsReducer,
    otherProjectInstallationDrafts: otherProjectInstallationDraftsReducer,
    notifications: notificationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
