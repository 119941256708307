import React from "react";
import ReactDOM from "react-dom/client";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./css/index.css";
import SMain from "./screens/main";

import { store } from "./controllers/store";
import { Provider } from "react-redux";

import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Toaster />
    <React.StrictMode>
      <SMain />
    </React.StrictMode>
  </Provider>
);
