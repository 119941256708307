import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  createOtherProjectInstallation,
  readOtherProject,
} from "../controllers/other_projects";
import { setFile, uploadFile } from "../controllers/files";

import {
  createOtherProjectInstallationDraft,
  readOtherProjectInstallationDraft,
  setOtherProjectInstallationDraft,
  setOtherProjectInstallation,
  setOtherProjectInstallationValues,
} from "../controllers/other_project_installation_drafts";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CInput from "../components/input";
import CButton from "../components/button";
import CDialog from "../components/dialog";
import { CDropDown, CDropDownMenu } from "../components/dropdown";
import CCollapse from "../components/collapse";
import CDateTimePicker from "../components/datetimepicker";
import CAddImage from "../components/add_image";
import CCarousel from "../components/carousel";

import CancelSVG from "../assets/images/cancel.svg";
import SaveSVG from "../assets/images/save.svg";
import PlusSVG from "../assets/images/plus.svg";

import {
  dataURLtoFile,
  mergeObjects,
  uploadNamedFileOrParseMaker,
} from "../utils/misc";
import {
  filesURLSplitSequence,
  otherProjectInstallationTypes,
  locales,
} from "../utils/constants";
import { OtherProjectInstallation } from "../utils/models";

const collapseNames = {
  end_of_works: "end_of_works",
  images: "images",
  notes: "notes",
  declaration: "declaration",
  consent: "consent",
};

const locale = localStorage.getItem("locale") ?? locales.italian;

function SOtherProjectInstallationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const otherProjectID = searchParams.get("other_project_id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.projects.isLoading);
  const otherProject = useSelector((state) => state.otherProjects.otherProject);
  const file = useSelector((state) => state.files.file);
  const isLoadingOtherProjectInstallationDraft = useSelector(
    (state) => state.otherProjectInstallationDrafts.isLoading
  );
  const otherProjectInstallationDraft = useSelector(
    (state) =>
      state.otherProjectInstallationDrafts.otherProjectInstallationDraft
  );
  const otherProjectInstallationState = useSelector(
    (state) => state.otherProjectInstallationDrafts.otherProjectInstallation
  );

  const [openedCollapse, setOpenedCollapse] = useState(
    collapseNames.end_of_works
  );

  const _setOpenedCollapse = (name) => {
    if (name != openedCollapse) {
      setOpenedCollapse(name);
    } else {
      setOpenedCollapse(null);
    }
  };

  const [
    isSavingOtherProjectInstallationDraft,
    setIsSavingOtherProjectInstallationDraft,
  ] = useState(false);

  const otherProjectInstallation = useMemo(() => {
    return OtherProjectInstallation.parse(
      otherProjectInstallationState.toMap()
    );
  }, [otherProjectInstallationState]);

  const [
    hasLoadedOtherProjectInstallationDraft,
    setHasLoadedOtherProjectInstallationDraft,
  ] = useState(false);

  const consentClientSignatureRef = useRef(null);

  useEffect(() => {
    if (otherProjectID) {
      dispatch(
        readOtherProject({
          id: otherProjectID,
        })
      );
    }
  }, [otherProjectID]);

  useEffect(() => {
    if (
      !hasLoadedOtherProjectInstallationDraft &&
      otherProjectInstallationDraft &&
      otherProjectInstallationDraft.type == otherProjectInstallationTypes.form
    ) {
      window.modal_installation_draft.showModal();
      setHasLoadedOtherProjectInstallationDraft(true);
    }
  }, [otherProjectInstallationDraft]);

  useEffect(() => {
    dispatch(
      readOtherProjectInstallationDraft({
        other_project_id: otherProjectID,
        type: otherProjectInstallationTypes.form,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      setOtherProjectInstallationDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${otherProject?.client?.firstName} - ${otherProject?.client?.lastName} - Other Project Installation`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingOtherProjectInstallationDraft(true);

    const images = [];
    for (let i = 0; i < otherProjectInstallation.images.length; i++) {
      const _image = otherProjectInstallation.images[i];
      const note = _image.note;
      const image = _image.image;

      if (!note) {
        toast.error(`Image - ${i + 1} - note can't be empty`);
        setIsSavingOtherProjectInstallationDraft(false);
        return false;
      }
      if (!image) {
        toast.error(`Image - ${i + 1} - image can't be empty`);
        setIsSavingOtherProjectInstallationDraft(false);
        return false;
      }
    }
    for (let i = 0; i < otherProjectInstallation.images.length; i++) {
      const _image = otherProjectInstallation.images[i];
      const note = _image.note;
      const image = _image.image;

      const __image = await uploadNamedFileOrParse({
        file: image,
        name: `Images - ${i + 1}`,
      });

      images.push({
        note: note,
        image: __image,
      });
    }

    dispatch(
      setOtherProjectInstallationValues({
        images,
      })
    );

    const _otherProjectInstallationDraft = await dispatch(
      createOtherProjectInstallationDraft({
        ...otherProjectInstallationState.toBody(),
        orotund,
        type: otherProjectInstallationTypes.form,
        other_project_id: otherProjectID,
      })
    );

    dispatch(
      setOtherProjectInstallation(
        OtherProjectInstallation.parse(
          mergeObjects(
            _otherProjectInstallationDraft.payload.toMap(),
            otherProjectInstallation.toMap()
          )
        )
      )
    );

    setIsSavingOtherProjectInstallationDraft(false);

    return true;
  }

  async function getConsentClientSignature() {
    const consentClientSignature = consentClientSignatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    if (consentClientSignature) {
      const consentClientSignatureFile = dataURLtoFile(
        consentClientSignature,
        "signature.jpeg"
      );
      await dispatch(setFile(consentClientSignatureFile));
      const _consentClientSignatureFile = await dispatch(
        uploadFile({
          name: `${otherProject?.client?.firstName} - ${
            otherProject?.client?.lastName
          } - Other Project Installation - Client Signature - ${Date.now()}.jpeg`,
        })
      );

      if (_consentClientSignatureFile.payload) {
        _consentClientSignatureFile.payload =
          _consentClientSignatureFile.payload
            .split(filesURLSplitSequence)
            .pop();
      }

      return _consentClientSignatureFile.payload;
    } else {
      toast.error("Signature can't be empty.");
      return;
    }
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("installation")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingOtherProjectInstallationDraft ||
              isSavingOtherProjectInstallationDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedOtherProjectInstallationDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          <CCollapse
            label={`1. ${t(collapseNames.end_of_works)}`}
            isSelected={openedCollapse == collapseNames.end_of_works}
            onClick={() => _setOpenedCollapse(collapseNames.end_of_works)}
          >
            <CDropDown
              label={t("customer_presence")}
              value={otherProjectInstallation.eowCustomerPresent}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={
                      otherProjectInstallation.eowCustomerPresent == _
                    }
                    onClick={() =>
                      dispatch(
                        setOtherProjectInstallationValues({
                          eowCustomerPresent: _,
                        })
                      )
                    }
                  />
                )
              )}
            </CDropDown>
            <p className="text-[12px]">{t("construction_end_date_and_time")}</p>
            <CDateTimePicker
              value={otherProjectInstallation.eowConstructionEndDate}
              onChange={(_) =>
                dispatch(
                  setOtherProjectInstallationValues({
                    eowConstructionEndDate: _,
                  })
                )
              }
            />
            <div className="h-[2%]" />
            <CInput
              label={t("missing_jobs")}
              placeholder={t("enter_missing_jobs")}
              value={otherProjectInstallation.eowMissingJobs}
              onChange={(e) =>
                dispatch(
                  setOtherProjectInstallationValues({
                    eowMissingJobs: e.target.value,
                  })
                )
              }
            />
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse
            label={`2. ${t(collapseNames.images)}`}
            isSelected={openedCollapse == collapseNames.images}
            onClick={() => _setOpenedCollapse(collapseNames.images)}
          >
            <div className="flex flex-col items-start justify-start w-[100%] h-[auto]">
              <div className="flex flex-row items-center justify-between w-[100%]">
                <p className="text-[12px]">{t("images")}</p>
                {isLoading &&
                otherProjectInstallation.images
                  .map((_) => _.image)
                  .includes(file) ? (
                  <div className="h-[40px] w-[40px]">
                    <CLoader />
                  </div>
                ) : null}
              </div>
              <CCarousel>
                {otherProjectInstallation.images.map((v, i) => (
                  <div className="flex flex-col items-start justify-start w-[100%] mx-[0.5rem] flex-none">
                    <p className="text-[1rem] whitespace-nowrap">{i + 1}.</p>
                    <CInput
                      placeholder={t("enter_note")}
                      value={v.note}
                      onChange={(e) => {
                        const images = structuredClone(
                          otherProjectInstallation.images
                        );
                        images[i].note = e.target.value;
                        dispatch(
                          setOtherProjectInstallationValues({
                            images: images,
                          })
                        );
                      }}
                    />
                    <CAddImage
                      label={`extra_images_${i + 1}`}
                      hideLabel={true}
                      image={v.image}
                      setImage={(_) => {
                        const images = structuredClone(
                          otherProjectInstallation.images
                        );
                        images[i].image = _;
                        dispatch(
                          setOtherProjectInstallationValues({
                            images: images,
                          })
                        );
                      }}
                    />
                  </div>
                ))}
                <div
                  className="flex flex-col items-center justify-center w-[100%] h-[18rem] mx-[1rem] bg-[#F1F2F3] rounded-[1rem] flex-none"
                  onClick={() => {
                    const images = JSON.parse(
                      JSON.stringify(otherProjectInstallation.images)
                    );
                    images.push({
                      note: null,
                      image: null,
                    });
                    dispatch(
                      setOtherProjectInstallationValues({
                        images: images,
                      })
                    );
                  }}
                >
                  <img src={PlusSVG} className="w-[5rem] h-[5rem]" />
                </div>
              </CCarousel>
            </div>
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse
            label={`3. ${t(collapseNames.notes)}`}
            isSelected={openedCollapse == collapseNames.notes}
            onClick={() => _setOpenedCollapse(collapseNames.notes)}
          >
            <div className="flex flex-col justify-start items-start w-[100%]">
              <p className="text-[12px]">{t("notes")}</p>
              <textarea
                className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[25dvh] resize-none"
                placeholder={t("enter_notes")}
                value={otherProjectInstallation.notes}
                onChange={(e) =>
                  dispatch(
                    setOtherProjectInstallationValues({
                      notes: e.target.value,
                    })
                  )
                }
                type="text"
              />
            </div>
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse
            label={`4. ${t(collapseNames.declaration)}`}
            isSelected={openedCollapse == collapseNames.declaration}
            onClick={() => _setOpenedCollapse(collapseNames.declaration)}
          >
            <div className="flex flex-col justify-start items-start w-[100%]">
              <p className="text-[12px]">
                {t("notatory_declartions_of_end_of_work")}
              </p>
              <textarea
                className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[25dvh] resize-none"
                placeholder={t("enter_declarations_of_end_of_work")}
                value={
                  otherProjectInstallation.declarationNotatoryDeclarationsOfEndOfWork
                }
                onChange={(e) =>
                  dispatch(
                    setOtherProjectInstallationValues({
                      declarationNotatoryDeclarationsOfEndOfWork:
                        e.target.value,
                    })
                  )
                }
                type="text"
              />
            </div>
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse label={`5. ${t(collapseNames.consent)}`} isSelected={true}>
            <CInput
              label={t("client_name")}
              placeholder={t("enter_client_name")}
              value={otherProjectInstallation.consentClientName}
              onChange={(e) =>
                dispatch(
                  setOtherProjectInstallationValues({
                    consentClientName: e.target.value,
                  })
                )
              }
            />
            <div className="h-[2%]" />
            <p className="text-[12px]">{t("acceptance_date")}</p>
            <CDateTimePicker
              value={otherProjectInstallation.consentAcceptanceDate}
              onChange={(_) =>
                dispatch(
                  setOtherProjectInstallationValues({
                    consentAcceptanceDate: _,
                  })
                )
              }
            />
            <div className="flex flex-row items-center justify-between">
              <p className={`text-[12px]`}>{t("client_signature")}</p>
              <img
                src={CancelSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={() => {
                  consentClientSignatureRef?.current?.clear();
                }}
              />
            </div>
            <SignatureCanvas
              penColor="#3466AA"
              canvasProps={{
                className:
                  "min-h-[200px] w-[100%] border-[1px] border-[#000000] border-solid rounded-[10px]",
              }}
              ref={consentClientSignatureRef}
              clearOnResize={false}
            />
          </CCollapse>
          <div className="flex-grow" />
          <CButton
            className="w-[100%] my-[14px]"
            text={t("continue")}
            isLoading={isLoading}
            onClick={async () => {
              const pass = await saveAndLoadDraft();

              if (pass) {
                const res = await dispatch(
                  createOtherProjectInstallation({
                    ...otherProjectInstallationState.toBody(),
                    type: otherProjectInstallationTypes.form,
                    other_project_id: otherProjectID,
                    getConsentClientSignature: getConsentClientSignature,
                  })
                );

                if (res?.payload) {
                  navigate("/");
                }
              }
            }}
          />
          <CDialog
            name="modal_installation_draft"
            title={t("installation_draft_found")}
            description={t("do_you_want_to_load_data_from_the_saved_draft?")}
            approvalText={t("load_data")}
            onApproval={() => {
              dispatch(
                setOtherProjectInstallation(
                  OtherProjectInstallation.parse(
                    mergeObjects(
                      otherProjectInstallationDraft.toMap(),
                      otherProjectInstallation.toMap()
                    )
                  )
                )
              );
              window.modal_installation_draft.close();
            }}
            rejectionText={t("delete_draft")}
            onRejection={() => {}}
          />
        </div>
      </div>
    </CContainer>
  );
}

export default SOtherProjectInstallationForm;
