import CButton from "./button";

const DisconnectVehicle = ({
  name,
  title,
  description,
  approvalText,
  onApproval,
  displayRejection,
  rejectionText,
  onRejection,
}) => {
  return (
    <>
      <dialog id={name} className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white">
            <p className="text-[1.1rem] font-[800]">{title}</p>
            <div className="h-[1rem]" />
            <p className="text-[0.9rem] font-[600]">{description}</p>
            <div className="h-[2rem]" />
            <CButton
              className="w-[20hvw]"
              text={approvalText}
              onClick={onApproval}
            />
            <div className="h-[0.6rem]" />
            {displayRejection ? (
              <>
                <p
                  className="text-[1rem] font-[600] text-[#E53935] cursor-pointer"
                  onClick={onRejection}
                >
                  {rejectionText}
                </p>
              </>
            ) : null}
            <div className="h-[0.6rem]" />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default DisconnectVehicle;
