import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { createInspection, readProject } from "../controllers/projects";
import { setFile, uploadFile } from "../controllers/files";
import {
  createInspectionDraft,
  readInspectionDraft,
  setInspectionDraft,
  setInspection,
  setInspectionValues,
} from "../controllers/inspection_drafts";

import CancelSVG from "../assets/images/cancel.svg";
import SaveSVG from "../assets/images/save.svg";
import PlusSVG from "../assets/images/plus.svg";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CInput from "../components/input";
import CButton from "../components/button";
import CDialog from "../components/dialog";
import { CDropDown, CDropDownMenu } from "../components/dropdown";
import CCollapse from "../components/collapse";
import CDateTimePicker from "../components/datetimepicker";
import CAddImage from "../components/add_image";
import CCarousel from "../components/carousel";

import {
  filesURLSplitSequence,
  inspectionTypes,
  locales,
} from "../utils/constants";
import {
  dataURLtoFile,
  uploadNamedFileOrParseMaker,
  mergeObjects,
} from "../utils/misc";
import { Inspection } from "../utils/models";

const collapseNames = {
  property_features: "property_features",
  charateristics_of_the_electrical_system_present:
    "charateristics_of_the_electrical_system_present",
  type_of_installation: "type_of_installation",
  characteristics_of_the_aquiture: "characteristics_of_the_roof",
  drawing_of_the_roof_and_possible_layout:
    "drawing_of_the_roof_and_possible_layout",
  material_required_for_installation: "material_required_for_installation",
  photovoltaic_system_features: "photovoltaic_system_features",
  feasibility_of_the_product: "feasibility_of_the_product",
  extras: "extras",
  declaration_of_consent_technical_solution:
    "declaration_of_consent_technical_solution",
};

const locale = localStorage.getItem("locale") ?? locales.italian;

function SInspectionForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get("project_id");
  const { t } = useTranslation();

  const [isSavingInspectionDraft, setIsSavingInspectionDraft] = useState(false);

  const isLoading = useSelector((state) => state.projects.isLoading);
  const project = useSelector((state) => state.projects.project);
  const file = useSelector((state) => state.files.file);
  const isLoadingInspectionDraft = useSelector(
    (state) => state.inspectionDrafts.isLoading
  );
  const inspectionDraft = useSelector(
    (state) => state.inspectionDrafts.inspectionDraft
  );
  const inspectionState = useSelector(
    (state) => state.inspectionDrafts.inspection
  );

  const [openedCollapse, setOpenedCollapse] = useState(
    collapseNames.property_features
  );
  const _setOpenedCollapse = (name) => {
    if (name != openedCollapse) {
      setOpenedCollapse(name);
    } else {
      setOpenedCollapse(null);
    }
  };

  const inspection = useMemo(() => {
    return Inspection.parse(inspectionState.toMap());
  }, [inspectionState]);

  const [hasLoadedInspectionDraft, setHasLoadedInspectionDraft] =
    useState(false);

  const consentClientSignatureRef = useRef(null);

  // Load data
  useEffect(() => {
    if (projectID) {
      dispatch(
        readInspectionDraft({
          project_id: projectID,
          type: inspectionTypes.form,
        })
      );
      dispatch(
        readProject({
          id: projectID,
        })
      );
    }
  }, [projectID]);

  // Display load draft data
  useEffect(() => {
    if (
      !hasLoadedInspectionDraft &&
      inspectionDraft &&
      inspectionDraft.type == inspectionTypes.form
    ) {
      window.modal_inspection_draft.showModal();
      setHasLoadedInspectionDraft(true);
    }
  }, [inspectionDraft]);

  // Dismount
  useEffect(() => {
    return () => {
      setInspectionDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${project?.client?.firstName} - ${project?.client?.lastName} - Inspection`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingInspectionDraft(true);

    let cotaRoofPicture = await uploadNamedFileOrParse({
      file: inspection.cotaRoofPicture,
      name: "Picture of the roof",
    });
    let dotraplImage = await uploadNamedFileOrParse({
      file: inspection.dotraplImage,
      name: "Drawing of the roof and possible layout",
    });
    let psfInverterPositionImage = await uploadNamedFileOrParse({
      file: inspection.psfInverterPositionImage,
      name: "Photovoltaic System Features - Inverter Postion",
    });
    let psfBatteryLocationImage = await uploadNamedFileOrParse({
      file: inspection.psfBatteryLocationImage,
      name: "Photovoltaic System Features - Battery Location",
    });

    const extraImages = [];
    for (let i = 0; i < inspection.extraImages.length; i++) {
      const extraImage = inspection.extraImages[i];
      const note = extraImage.note;
      const image = extraImage.image;

      if (!note) {
        toast.error(`Extra image - ${i + 1} - note can't be empty`);
        setIsSavingInspectionDraft(false);
        return;
      }
      if (!image) {
        toast.error(`Extra image - ${i + 1} - image can't be empty`);
        setIsSavingInspectionDraft(false);
        return;
      }
    }
    for (let i = 0; i < inspection.extraImages.length; i++) {
      const extraImage = inspection.extraImages[i];
      const note = extraImage.note;
      const image = extraImage.image;

      const _image = await uploadNamedFileOrParse({
        file: image,
        name: `Extra Images - ${i + 1}`,
      });

      extraImages.push({
        note: note,
        image: _image,
      });
    }

    let consentDelegateAttachment = await uploadNamedFileOrParse({
      file: inspection.consentDelegateAttachment,
      name: "Consent - Delegate Attachment",
    });

    dispatch(
      setInspectionValues({
        cotaRoofPicture,
        dotraplImage,
        psfInverterPositionImage,
        psfBatteryLocationImage,
        extraImages,
        consentDelegateAttachment,
      })
    );

    const _inspectionDraft = await dispatch(
      createInspectionDraft({
        ...inspectionState.toBody(),
        orotund,
        type: inspectionTypes.form,
        project_id: projectID,
      })
    );

    dispatch(
      setInspection(
        Inspection.parse(
          mergeObjects(_inspectionDraft.payload.toMap(), inspection.toMap())
        )
      )
    );

    setIsSavingInspectionDraft(false);

    return;
  }

  async function getConsentClientSignature() {
    const consentClientSignature = consentClientSignatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    if (consentClientSignature) {
      const consentClientSignatureFile = dataURLtoFile(
        consentClientSignature,
        "signature.jpeg"
      );
      await dispatch(setFile(consentClientSignatureFile));
      const _consentClientSignatureFile = await dispatch(
        uploadFile({
          name: `${project?.client?.firstName} - ${
            project?.client?.lastName
          } - Inspection - Client Signature - ${Date.now()}.jpeg`,
        })
      );

      if (_consentClientSignatureFile.payload) {
        _consentClientSignatureFile.payload =
          _consentClientSignatureFile.payload
            .split(filesURLSplitSequence)
            .pop();
      }

      return _consentClientSignatureFile.payload;
    } else {
      toast.error("Signature can't be empty.");
      return;
    }
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("inspection")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingInspectionDraft || isSavingInspectionDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedInspectionDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[5rem]" />
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            <CCollapse
              label={`1. ${t(collapseNames.property_features)}`}
              isSelected={openedCollapse == collapseNames.property_features}
              onClick={() =>
                _setOpenedCollapse(collapseNames.property_features)
              }
            >
              <CDropDown label={t("property")} value={inspection.propertyType}>
                {(locale == locales.italian
                  ? ["Esistente", "Di recente costruzione"]
                  : ["Existing", "Newly Built"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.propertyType == _}
                    onClick={() =>
                      dispatch(
                        setInspectionValues({
                          propertyType: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              <CDropDown
                label={t("property_type")}
                value={inspection.propertyType2}
              >
                {(locale == locales.italian
                  ? ["Condominio", "Villa indipendente"]
                  : ["Condominium", "Detached villa"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.propertyType2 == _}
                    onClick={() =>
                      dispatch(
                        setInspectionValues({
                          propertyType2: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              <CDropDown
                label={t("property_use")}
                value={inspection.propertyUse}
              >
                {(locale == locales.italian
                  ? ["Casa", "Uffici", "Industria", "Altro"]
                  : ["Home", "Offices", "Industry", "Other"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.propertyUse == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          propertyUse: _,
                          propertyUseOther: "",
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {inspection.propertyUse == "Other" ||
              inspection.propertyUse == "Altro" ? (
                <CInput
                  label={t("property_use")}
                  placeholder={t("enter_property_use")}
                  value={inspection.propertyUseOther}
                  onChange={(e) =>
                    dispatch(
                      setInspectionValues({
                        propertyUseOther: e.target.value,
                      })
                    )
                  }
                />
              ) : null}
              <CDropDown
                label={t("property_landscapping")}
                value={inspection.propertyLandscaping}
              >
                {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                  (_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={inspection.propertyLandscaping == _}
                      onClick={() =>
                        dispatch(
                          setInspectionValues({
                            propertyLandscaping: _,
                          })
                        )
                      }
                    />
                  )
                )}
              </CDropDown>
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`2. ${t(
                collapseNames.charateristics_of_the_electrical_system_present
              )}`}
              isSelected={
                openedCollapse ==
                collapseNames.charateristics_of_the_electrical_system_present
              }
              onClick={() =>
                _setOpenedCollapse(
                  collapseNames.charateristics_of_the_electrical_system_present
                )
              }
            >
              <CDropDown label={t("meter")} value={inspection.esMeter}>
                {(locale == locales.italian
                  ? ["Monofase", "Trifase"]
                  : ["Single-Phase", "Three-Phase"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.esMeter == _}
                    onClick={() =>
                      dispatch(
                        setInspectionValues({
                          esMeter: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              <CDropDown label={t("supply")} value={inspection.esSupply}>
                {(locale == locales.italian
                  ? ["ENEL", "ACAE", "Altro"]
                  : ["ENEL", "ACAE", "Other"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.esSupply == _}
                    onClick={() =>
                      dispatch(
                        setInspectionValues({
                          esSupply: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              {["Other", "Altro"].includes(inspection.esSupplyOther) ? (
                <CInput
                  label={t("supply")}
                  placeholder={t("enter_supply")}
                  value={inspection.esSupplyOther}
                  onChange={(e) =>
                    dispatch(
                      setInspectionValues({
                        esSupplyOther: e.target.value,
                      })
                    )
                  }
                />
              ) : null}
              <CDropDown
                label={t("earthing_system")}
                value={inspection.esEarthingSystem}
              >
                {(locale == locales.italian
                  ? ["Presente", "Assente", "Lavoro necessario"]
                  : ["Present", "Absent", "Necessary work"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.esEarthingSystem == _}
                    onClick={() =>
                      dispatch(
                        setInspectionValues({
                          esEarthingSystem: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              {["Necessary work", "Lavoro necessario"].includes(
                inspection.esEarthingSystem
              ) ? (
                <CInput
                  label={t("earthing_system")}
                  placeholder={t("enter_earthing_system")}
                  value={inspection.esEarthingSystemOther}
                  onChange={(e) =>
                    dispatch(
                      setInspectionValues({
                        esEarthingSystemOther: e.target.value,
                      })
                    )
                  }
                />
              ) : null}
              <CDropDown
                label={t("complaint_electrical_system")}
                value={inspection.esComplaintElectricalSystem}
              >
                {(locale == locales.italian
                  ? ["SÌ", "NO", "Lavoro necessario"]
                  : ["Yes", "No", "Necessary work"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.esComplaintElectricalSystem == _}
                    onClick={() =>
                      dispatch(
                        setInspectionValues({
                          esComplaintElectricalSystem: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
              {["Necessary work", "Lavoro necessario"].includes(
                inspection.esComplaintElectricalSystem
              ) ? (
                <CInput
                  label={t("complaint_electrical_system")}
                  placeholder={t("enter_complaint_electrical_system")}
                  value={inspection.esComplaintElectricalSystemOther}
                  onChange={(e) =>
                    dispatch(
                      setInspectionValues({
                        esComplaintElectricalSystemOther: e.target.value,
                      })
                    )
                  }
                />
              ) : null}
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`3. ${t(collapseNames.type_of_installation)}`}
              isSelected={openedCollapse == collapseNames.type_of_installation}
              onClick={() =>
                _setOpenedCollapse(collapseNames.type_of_installation)
              }
            >
              <CDropDown label={t("type")} value={inspection.installationType}>
                {(locale == locales.italian
                  ? [
                      "Tetto a terrazza",
                      "Tetto spiovente",
                      "Capannone/Rifugio*",
                      "Altro",
                    ]
                  : ["Flat roof", "Pitched roof", "Shed/Shelter*", "Other"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.installationType == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          installationType: _,
                          installationTypeOther: "",
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {inspection.installationType == "Other" ||
              inspection.installationType == "Altro" ? (
                <>
                  <CInput
                    label={t("type")}
                    placeholder={t("enter_type")}
                    value={inspection.installationTypeOther}
                    onChange={(e) =>
                      dispatch(
                        setInspectionValues({
                          installationTypeOther: e.target.value,
                        })
                      )
                    }
                  />
                </>
              ) : null}
              <CInput
                label={t("deviation_from_south_(azimut)_in_degrees")}
                placeholder={t("enter_deviation_from_south_(azimut)")}
                value={inspection.installationDeviationFromSouth}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      installationDeviationFromSouth: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("panel_inclination_(tilt)_in_degrees")}
                placeholder={t("panel_inclination_(tilt)")}
                value={inspection.installationPanelInclination}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      installationPanelInclination: e.target.value,
                    })
                  )
                }
              />
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`4. ${t(collapseNames.characteristics_of_the_aquiture)}`}
              isSelected={
                openedCollapse == collapseNames.characteristics_of_the_aquiture
              }
              onClick={() =>
                _setOpenedCollapse(
                  collapseNames.characteristics_of_the_aquiture
                )
              }
            >
              <CDropDown label={t("type")} value={inspection.cotaType}>
                {(locale == locales.italian
                  ? [
                      "Piastrella",
                      "Lamiera ondulata",
                      "Bitume",
                      "Ghiaia",
                      "Altro",
                    ]
                  : [
                      "Tile",
                      "Corrugated sheet metal",
                      "Bitumen",
                      "Gravel",
                      "Other",
                    ]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.cotaType == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          cotaType: _,
                          cotaTypeOther: "",
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {inspection.cotaType == "Other" ||
              inspection.cotaType == "Altro" ? (
                <>
                  <CInput
                    label={t("type")}
                    placeholder={t("enter_type")}
                    value={inspection.cotaTypeOther}
                    onChange={(e) =>
                      dispatch(
                        setInspectionValues({
                          cotaTypeOther: e.target.value,
                        })
                      )
                    }
                  />
                </>
              ) : null}
              <CDropDown
                label={t("attic_type")}
                value={inspection.cotaAtticType}
              >
                {(locale == locales.italian
                  ? [
                      "Guaina",
                      "Massetto",
                      "Travi in ​​legno",
                      "Tella/blocco grande",
                      "Altro",
                    ]
                  : [
                      "Sheath",
                      "Screed",
                      "Wooden beams",
                      "Tella/large block",
                      "Other",
                    ]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.cotaAtticType == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          cotaAtticType: _,
                        })
                      );
                      if (
                        (locale == locales.italian
                          ? [
                              "Massetto",
                              "Travi in ​​legno",
                              "Tella/blocco grande",
                            ]
                          : ["Screed", "Wooden beams", "Tella/large block"]
                        ).includes(inspection.cotaAtticType)
                      ) {
                        dispatch(
                          setInspectionValues({
                            cotaAtticHeight: "",
                          })
                        );
                      }
                      if (
                        inspection.cotaAtticType != "Other" ||
                        inspection.cotaAtticType != "Altro"
                      ) {
                        dispatch(
                          setInspectionValues({
                            cotaAtticTypeOther: "",
                          })
                        );
                      }
                    }}
                  />
                ))}
              </CDropDown>
              {(locale == locales.italian
                ? ["Massetto", "Travi in ​​legno", "Tella/blocco grande"]
                : ["Screed", "Wooden beams", "Tella/large block"]
              ).includes(inspection.cotaAtticType) ? (
                <>
                  <CInput
                    label={t("attic_height_in_cm")}
                    placeholder={t("enter_attic_height")}
                    value={inspection.cotaAtticHeight}
                    onChange={(e) =>
                      dispatch(
                        setInspectionValues({
                          cotaAtticHeight: e.target.value,
                        })
                      )
                    }
                  />
                </>
              ) : null}
              {inspection.cotaAtticType == "Other" ||
              inspection.cotaAtticType == "Altro" ? (
                <>
                  <CInput
                    label={t("attic_type")}
                    placeholder={t("enter_attic_type")}
                    value={inspection.cotaAtticTypeOther}
                    onChange={(e) =>
                      dispatch(
                        setInspectionValues({
                          cotaAtticTypeOther: e.target.value,
                        })
                      )
                    }
                  />
                </>
              ) : null}
              <CDropDown
                label={t("access_to_the_roof")}
                value={inspection.cotaRoofAccess}
              >
                {(locale == locales.italian
                  ? ["Velux", "Scala esterna", "Cestino", "Altro"]
                  : ["Velux", "External staircase", "Basket", "Other"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.cotaRoofAccess == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          cotaRoofAccess: _,
                          cotaRoofAccessOther: "",
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {inspection.cotaRoofAccess == "Other" ||
              inspection.cotaRoofAccess == "Altro" ? (
                <CInput
                  label={t("roof_access")}
                  placeholder={t("enter_roof_access")}
                  value={inspection.cotaRoofAccessOther}
                  onChange={(e) =>
                    dispatch(
                      setInspectionValues({
                        cotaRoofAccessOther: e.target.value,
                      })
                    )
                  }
                />
              ) : null}
              <CInput
                label={t("roof_measurement_in_meters")}
                placeholder={t("enter_roof_measurement")}
                value={inspection.cotaRoofMeasurement}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      cotaRoofMeasurement: e.target.value,
                    })
                  )
                }
              />
              {isLoading && file == inspection.cotaRoofPicture ? (
                <div className="h-[40px] w-[40px]">
                  <CLoader />
                </div>
              ) : null}
              <CAddImage
                label={t("picture_of_the_roof")}
                image={inspection.cotaRoofPicture}
                setImage={(_) =>
                  dispatch(
                    setInspectionValues({
                      cotaRoofPicture: _,
                    })
                  )
                }
              />
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`5. ${t(
                collapseNames.drawing_of_the_roof_and_possible_layout
              )}`}
              isSelected={
                openedCollapse ==
                collapseNames.drawing_of_the_roof_and_possible_layout
              }
              onClick={() =>
                _setOpenedCollapse(
                  collapseNames.drawing_of_the_roof_and_possible_layout
                )
              }
            >
              <p className="text-[18px] font-[900]">
                {t(
                  "*_n.b._please_report_(south)_orientation,_measurements,_obstacles_(chimneys,_velux,_etc...)_and_panel_layout"
                )}
              </p>
              {isLoading && file == inspection.dotraplImage ? (
                <div className="h-[40px] w-[40px]">
                  <CLoader />
                </div>
              ) : null}
              <CAddImage
                label={t("drawing_of_the_roof_and_possible_layout")}
                image={inspection.dotraplImage}
                setImage={(_) =>
                  dispatch(
                    setInspectionValues({
                      dotraplImage: _,
                    })
                  )
                }
              />
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`6. ${t(
                collapseNames.material_required_for_installation
              )}`}
              isSelected={
                openedCollapse ==
                collapseNames.material_required_for_installation
              }
              onClick={() =>
                _setOpenedCollapse(
                  collapseNames.material_required_for_installation
                )
              }
            >
              <CInput
                label={t("number_of_profiles")}
                placeholder={t("enter_number_of_profiles")}
                value={inspection.mrNoOfProfiles}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrNoOfProfiles: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("ac_cable_in_meters")}
                placeholder={t("enter_length_of_ac_cable")}
                value={inspection.mrACCable}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrACCable: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("dc_cable_in_meters")}
                placeholder={t("enter_length_of_dc_cable")}
                value={inspection.mrDCCable}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrDCCable: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("solar_cable_in_meters")}
                placeholder={t("enter_length_of_solar_cable")}
                value={inspection.mrSolarCable}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrSolarCable: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("blast_number")}
                placeholder={t("enter_blast_number")}
                value={inspection.mrBlastNumber}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrBlastNumber: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("number_of_triangles")}
                placeholder={t("enter_number_of_triangles")}
                value={inspection.mrNoOfTriangles}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrNoOfTriangles: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("number_of_breakers_and_power")}
                placeholder={t("enter_number_of_breakers_and_power")}
                value={inspection.mrNoOfBreakersAndPower}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrNoOfBreakersAndPower: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("any_extra_material_required_(optional)")}
                placeholder={t("enter_any_extra_material_required")}
                value={inspection.mrExtra}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      mrExtra: e.target.value,
                    })
                  )
                }
              />
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`7. ${t(collapseNames.photovoltaic_system_features)}`}
              isSelected={
                openedCollapse == collapseNames.photovoltaic_system_features
              }
              onClick={() =>
                _setOpenedCollapse(collapseNames.photovoltaic_system_features)
              }
            >
              <CInput
                label={t("power_in_kw")}
                placeholder={t("enter_power")}
                value={inspection.psfPower}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      psfPower: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("number_of_panels_and_types")}
                placeholder={t("enter_no_of_panels_and_types")}
                value={inspection.psfNoOfPanelsAndTypes}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      psfNoOfPanelsAndTypes: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("dc_cable_in_meters")}
                placeholder={t("enter_length_of_dc_cable")}
                value={inspection.psfDCCable}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      psfDCCable: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("dc_cable_number_of_strings")}
                placeholder={t("enter_dc_cable_number_of_strings")}
                value={inspection.psfDCCableNoOfStrings}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      psfDCCableNoOfStrings: e.target.value,
                    })
                  )
                }
              />
              <CInput
                label={t("ac_cable_in_meters")}
                placeholder={t("enter_length_of_ac_cable")}
                value={inspection.psfACCable}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      psfACCable: e.target.value,
                    })
                  )
                }
              />
              <CDropDown
                label={t("inverter")}
                value={inspection.psfInverterType}
              >
                {(locale == locales.italian
                  ? ["Esterno", "Interno"]
                  : ["External", "Internal"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.psfInverterType == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          psfInverterType: _,
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {isLoading && file == inspection.psfInverterPositionImage ? (
                <div className="h-[40px] w-[40px]">
                  <CLoader />
                </div>
              ) : null}
              <CAddImage
                label={t("inverter_position")}
                image={inspection.psfInverterPositionImage}
                setImage={(_) =>
                  dispatch(
                    setInspectionValues({
                      psfInverterPositionImage: _,
                    })
                  )
                }
              />
              <CDropDown
                label={t("inverter_type")}
                value={inspection.psfInverterPhaseType}
              >
                {(locale == locales.italian
                  ? ["Monofase", "Trifase"]
                  : ["Single-phase", "Three-phase"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.psfInverterPhaseType == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          psfInverterPhaseType: _,
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              <CDropDown
                label={t("niche_for_production_counter")}
                value={inspection.psfNicheForProductionCounter}
              >
                {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                  (_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={inspection.psfNicheForProductionCounter == _}
                      onClick={() => {
                        dispatch(
                          setInspectionValues({
                            psfNicheForProductionCounter: _,
                          })
                        );
                      }}
                    />
                  )
                )}
              </CDropDown>
              <CDropDown
                label={t("system_connection")}
                value={inspection.psfSystemConnectionType}
              >
                {(locale == locales.italian
                  ? ["Pannello generale", "Metro"]
                  : ["General panel", "Meter"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.psfSystemConnectionType == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          psfSystemConnectionType: _,
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              <CDropDown
                label={t("anomalies_detected")}
                value={inspection.psfSystemAnomaliesType}
              >
                {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                  (_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={inspection.psfSystemAnomaliesType == _}
                      onClick={() =>
                        dispatch(
                          setInspectionValues({
                            psfSystemAnomaliesType: _,
                          })
                        )
                      }
                    />
                  )
                )}
              </CDropDown>
              {["Yes", "SÌ"].includes(inspection.psfSystemAnomaliesType) ? (
                <CInput
                  label={t(
                    "anomalies_detectable_in_the_upstream_electrical_system"
                  )}
                  placeholder={t(
                    "enter_anomalies_detectable_in_the_upstream_electrical_system"
                  )}
                  value={inspection.psfSystemAnomalies}
                  onChange={(e) =>
                    dispatch(
                      setInspectionValues({
                        psfSystemAnomalies: e.target.value,
                      })
                    )
                  }
                />
              ) : null}
              <CAddImage
                label={t("battery_location")}
                image={inspection.psfBatteryLocationImage}
                setImage={(_) =>
                  dispatch(
                    setInspectionValues({
                      psfBatteryLocationImage: _,
                    })
                  )
                }
              />
              <CInput
                label={t("t/a_cable_length_in_meters")}
                placeholder={t("enter_t/a_cable_length")}
                value={inspection.psfTACableLength}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      psfTACableLength: e.target.value,
                    })
                  )
                }
              />
              <CDropDown
                label={t("internet_connection_present")}
                value={inspection.psfInternetConnection}
              >
                {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                  (_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={inspection.psfInternetConnection == _}
                      onClick={() =>
                        dispatch(
                          setInspectionValues({
                            psfInternetConnection: _,
                          })
                        )
                      }
                    />
                  )
                )}
              </CDropDown>
              {["Yes", "SÌ"].includes(inspection.psfInternetConnection) ? (
                <CDropDown
                  label={t("internet_connection")}
                  value={inspection.psfInternetConnectionType}
                >
                  {(locale == locales.italian
                    ? ["Wifi", "LAN"]
                    : ["Wifi", "LAN"]
                  ).map((_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={inspection.psfInternetConnectionType == _}
                      onClick={() => {
                        dispatch(
                          setInspectionValues({
                            psfInternetConnectionType: _,
                          })
                        );
                      }}
                    />
                  ))}
                </CDropDown>
              ) : null}
              <CInput
                label={t("internet_connection_notes_(optional)")}
                placeholder={t("enter_internet_connection_notes")}
                value={inspection.psfInternetConnectionNotes}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      psfInternetConnectionNotes: e.target.value,
                    })
                  )
                }
              />
              <CDropDown
                label={t("wifi_signals_where_inverter_is_provided")}
                value={inspection.psfWifiSignalsNearInverter}
              >
                {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                  (_) => (
                    <CDropDownMenu
                      title={_}
                      isSelected={inspection.psfWifiSignalsNearInverter == _}
                      onClick={() => {
                        dispatch(
                          setInspectionValues({
                            psfWifiSignalsNearInverter: _,
                          })
                        );
                      }}
                    />
                  )
                )}
              </CDropDown>
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`8. ${t(collapseNames.feasibility_of_the_product)}`}
              isSelected={
                openedCollapse == collapseNames.feasibility_of_the_product
              }
              onClick={() =>
                _setOpenedCollapse(collapseNames.feasibility_of_the_product)
              }
            >
              <p className="text-[18px] font-[900]">
                {t(
                  "*_if_it_is_not_possible_to_confirm_the_feasibility_of_the_product,_please_propose:"
                )}
              </p>
              <CInput
                label={t("alternative_solution_(optional)")}
                placeholder={t("enter_alternative_solution")}
                value={inspection.pfAlternativeSolution}
                onChange={(e) =>
                  dispatch(
                    setInspectionValues({
                      pfAlternativeSolution: e.target.value,
                    })
                  )
                }
              />
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`9. ${t(collapseNames.extras)}`}
              isSelected={openedCollapse == collapseNames.extras}
              onClick={() => _setOpenedCollapse(collapseNames.extras)}
            >
              <div className="flex flex-col justify-start items-start w-[100%]">
                <p className="text-[12px]">{t("extra_work_(optional)")}</p>
                <textarea
                  className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[25dvh] resize-none"
                  placeholder={t("enter_extra_work")}
                  value={inspection.extraWork}
                  onChange={(e) =>
                    dispatch(
                      setInspectionValues({
                        extraWork: e.target.value,
                      })
                    )
                  }
                  type="text"
                />
              </div>
              <div className="flex flex-col items-start justify-start w-[100%] h-[auto]">
                <div className="flex flex-row items-center justify-between w-[100%]">
                  <p className="text-[12px]">{t("extra_images_(optional)")}</p>
                  {isLoading &&
                  inspection.extraImages.map((_) => _.image).includes(file) ? (
                    <div className="h-[40px] w-[40px]">
                      <CLoader />
                    </div>
                  ) : null}
                </div>
                <CCarousel>
                  {inspection.extraImages.map((v, i) => (
                    <div className="flex flex-col items-start justify-start w-[100%] mx-[0.5rem] flex-none">
                      <p className="text-[1rem] whitespace-nowrap">{i + 1}.</p>
                      <CInput
                        placeholder={t("enter_note")}
                        value={v.note}
                        onChange={(e) => {
                          const extraImages = structuredClone(
                            inspection.extraImages
                          );
                          extraImages[i].note = e.target.value;
                          dispatch(
                            setInspectionValues({
                              extraImages: extraImages,
                            })
                          );
                        }}
                      />
                      <CAddImage
                        label={`extra_images_${i + 1}`}
                        hideLabel={true}
                        image={v.image}
                        setImage={(_) => {
                          const extraImages = structuredClone(
                            inspection.extraImages
                          );
                          extraImages[i].image = _;
                          dispatch(
                            setInspectionValues({
                              extraImages: extraImages,
                            })
                          );
                        }}
                      />
                    </div>
                  ))}
                  <div
                    className="flex flex-col items-center justify-center w-[100%] h-[18rem] mx-[1rem] bg-[#F1F2F3] rounded-[1rem] flex-none"
                    onClick={() => {
                      const extraImages = JSON.parse(
                        JSON.stringify(inspection.extraImages)
                      );
                      extraImages.push({
                        note: null,
                        image: null,
                      });
                      dispatch(
                        setInspectionValues({
                          extraImages: extraImages,
                        })
                      );
                    }}
                  >
                    <img src={PlusSVG} className="w-[5rem] h-[5rem]" />
                  </div>
                </CCarousel>
              </div>
            </CCollapse>
            <div className="mb-[2%]" />
            <CCollapse
              label={`10. ${t(
                collapseNames.declaration_of_consent_technical_solution
              )}`}
              isSelected={true}
            >
              <CDropDown
                label={t("consent_provider")}
                value={inspection.consentProvider}
              >
                {(locale == locales.italian
                  ? ["Proprietario", "Delegare"]
                  : ["Owner", "Delegate"]
                ).map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={inspection.consentProvider == _}
                    onClick={() => {
                      dispatch(
                        setInspectionValues({
                          consentProvider: _,
                        })
                      );
                    }}
                  />
                ))}
              </CDropDown>
              {inspection.consentProvider == "Delegate" ||
              inspection.consentProvider == "Delegare" ? (
                <>
                  <CInput
                    label={t("delegate_name")}
                    placeholder={t("enter_name_of_delegate")}
                    value={inspection.consentDelegateName}
                    onChange={(e) =>
                      dispatch(
                        setInspectionValues({
                          consentDelegateName: e.target.value,
                        })
                      )
                    }
                  />
                  {isLoading && file == inspection.consentDelegateAttachment ? (
                    <div className="h-[40px] w-[40px]">
                      <CLoader />
                    </div>
                  ) : null}
                  <CAddImage
                    label={t("delegate_attachment")}
                    image={inspection.consentDelegateAttachment}
                    setImage={(_) =>
                      dispatch(
                        setInspectionValues({
                          consentDelegateAttachment: _,
                        })
                      )
                    }
                  />
                  <div className="mt-[5px]" />
                </>
              ) : null}
              <p className="text-[12px]">{t("date")}</p>
              <CDateTimePicker
                value={inspection.consentAcceptanceDate}
                onChange={(_) => {
                  dispatch(
                    setInspectionValues({
                      consentAcceptanceDate: _,
                    })
                  );
                }}
              />
              <div className="flex flex-row items-center justify-between">
                <p className={`text-[12px]`}>{t("client_signature")}</p>
                <img
                  src={CancelSVG}
                  className="h-[30px] w-[30px] cursor-pointer"
                  onClick={() => {
                    consentClientSignatureRef?.current?.clear();
                  }}
                />
              </div>
              <SignatureCanvas
                penColor="#F1622C"
                canvasProps={{
                  className:
                    "min-h-[200px] w-[100%] border-[1px] border-[#000000] border-solid rounded-[10px]",
                }}
                ref={consentClientSignatureRef}
              />
            </CCollapse>
            <div className="flex-grow" />
            <CButton
              className="w-[100%] my-[14px]"
              text={t("continue")}
              isLoading={isLoading}
              onClick={async () => {
                await saveAndLoadDraft();

                const res = await dispatch(
                  createInspection({
                    ...inspectionState.toBody(),
                    type: inspectionTypes.form,
                    project_id: projectID,
                    getConsentClientSignature: getConsentClientSignature,
                  })
                );

                if (res?.payload) {
                  navigate("/");
                }
              }}
            />
            <CDialog
              name="modal_inspection_draft"
              title={t("inspection_draft_found")}
              description={t("do_you_want_to_load_data_from_the_saved_draft?")}
              approvalText={t("load_data")}
              onApproval={() => {
                dispatch(
                  setInspection(
                    Inspection.parse(
                      mergeObjects(inspectionDraft.toMap(), inspection.toMap())
                    )
                  )
                );

                window.modal_inspection_draft.close();
              }}
              rejectionText={t("delete_draft")}
              onRejection={() => {}}
            />
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SInspectionForm;
