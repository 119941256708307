import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import {
  createOtherProjectInstallation,
  readOtherProject,
} from "../controllers/other_projects";

import {
  createOtherProjectInstallationDraft,
  readOtherProjectInstallationDraft,
  setOtherProjectInstallation,
  setOtherProjectInstallationDraft,
  setOtherProjectInstallationValues,
} from "../controllers/other_project_installation_drafts";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";
import CAddImage from "../components/add_image";
import CDialog from "../components/dialog";
import { useTranslation } from "react-i18next";

import SaveSVG from "../assets/images/save.svg";

import { mergeObjects, uploadNamedFileOrParseMaker } from "../utils/misc";
import {
  filesURLSplitSequence,
  otherProjectInstallationTypes,
} from "../utils/constants";
import { OtherProjectInstallation } from "../utils/models";

function SOtherProjectInstallationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const otherProjectID = searchParams.get("other_project_id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.projects.isLoading);
  const otherProject = useSelector((state) => state.otherProjects.otherProject);
  const file = useSelector((state) => state.files.file);
  const isLoadingOtherProjectInstallationDraft = useSelector(
    (state) => state.otherProjectInstallationDrafts.isLoading
  );
  const [
    hasLoadedOtherProjectInstallationDraft,
    setHasLoadedOtherProjectInstallationDraft,
  ] = useState(false);
  const otherProjectInstallationDraft = useSelector(
    (state) =>
      state.otherProjectInstallationDrafts.otherProjectInstallationDraft
  );
  const otherProjectInstallationState = useSelector(
    (state) => state.otherProjectInstallationDrafts.otherProjectInstallation
  );

  const [
    isSavingOtherProjectInstallationDraft,
    setIsSavingOtherProjectInstallationDraft,
  ] = useState(false);

  const otherProjectInstallation = useMemo(() => {
    return OtherProjectInstallation.parse(
      otherProjectInstallationState.toMap()
    );
  }, [otherProjectInstallationState]);

  useEffect(() => {
    if (otherProjectID) {
      dispatch(
        readOtherProject({
          id: otherProjectID,
        })
      );
    }
  }, [otherProjectID]);

  useEffect(() => {
    if (
      !hasLoadedOtherProjectInstallationDraft &&
      otherProjectInstallationDraft &&
      otherProjectInstallationDraft.type == otherProjectInstallationTypes.images
    ) {
      window.modal_installation_draft.showModal();
      setHasLoadedOtherProjectInstallationDraft(true);
    }
  }, [otherProjectInstallationDraft]);

  useEffect(() => {
    dispatch(
      readOtherProjectInstallationDraft({
        other_project_id: otherProjectID,
        type: otherProjectInstallationTypes.images,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      setOtherProjectInstallationDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${otherProject?.client?.firstName} - ${otherProject?.client?.lastName} - Other Project Installation`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingOtherProjectInstallationDraft(true);

    let page1 = await uploadNamedFileOrParse({
      file: otherProjectInstallation.page1,
      name: t("page_1"),
    });

    dispatch(
      setOtherProjectInstallationValues({
        page1,
      })
    );

    const _installationDraft = await dispatch(
      createOtherProjectInstallationDraft({
        ...otherProjectInstallationState.toBody(),
        orotund,
        type: otherProjectInstallationTypes.images,
        other_project_id: otherProjectID,
      })
    );

    dispatch(
      setOtherProjectInstallation(
        OtherProjectInstallation.parse(
          mergeObjects(
            _installationDraft.payload.toMap(),
            otherProjectInstallation.toMap()
          )
        )
      )
    );

    setIsSavingOtherProjectInstallationDraft(false);

    return;
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("installation")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingOtherProjectInstallationDraft ||
              isSavingOtherProjectInstallationDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedOtherProjectInstallationDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            {isLoading && file == otherProjectInstallation.page1 ? (
              <div className="h-[40px] w-[40px]">
                <CLoader />
              </div>
            ) : null}
            <CAddImage
              label={t("page_1")}
              image={otherProjectInstallation.page1}
              setImage={(_) =>
                dispatch(
                  setOtherProjectInstallationValues({
                    page1: _,
                  })
                )
              }
            />
            <div className="flex-grow" />
            <CButton
              className="w-[100%] my-[14px]"
              text={t("continue")}
              isLoading={isLoading}
              onClick={async () => {
                await saveAndLoadDraft();

                const res = await dispatch(
                  createOtherProjectInstallation({
                    ...otherProjectInstallationState.toBody(),
                    type: otherProjectInstallationTypes.images,
                    other_project_id: otherProjectID,
                  })
                );

                if (res?.payload) {
                  navigate("/");
                }
              }}
            />
            <CDialog
              name="modal_installation_draft"
              title={t("installation_draft_found")}
              description={t("do_you_want_to_load_data_from_the_saved_draft?")}
              approvalText={t("load_data")}
              onApproval={() => {
                dispatch(
                  setOtherProjectInstallation(
                    OtherProjectInstallation.parse(
                      mergeObjects(
                        otherProjectInstallationDraft.toMap(),
                        otherProjectInstallation.toMap()
                      )
                    )
                  )
                );
                window.modal_installation_draft.close();
              }}
              rejectionText={t("delete_draft")}
              onRejection={() => {}}
            />
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SOtherProjectInstallationForm;
