import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import toast from "react-hot-toast";
import { PagesInfo, NotificationsFilter } from "../utils/models";
import { stringToBoolean } from "../utils/misc";

const initialState = {
  isLoading: false,
  pagesInfo: PagesInfo.parse({
    current: 1,
  }),
  notifications: [],
  filter: NotificationsFilter.parse({
    isArchived: false,
  }),
};

const readNotifications = createAsyncThunk(
  "notifications/readNotifications",
  async (args, { getState }) => {
    try {
      const state = getState();

      const {
        page_no = state.notifications.pagesInfo.current,
        applyFilter = true,
        body = {},
      } = args ?? {};

      const filter = state.notifications.filter;

      let _body = {
        ...body,
        page_no,
      };
      if (applyFilter) {
        _body = {
          is_archived: stringToBoolean(filter.isArchived),
          ..._body,
        };
      }

      const [pagesInfo, notifications] = await AppNetworkRest.readNotifications(
        _body
      );
      return [pagesInfo, notifications];
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updateNotification = createAsyncThunk(
  "notifications/updateNotification",
  async (args, { getState }) => {
    try {
      const { is_archived } = args;

      const str = await AppNetworkRest.updateNotification(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readNotifications.fulfilled, (state, action) => {
      if (action.payload) {
        state.pagesInfo = action.payload[0];
        state.notifications = action.payload[1];
      }
    });
  },
});

export const { setFilter } = notificationsSlice.actions;

export { readNotifications, updateNotification };

export default notificationsSlice.reducer;
