import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  projectStatuses,
  dateTimeFormatter,
  inspectionTechnicianTypes,
} from "../utils/constants";

import { setSelectedRouteIndex } from "../controllers/control_bar";
import { readProject } from "../controllers/projects";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import MapSVG from "../assets/images/map.svg";
import FormSVG from "../assets/images/form.svg";
import ImageSVG from "../assets/images/image.svg";
import CallSVG from "../assets/images/call.svg";
import WhatsappPNG from "../assets/images/whatsapp.png";

function SProjectInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.projects.isLoading);
  const project = useSelector((state) => state.projects.project);

  useEffect(() => {
    if (id) {
      dispatch(
        readProject({
          id,
        })
      );
    }
  }, [id]);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(0));
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">
            {t("project_info")}
          </p>
          <div className="h-[20px]" />
          {!project ? <CLoader /> : <CProject project={project} />}
        </div>
        <MInspectionType project={project} />
        <MInstallationType project={project} />
      </div>
    </CContainer>
  );
}

function CProject({ project }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col p-[10px] w-[100%] bg-slate-100 rounded-[10px] cursor-pointer">
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[600]">{project.title}</p>
        <p
          className={`text-[16px] font-[600] ${
            project.status == projectStatuses.created
              ? "text-[#FF9900]"
              : project.status == projectStatuses.inspected
              ? "text-[#4A86E8]"
              : project.status == projectStatuses.installed
              ? "text-[#6AA84F]"
              : ""
          }`}
        >
          {project.status.toUpperCase()}
        </p>
      </div>
      <div className="flex flex-row items-center justify-between w-[100%]">
        <p className="text-[16px] font-[600] w-[80%]">{project.address}</p>
        <img
          src={MapSVG}
          className="h-[30px] w-[30px]"
          onClick={() => {
            window.open(`https://maps.google.com/?q=${project.address}`);
          }}
        />
      </div>
      <div className="h-[18dvh] overflow-scroll">
        <p className="text-[18px] font-[500]">{project.description}</p>
      </div>
      <div className="h-[0.5px] w-[100%] bg-black" />
      <div className="h-[12dvh] overflow-scroll">
        <p className="text-[18px] font-[500]">{project.installationKit}</p>
      </div>
      <div className="h-[0.5px] w-[100%] bg-black" />
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[500]">{t("inspection")}</p>
        <p className="text-[16px] font-[500]">
          {project.inspection
            ? dateTimeFormatter.format(new Date(project.inspection))
            : "N/A"}
        </p>
      </div>
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[500]">{t("installation")}</p>
        <p className="text-[16px] font-[500]">
          {project.installation
            ? dateTimeFormatter.format(new Date(project.installation))
            : "N/A"}
        </p>
      </div>
      {(project.status == projectStatuses.created ||
        project.status == projectStatuses.assigned) &&
      (project.as == inspectionTechnicianTypes.inspection ||
        project.as == inspectionTechnicianTypes.inspection_installation) ? (
        <>
          <div className="h-[20px]" />
          <CButton
            text={t("submit_inspection")}
            onClick={() => {
              window.modal_inspection_type.showModal();
            }}
          />
        </>
      ) : null}
      {project.status == projectStatuses.inspected &&
      (project.as == inspectionTechnicianTypes.installation ||
        project.as == inspectionTechnicianTypes.inspection_installation) ? (
        <>
          <div className="h-[10px]" />
          <CButton
            text={t("submit_installation")}
            onClick={() => {
              window.modal_installation_type.showModal();
            }}
          />
        </>
      ) : null}
      <div className="mt-[1rem]" />
      <p className="text-[14px] font-[500] text-center">
        {project.as == inspectionTechnicianTypes.inspection
          ? t("*_you_have_been_assigned_this_project_for_inspection.")
          : project.as == inspectionTechnicianTypes.installation
          ? t("*_you_have_been_assigned_this_project_for_installation.")
          : project.as == inspectionTechnicianTypes.inspection_installation
          ? t(
              "*_you_have_been_assigned_this_project_for_both_inspection_and_installation."
            )
          : ""}
      </p>
      {project?.client?.phone ? (
        <>
          <div className="my-[0.5rem]" />
          <CButton
            text={t("make_a_phone_call")}
            icon={CallSVG}
            onClick={() => {
              window.open(`tel:${project?.client?.phone}`, "_self");
            }}
          />
        </>
      ) : null}
      {project?.client?.phone ? (
        <>
          <div className="my-[0.5rem]" />
          <CButton
            text={t("open_a_whatsapp_chat")}
            icon={WhatsappPNG}
            onClick={() => {
              window.open(`https://wa.me/${project?.client?.phone}`, "_blank");
            }}
          />
          <div className="my-[0.5rem]" />
        </>
      ) : null}
    </div>
  );
}

const MInspectionType = ({ project }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <dialog id="modal_inspection_type" className="modal">
      <form
        method="dialog"
        className="flex items-center justify-center modal-box bg-white min-h-[60dvh] min-w-[40dvw]"
      >
        <div className="flex flex-col items-start justify-center bg-white p-[2%] overflow-scroll h-[100%] w-[100%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(`/inspection_form?project_id=${project.id}`);
              }}
            >
              <img src={FormSVG} className="h-[50px] w-[50px]" />
              <p>{t("fill_out")}</p>
            </div>
            <div className="w-[20px]" />
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(`/inspection_images?project_id=${project.id}`);
              }}
            >
              <img src={ImageSVG} className="h-[50px] w-[50px]" />
              <p>{t("images")}</p>
            </div>
          </div>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

const MInstallationType = ({ project }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <dialog id="modal_installation_type" className="modal">
      <form
        method="dialog"
        className="flex items-center justify-center modal-box bg-white min-h-[60dvh] min-w-[40dvw]"
      >
        <div className="flex flex-col items-start justify-center bg-white p-[2%] overflow-scroll h-[100%] w-[100%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(`/installation_form?project_id=${project.id}`);
              }}
            >
              <img src={FormSVG} className="h-[50px] w-[50px]" />
              <p>{t("fill_out")}</p>
            </div>
            <div className="w-[20px]" />
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(`/installation_images?project_id=${project.id}`);
              }}
            >
              <img src={ImageSVG} className="h-[50px] w-[50px]" />
              <p>{t("images")}</p>
            </div>
          </div>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default SProjectInfo;
