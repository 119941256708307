import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  otherProjectStatuses,
  dateTimeFormatter,
  otherProjectTechnicianTypes,
} from "../utils/constants";

import { setSelectedRouteIndex } from "../controllers/control_bar";
import { readOtherProject } from "../controllers/other_projects";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import MapSVG from "../assets/images/map.svg";
import FormSVG from "../assets/images/form.svg";
import ImageSVG from "../assets/images/image.svg";
import CallSVG from "../assets/images/call.svg";
import WhatsappPNG from "../assets/images/whatsapp.png";

function SOtherProjectInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.otherProjects.isLoading);
  const otherProject = useSelector((state) => state.otherProjects.otherProject);

  useEffect(() => {
    if (id) {
      dispatch(
        readOtherProject({
          id,
        })
      );
    }
  }, [id]);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(1));
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">
            {t("other_project_info")}
          </p>
          <div className="h-[20px]" />
          {!otherProject ? (
            <CLoader />
          ) : (
            <CProject otherProject={otherProject} />
          )}
        </div>
        <MInspectionType otherProject={otherProject} />
        <MInstallationType otherProject={otherProject} />
      </div>
    </CContainer>
  );
}

function CProject({ otherProject }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col p-[10px] w-[100%] bg-slate-100 rounded-[10px] cursor-pointer">
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[600]">{otherProject.title}</p>
        <p
          className={`text-[16px] font-[600] ${
            otherProject.status == otherProjectStatuses.created
              ? "text-[#FF9900]"
              : otherProject.status == otherProjectStatuses.inspected
              ? "text-[#4A86E8]"
              : otherProject.status == otherProjectStatuses.installed
              ? "text-[#6AA84F]"
              : ""
          }`}
        >
          {otherProject.status.toUpperCase()}
        </p>
      </div>
      <div className="flex flex-row items-center justify-between w-[100%]">
        <p className="text-[16px] font-[600] w-[80%]">{otherProject.address}</p>
        <img
          src={MapSVG}
          className="h-[30px] w-[30px]"
          onClick={() => {
            window.open(`https://maps.google.com/?q=${otherProject.address}`);
          }}
        />
      </div>
      <div className="h-[18dvh] overflow-scroll">
        <p className="text-[18px] font-[500]">{otherProject.description}</p>
      </div>
      <div className="h-[0.5px] w-[100%] bg-black" />
      <div className="h-[12dvh] overflow-scroll">
        <p className="text-[18px] font-[500]">{otherProject.installationKit}</p>
      </div>
      <div className="h-[0.5px] w-[100%] bg-black" />
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[500]">{t("inspection")}</p>
        <p className="text-[16px] font-[500]">
          {otherProject.inspection
            ? dateTimeFormatter.format(new Date(otherProject.inspection))
            : "N/A"}
        </p>
      </div>
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[500]">{t("installation")}</p>
        <p className="text-[16px] font-[500]">
          {otherProject.installation
            ? dateTimeFormatter.format(new Date(otherProject.installation))
            : "N/A"}
        </p>
      </div>
      {(otherProject.status == otherProjectStatuses.created ||
        otherProject.status == otherProjectStatuses.assigned) &&
      (otherProject.as == otherProjectTechnicianTypes.inspection ||
        otherProject.as ==
          otherProjectTechnicianTypes.inspection_installation) ? (
        <>
          <div className="h-[20px]" />
          <CButton
            text={t("submit_inspection")}
            onClick={() => {
              window.modal_inspection_type.showModal();
            }}
          />
        </>
      ) : null}
      {otherProject.status == otherProjectStatuses.inspected &&
      (otherProject.as == otherProjectTechnicianTypes.installation ||
        otherProject.as ==
          otherProjectTechnicianTypes.inspection_installation) ? (
        <>
          <div className="h-[10px]" />
          <CButton
            text={t("submit_installation")}
            onClick={() => {
              window.modal_installation_type.showModal();
            }}
          />
        </>
      ) : null}
      <div className="mt-[1rem]" />
      <p className="text-[14px] font-[500] text-center">
        {otherProject.as == otherProjectTechnicianTypes.inspection
          ? t("*_you_have_been_assigned_this_project_for_inspection.")
          : otherProject.as == otherProjectTechnicianTypes.installation
          ? t("*_you_have_been_assigned_this_project_for_installation.")
          : otherProject.as ==
            otherProjectTechnicianTypes.inspection_installation
          ? t(
              "*_you_have_been_assigned_this_project_for_both_inspection_and_installation."
            )
          : ""}
      </p>
      {otherProject?.client?.phone ? (
        <>
          <div className="my-[0.5rem]" />
          <CButton
            text={t("make_a_phone_call")}
            icon={CallSVG}
            onClick={() => {
              window.open(`tel:${otherProject?.client?.phone}`, "_self");
            }}
          />
        </>
      ) : null}
      {otherProject?.client?.phone ? (
        <>
          <div className="my-[0.5rem]" />
          <CButton
            text={t("open_a_whatsapp_chat")}
            icon={WhatsappPNG}
            onClick={() => {
              window.open(
                `https://wa.me/${otherProject?.client?.phone}`,
                "_blank"
              );
            }}
          />
          <div className="my-[0.5rem]" />
        </>
      ) : null}
    </div>
  );
}

const MInspectionType = ({ otherProject }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <dialog id="modal_inspection_type" className="modal">
      <form
        method="dialog"
        className="flex items-center justify-center modal-box bg-white min-h-[60dvh] min-w-[40dvw]"
      >
        <div className="flex flex-col items-start justify-center bg-white p-[2%] overflow-scroll h-[100%] w-[100%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(
                  `/other_project_inspection_form?other_project_id=${otherProject.id}`
                );
              }}
            >
              <img src={FormSVG} className="h-[50px] w-[50px]" />
              <p>{t("fill_out")}</p>
            </div>
            <div className="w-[20px]" />
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(
                  `/other_project_inspection_images?other_project_id=${otherProject.id}`
                );
              }}
            >
              <img src={ImageSVG} className="h-[50px] w-[50px]" />
              <p>{t("images")}</p>
            </div>
          </div>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

const MInstallationType = ({ otherProject }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <dialog id="modal_installation_type" className="modal">
      <form
        method="dialog"
        className="flex items-center justify-center modal-box bg-white min-h-[60dvh] min-w-[40dvw]"
      >
        <div className="flex flex-col items-start justify-center bg-white p-[2%] overflow-scroll h-[100%] w-[100%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(
                  `/other_project_installation_form?other_project_id=${otherProject.id}`
                );
              }}
            >
              <img src={FormSVG} className="h-[50px] w-[50px]" />
              <p>{t("fill_out")}</p>
            </div>
            <div className="w-[20px]" />
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(
                  `/other_project_installation_images?other_project_id=${otherProject.id}`
                );
              }}
            >
              <img src={ImageSVG} className="h-[50px] w-[50px]" />
              <p>{t("images")}</p>
            </div>
          </div>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default SOtherProjectInfo;
