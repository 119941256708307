import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { otherProjectStatuses } from "../utils/constants";

import { setSelectedRouteIndex } from "../controllers/control_bar";
import {
  clearOtherProjects,
  readOtherProjects,
} from "../controllers/other_projects";

import CContainer from "../components/container";
import CButton from "../components/button";

function SOtherProjects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.otherProjects.isLoading);
  const pagesInfo = useSelector((state) => state.otherProjects.pagesInfo);
  const otherProjects = useSelector(
    (state) => state.otherProjects.otherProjects
  );

  useEffect(() => {
    _init();

    return () => {
      dispatch(clearOtherProjects());
    };
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(1));
    dispatch(
      readOtherProjects({
        page_no: 1,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">
            {t("other_projects")}
          </p>
          <div className="h-[20px]" />
          <>
            {otherProjects.length == 0 ? (
              <div className="flex flex-col items-center justify-center h-[100%] w-[100%]">
                <p className="text-[18px] font-[500] self-center">
                  {t("hi")}{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .firstName
                  }{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .lastName
                  }{" "}
                  👋!
                </p>
                <p className="text-[16px] font-[500] self-center">
                  {t("there_are_no_active_other_projects_for_you.")}
                </p>
              </div>
            ) : (
              <>
                {otherProjects.map((_, index) => (
                  <COtherProject otherProject={otherProjects[index]} />
                ))}
                {pagesInfo.current < pagesInfo.total ? (
                  <>
                    <div className="mt-[0.5rem]" />
                    <CButton
                      isLoading={isLoading}
                      className="w-[50%] self-center"
                      text={t("load_more")}
                      onClick={async () => {
                        dispatch(
                          readOtherProjects({
                            page_no: pagesInfo.current + 1,
                          })
                        );
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </>
        </div>
      </div>
    </CContainer>
  );
}

function COtherProject({ otherProject }) {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col p-[10px] my-[5px] w-[100%] bg-slate-100 rounded-[10px] cursor-pointer"
      onClick={() => {
        navigate(`/other_project_info?id=${otherProject.id}`);
      }}
    >
      <div className="flex flex-row w-[100%]">
        <div className="flex flex-row justify-start items-center w-[50%]">
          <p className="text-[18px] font-[600]">{otherProject.title}</p>
        </div>
        <div className="flex flex-row justify-end items-center w-[50%]">
          <p
            className={`text-[16px] font-[600] ${
              otherProject.status == otherProjectStatuses.created
                ? "text-[#FF9900]"
                : otherProject.status == otherProjectStatuses.inspected
                ? "text-[#4A86E8]"
                : otherProject.status == otherProjectStatuses.installed
                ? "text-[#6AA84F]"
                : ""
            }`}
          >
            {otherProject.status.toUpperCase()}
          </p>
        </div>
      </div>
      <div className="h-[10dvh] overflow-hidden">
        <p className="text-[18px] font-[500]">{otherProject.description}</p>
      </div>
    </div>
  );
}

export default SOtherProjects;
