import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  maintenanceProjectStatuses,
  projectStatuses,
} from "../utils/constants";

import { setSelectedRouteIndex } from "../controllers/control_bar";
import {
  clearMaintenanceProjects,
  readMaintenanceProjects,
} from "../controllers/maintenance_projects";

import CContainer from "../components/container";
import CButton from "../components/button";

function SMaintenanceProjects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.maintenanceProjects.isLoading);
  const pagesInfo = useSelector((state) => state.maintenanceProjects.pagesInfo);
  const maintenanceProjects = useSelector(
    (state) => state.maintenanceProjects.maintenanceProjects
  );

  useEffect(() => {
    _init();

    return () => {
      dispatch(clearMaintenanceProjects());
    };
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(2));
    dispatch(
      readMaintenanceProjects({
        page_no: 1,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">
            {t("maintenances")}
          </p>
          <div className="h-[20px]" />
          <>
            {maintenanceProjects.length == 0 ? (
              <div className="flex flex-col items-center justify-center h-[100%] w-[100%]">
                <p className="text-[18px] font-[500] self-center">
                  {t("hi")}{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .firstName
                  }{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .lastName
                  }{" "}
                  👋!
                </p>
                <p className="text-[16px] font-[500] self-center">
                  {t("there_are_no_active_maintenances_for_you.")}
                </p>
              </div>
            ) : (
              <>
                {maintenanceProjects.map((_, index) => (
                  <CMaintenanceProject
                    maintenanceProject={maintenanceProjects[index]}
                  />
                ))}
                {pagesInfo.current < pagesInfo.total ? (
                  <>
                    <div className="mt-[0.5rem]" />
                    <CButton
                      isLoading={isLoading}
                      className="w-[50%] self-center"
                      text={t("load_more")}
                      onClick={async () => {
                        dispatch(
                          readMaintenanceProjects({
                            page_no: pagesInfo.current + 1,
                          })
                        );
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </>
        </div>
      </div>
    </CContainer>
  );
}

function CMaintenanceProject({ maintenanceProject }) {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col p-[10px] my-[5px] w-[100%] bg-slate-100 rounded-[10px] cursor-pointer"
      onClick={() => {
        navigate(`/maintenance_project_info?id=${maintenanceProject.id}`);
      }}
    >
      <div className="flex flex-row w-[100%]">
        <div className="flex flex-row justify-start items-center w-[50%]">
          <p className="text-[18px] font-[600]">{maintenanceProject.title}</p>
        </div>
        <div className="flex flex-row justify-end items-center w-[50%]">
          <p
            className={`text-[16px] font-[600] ${
              maintenanceProject.status == maintenanceProjectStatuses.created
                ? "text-[#FF9900]"
                : maintenanceProject.status ==
                  maintenanceProjectStatuses.submitted
                ? "text-[#4A86E8]"
                : ""
            }`}
          >
            {maintenanceProject.status.toUpperCase()}
          </p>
        </div>
      </div>
      <div className="h-[10dvh] overflow-hidden">
        <p className="text-[18px] font-[500]">
          {maintenanceProject.description}
        </p>
      </div>
    </div>
  );
}

export default SMaintenanceProjects;
