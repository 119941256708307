import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { createInspection, readProject } from "../controllers/projects";
import {
  readInspectionDraft,
  setInspectionDraft,
  setInspection,
  setInspectionValues,
  createInspectionDraft,
} from "../controllers/inspection_drafts";

import PlusSVG from "../assets/images/plus.svg";
import SaveSVG from "../assets/images/save.svg";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CAddImage from "../components/add_image";
import CButton from "../components/button";
import CDialog from "../components/dialog";

import { inspectionTypes } from "../utils/constants";
import { uploadNamedFileOrParseMaker, mergeObjects } from "../utils/misc";
import { nullValidator, validate } from "../utils/validators";
import { Inspection } from "../utils/models";

function SInspectionImages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const projectID = searchParams.get("project_id");

  const [isSavingInspectionDraft, setIsSavingInspectionDraft] = useState(false);

  const isLoading = useSelector((state) => state.projects.isLoading);
  const project = useSelector((state) => state.projects.project);
  const file = useSelector((state) => state.files.file);
  const isLoadingInspectionDraft = useSelector(
    (state) => state.inspectionDrafts.isLoading
  );
  const inspectionDraft = useSelector(
    (state) => state.inspectionDrafts.inspectionDraft
  );
  const inspectionState = useSelector(
    (state) => state.inspectionDrafts.inspection
  );

  const inspection = useMemo(() => {
    return Inspection.parse(inspectionState.toMap());
  }, [inspectionState]);

  const [hasLoadedInspectionDraft, setHasLoadedInspectionDraft] =
    useState(false);

  // Load data
  useEffect(() => {
    if (projectID) {
      dispatch(
        readInspectionDraft({
          project_id: projectID,
          type: inspectionTypes.images,
        })
      );
      dispatch(
        readProject({
          id: projectID,
        })
      );
    }
  }, [projectID]);

  // Display load draft data
  useEffect(() => {
    if (inspectionDraft && inspectionDraft.type == inspectionTypes.images) {
      window.modal_inspection_draft.showModal();
    }
  }, [inspectionDraft]);

  // Display load draft data
  useEffect(() => {
    if (
      !hasLoadedInspectionDraft &&
      inspectionDraft &&
      inspectionDraft.type == inspectionTypes.images
    ) {
      window.modal_inspection_draft.showModal();
      setHasLoadedInspectionDraft(true);
    }
  }, [inspectionDraft]);

  // Dismount
  useEffect(() => {
    return () => {
      setInspectionDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${project?.client?.firstName} - ${project?.client?.lastName} - Inspection`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingInspectionDraft(true);

    const cotaRoofPicture = await uploadNamedFileOrParse({
      file: inspection.cotaRoofPicture,
      name: "Picture of the roof",
    });
    const psfInverterPositionImage = await uploadNamedFileOrParse({
      file: inspection.psfInverterPositionImage,
      name: "Photovoltaic System Features - Inverter Postion",
    });
    const psfBatteryLocationImage = await uploadNamedFileOrParse({
      file: inspection.psfBatteryLocationImage,
      name: "Photovoltaic System Features - Battery Location",
    });
    const page1 = await uploadNamedFileOrParse({
      file: inspection.page1,
      name: "Page 1",
    });
    const page2 = await uploadNamedFileOrParse({
      file: inspection.page2,
      name: "Page 2",
    });
    const page3 = await uploadNamedFileOrParse({
      file: inspection.page3,
      name: "Page 3",
    });

    dispatch(
      setInspectionValues({
        cotaRoofPicture,
        psfInverterPositionImage,
        psfBatteryLocationImage,
        page1,
        page2,
        page3,
      })
    );

    const _inspectionDraft = await dispatch(
      createInspectionDraft({
        ...inspectionState.toBody(),
        orotund,
        type: inspectionTypes.images,
        project_id: projectID,
      })
    );

    dispatch(
      setInspection(
        Inspection.parse(
          mergeObjects(_inspectionDraft.payload.toMap(), inspection.toMap())
        )
      )
    );

    setIsSavingInspectionDraft(false);

    return;
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("inspection")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingInspectionDraft || isSavingInspectionDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedInspectionDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          {isLoading && file == inspection.cotaRoofPicture ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("picture_of_the_roof")}
            image={inspection.cotaRoofPicture}
            setImage={(_) =>
              dispatch(
                setInspectionValues({
                  cotaRoofPicture: _,
                })
              )
            }
          />
          <div className="h-[2%]" />
          {isLoading && file == inspection.psfInverterPositionImage ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("inverter_position")}
            image={inspection.psfInverterPositionImage}
            setImage={(_) =>
              dispatch(
                setInspectionValues({
                  psfInverterPositionImage: _,
                })
              )
            }
          />
          {isLoading && file == inspection.psfBatteryLocationImage ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("battery_location")}
            image={inspection.psfBatteryLocationImage}
            setImage={(_) =>
              dispatch(
                setInspectionValues({
                  psfBatteryLocationImage: _,
                })
              )
            }
          />
          {isLoading && file == inspection.page1 ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("page_1")}
            image={inspection.page1}
            setImage={(_) =>
              dispatch(
                setInspectionValues({
                  page1: _,
                })
              )
            }
          />
          <div className="h-[2%]" />
          {isLoading && file == inspection.page2 ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("page_2")}
            image={inspection.page2}
            setImage={(_) =>
              dispatch(
                setInspectionValues({
                  page2: _,
                })
              )
            }
          />
          <div className="h-[2%]" />
          {isLoading && file == inspection.page3 ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("page_3")}
            image={inspection.page3}
            setImage={(_) =>
              dispatch(
                setInspectionValues({
                  page3: _,
                })
              )
            }
          />
          <div className="flex-grow" />
          <CButton
            className="w-[100%] my-[14px]"
            text="Continue"
            isLoading={isLoading}
            onClick={async () => {
              await saveAndLoadDraft();

              const res = await dispatch(
                createInspection({
                  ...inspectionState.toBody(),
                  type: inspectionTypes.images,
                  project_id: projectID,
                })
              );

              if (res?.payload) {
                navigate("/");
              }
            }}
          />
          <CDialog
            name="modal_inspection_draft"
            title={t("inspection_draft_found")}
            description={t("do_you_want_to_load_data_from_the_saved_draft?")}
            approvalText={t("load_data")}
            onApproval={() => {
              dispatch(
                setInspection(
                  Inspection.parse(
                    mergeObjects(inspectionDraft.toMap(), inspection.toMap())
                  )
                )
              );

              window.modal_inspection_draft.close();
            }}
            rejectionText={t("delete_draft")}
            onRejection={() => {}}
          />
        </div>
      </div>
    </CContainer>
  );
}

export default SInspectionImages;
