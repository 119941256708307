import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteIndex } from "../controllers/control_bar";
import { readNotifications } from "../controllers/notifications";

import CContainer from "../components/container";
import CButton from "../components/button";
import { notificationTypes } from "../utils/constants";

import InfoSVG from "../assets/images/info.svg";

function SNotifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.notifications.isLoading);
  const pagesInfo = useSelector((state) => state.notifications.pagesInfo);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(3));
    dispatch(
      readNotifications({
        page_no: 1,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">
            {t("notifications")}
          </p>
          <div className="h-[20px]" />
          <>
            {notifications.length == 0 ? (
              <div className="flex flex-col items-center justify-center h-[100%] w-[100%]">
                <p className="text-[18px] font-[500] self-center">
                  {t("hi")}{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .firstName
                  }{" "}
                  {
                    JSON.parse(localStorage.getItem("AppStorage/appUser"))
                      .lastName
                  }{" "}
                  👋!
                </p>
                <p className="text-[16px] font-[500] self-center">
                  {t("there_are_no_notifications_for_you.")}
                </p>
              </div>
            ) : (
              <>
                {notifications.map((_, index) => (
                  <CNotification notification={notifications[index]} />
                ))}
                {pagesInfo.current < pagesInfo.total ? (
                  <>
                    <div className="mt-[0.5rem]" />
                    <CButton
                      isLoading={isLoading}
                      className="w-[50%] self-center"
                      text={t("load_more")}
                      onClick={async () => {
                        dispatch(
                          readNotifications({
                            page_no: pagesInfo.current + 1,
                          })
                        );
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </>
        </div>
      </div>
    </CContainer>
  );
}

function CNotification({ notification }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col my-[0.5rem] bg-white shadow-lg border-[#F5F5F5] border-solid border-[1px] rounded-sm flex-shrink-0 overflow-auto">
      <div
        className={`flex flex-row items-center justify-start p-[1rem] ${
          notification.isRead ? "bg-[#F1F1F1]" : "bg-white"
        } border-b-[1px] border-[#D3D3D3] border-solid cursor-pointer`}
        onClick={() => {
          if (
            notification.type == notificationTypes.project_inspection_assigned
          ) {
            navigate(
              `/project_info?id=${notification.inspectionAssignmentProjectID}`
            );
          } else if (
            notification.type == notificationTypes.project_installation_assigned
          ) {
            navigate(
              `/project_info?id=${notification.installationAssignmentProjectID}`
            );
          }
        }}
      >
        <img src={InfoSVG} className="h-[1.5rem] w-[1.5rem]" />
        <div className="mx-[0.5rem]" />
        <p>{notification.title}</p>
      </div>
    </div>
  );
}

export default SNotifications;
