import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { noValidator, nullValidator, validate } from "../utils/validators";
import toast from "react-hot-toast";
import {
  otherProjectInspectionTypes,
  otherProjectInstallationTypes,
} from "../utils/constants";
import { PagesInfo } from "../utils/models";

const initialState = {
  isLoading: false,
  otherProjects: [],
  pagesInfo: PagesInfo.parse({
    current: 1,
  }),
  otherProject: null,
};

const readOtherProjects = createAsyncThunk(
  "projects/readOtherProjects",
  async (args, { getState }) => {
    try {
      const state = getState();

      const { page_no = state.otherProjects.pagesInfo.current } = args ?? {};

      let _body = {
        page_no,
      };

      const [pagesInfo, otherProjects] = await AppNetworkRest.readOtherProjects(
        _body
      );
      return [pagesInfo, otherProjects];
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const readOtherProject = createAsyncThunk(
  "otherProjects/readOtherProject",
  async (args, { getState }) => {
    const { id } = args;

    try {
      const otherProject = await AppNetworkRest.readOtherProject(args);
      return otherProject;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createOtherProjectInspection = createAsyncThunk(
  "projects/createOtherProjectInspection",
  async (args, { getState }) => {
    try {
      const {
        type,
        property_type,
        property_type_2,
        property_use,
        property_use_other,
        property_landscaping,
        it_type,
        it_type_other,
        tr_images,
        extra_work,
        extra_images,
        consent_provider,
        consent_acceptance_date,
        consent_delegate_name,
        consent_delegate_attachment,
        page_1,
        page_2,
        page_3,
        getConsentClientSignature,
      } = args;

      if (type == otherProjectInspectionTypes.form) {
        const valError = validate([
          () => nullValidator("1. Property", property_type),
          () => nullValidator("1. Property type", property_type_2),
          () => nullValidator("1. Property use", property_use),
          () => nullValidator("1. Property landscaping", property_landscaping),
          () => nullValidator("2. Installation Type", it_type),
          () => nullValidator("5. Consent provider", consent_provider),
          () =>
            consent_provider == "Delegate"
              ? nullValidator(
                  "5. Consent - delegate attachment",
                  consent_delegate_attachment
                )
              : noValidator(),
          () =>
            nullValidator(
              "5. Consent acceptance date",
              consent_acceptance_date
            ),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      } else if (type == otherProjectInspectionTypes.images) {
        const valError = validate([
          () => nullValidator("Page 1", page_1),
          () => nullValidator("Page 2", page_2),
          () => nullValidator("Page 3", page_3),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      }

      let consentClientSignature;
      if (getConsentClientSignature) {
        consentClientSignature = await getConsentClientSignature();
      }

      const str = await AppNetworkRest.createOtherProjectInspection({
        ...args,
        consent_client_signature: consentClientSignature,
        draft: false,
      });

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createOtherProjectInstallation = createAsyncThunk(
  "projects/createOtherProjectInstallation",
  async (args, { getState }) => {
    try {
      const {
        type,
        eow_customer_present,
        eow_construction_end_date,
        eow_missing_jobs,
        images,
        notes,
        declaration_notatory_declarations_of_end_of_work,
        consent_client_name,
        consent_acceptance_date,
        getConsentClientSignature,
        page_1,
      } = args;

      if (type == otherProjectInstallationTypes.form) {
        const valError = validate([
          () => nullValidator("1. Customer present", eow_customer_present),
          () =>
            nullValidator(
              "1. Construction end date",
              eow_construction_end_date
            ),
          () =>
            nullValidator(
              "3. Notatory declarations of end of work",
              declaration_notatory_declarations_of_end_of_work
            ),
          () => nullValidator("4. Client name", consent_client_name),
          () => nullValidator("4. Acceptance date", consent_acceptance_date),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      } else if (type == otherProjectInstallationTypes.images) {
        const valError = validate([() => nullValidator("Page 1", page_1)]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      }

      let consentClientSignature;
      if (getConsentClientSignature) {
        consentClientSignature = await getConsentClientSignature();
      }

      const str = await AppNetworkRest.createOtherProjectInstallation({
        ...args,
        consent_client_signature: consentClientSignature,
        draft: false,
      });

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const otherProjectsSlice = createSlice({
  name: "otherProjects",
  initialState,
  reducers: {
    clearOtherProjects: (state, action) => {
      state.otherProjects = [];
      state.pagesInfo = PagesInfo.parse({
        current: 1,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readOtherProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readOtherProjects.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        const [pagesInfo, projects] = action.payload;

        state.pagesInfo = pagesInfo;

        const array = [...state.otherProjects, ...projects];
        state.otherProjects = array.filter(
          (v, i, self) => i == self.findIndex((_) => _.id == v.id)
        );
      }
    });
    builder.addCase(readOtherProject.fulfilled, (state, action) => {
      if (action.payload) {
        state.otherProject = action.payload;
      }
    });
    builder.addCase(createOtherProjectInspection.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createOtherProjectInspection.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createOtherProjectInstallation.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      createOtherProjectInstallation.fulfilled,
      (state, action) => {
        state.isLoading = false;
      }
    );
  },
});

export {
  readOtherProjects,
  readOtherProject,
  createOtherProjectInspection,
  createOtherProjectInstallation,
};

export const { clearOtherProjects } = otherProjectsSlice.actions;

export default otherProjectsSlice.reducer;
