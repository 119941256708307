import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import { i18n } from "../utils/i18n";
import { Installation } from "../utils/models";

const initialState = {
  isLoading: false,
  installationDraft: null,
  installation: Installation.parse({}),
};

const readInstallationDraft = createAsyncThunk(
  "installationDrafts/readInstallationDraft",
  async (args, { getState }) => {
    const { project_id, type } = args;

    try {
      const installationDraft = await AppNetworkRest.readInstallationDraft(
        args
      );
      return installationDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createInstallationDraft = createAsyncThunk(
  "installationDrafts/createInstallationDraft",
  async (args, { getState }) => {
    try {
      const { orotund } = args ?? {};

      const installationDraft = await AppNetworkRest.createInstallation({
        draft: true,
        ...args,
      });

      if (installationDraft && orotund) {
        toast.success(i18n.t("installation_draft_saved_successfully."));
      }

      return installationDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const installationDraftsSlice = createSlice({
  name: "installationDrafts",
  initialState,
  reducers: {
    setInstallationDraft: (state, action) => {
      state.installationDraft = action.payload;
    },
    setInstallation: (state, action) => {
      state.installation = action.payload;
    },
    setInstallationValues: (state, action) => {
      const keys = Object.keys(action.payload ?? {});
      const values = Object.values(action.payload ?? {});

      for (let i = 0; i < keys.length; i++) {
        state.installation[keys[i]] = values[i];
      }

      state.installation = Installation.parse(state.installation.toMap());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readInstallationDraft.fulfilled, (state, action) => {
      if (action.payload) {
        state.installationDraft = action.payload;
      }
    });
    builder.addCase(createInstallationDraft.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createInstallationDraft.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setInstallationDraft, setInstallation, setInstallationValues } =
  installationDraftsSlice.actions;

export { readInstallationDraft, createInstallationDraft };

export default installationDraftsSlice.reducer;
