import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setSelectedRouteIndex } from "../controllers/control_bar";

import BNVHomeSVG from "../assets/images/bnv_home.svg";
import BNVProjectsSelectedSVG from "../assets/images/bnv_projects.svg";
import BNVProjectsUnselectedSVG from "../assets/images/bnv_projects.svg";
import BNVMaintenanceProjectsSelectedSVG from "../assets/images/bnv_maintenance_projects.svg";
import BNVMaintenanceProjectsUnselectedSVG from "../assets/images/bnv_maintenance_projects.svg";
import BNVSettingsSelectedSVG from "../assets/images/bnv_settings.svg";
import BNVSettingsUnselectedSVG from "../assets/images/bnv_settings.svg";
import BNVOtherProjectsSelectedSVG from "../assets/images/bnv_other_projects.svg";
import BNVOtherProjectsUnselectedSVG from "../assets/images/bnv_other_projects.svg";

const icons = [
  {
    title: "projects",
    route: "/projects",
    selectedIcon: BNVProjectsSelectedSVG,
    unSelectedIcon: BNVProjectsUnselectedSVG,
  },
  {
    title: "other_projects",
    route: "/other_projects",
    selectedIcon: BNVOtherProjectsSelectedSVG,
    unSelectedIcon: BNVOtherProjectsUnselectedSVG,
  },
  {
    title: "maintenances",
    route: "/maintenance_projects",
    selectedIcon: BNVMaintenanceProjectsSelectedSVG,
    unSelectedIcon: BNVMaintenanceProjectsUnselectedSVG,
  },
  {
    title: "settings",
    route: "/settings",
    selectedIcon: BNVSettingsSelectedSVG,
    unSelectedIcon: BNVSettingsUnselectedSVG,
  },
];

function CAppbar({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedRouteIndex = useSelector(
    (state) => state.controlBar.selectedRouteIndex
  );

  return (
    <div
      className={`sm:flex flex-row justify-evenly items-center h-[8%] w-[100%] bg-white absolute top-0 hidden ${
        className ?? ""
      }`}
    >
      <div className="sm:flex flex-row justify-start items-center h-[10%] w-[50%]">
        <div className="pl-[1%]" />
        <img
          src={BNVHomeSVG}
          className="h-[3rem] w-[3rem] cursor-pointer"
          onClick={() => {
            navigate("/");
            dispatch(setSelectedRouteIndex(0));
          }}
        />
      </div>
      <div className="sm:flex flex-row justify-end items-center h-[10%] w-[50%]">
        {icons.map((_, index) => (
          <CABListTile
            title={t(_.title)}
            isSelected={index + 1 == selectedRouteIndex}
            onClick={() => {
              navigate(_.route);
              dispatch(setSelectedRouteIndex(index + 1));
            }}
            selectedIcon={_.selectedIcon}
            unSelectedIcon={_.unSelectedIcon}
          />
        ))}
      </div>
    </div>
  );
}

function CABListTile({
  title,
  isSelected,
  onClick,
  selectedIcon,
  unSelectedIcon,
}) {
  return (
    <>
      <div className="px-[5%]">
        <div
          className="flex flex-row justify-end items-center cursor-pointer"
          onClick={onClick}
        >
          <img
            src={isSelected ? selectedIcon : unSelectedIcon}
            className="h-[1.5rem] w-[1.5rem]"
          />
          <div className="px-[0.3rem]" />
          <p
            className={`text-[1] font-[600] ${
              isSelected ? "text-primary" : "text-black"
            }`}
          >
            {title}
          </p>
        </div>
      </div>
    </>
  );
}

export default CAppbar;
