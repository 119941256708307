import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import localforage from "localforage";

import * as AppNetworkRest from "../utils/network_rest";
import * as AppRoutes from "../utils/routes";

const {
  nullValidator,
  emailValidator,
  lengthValidator,
  validate,
} = require("../utils/validators");

const initialState = {
  router: AppRoutes.splashRouter,
  isLoading: false,
};

const login = createAsyncThunk("auth/login", async (args) => {
  const { email, password } = args;

  const valError = validate([
    () => emailValidator("Email", email),
    () => lengthValidator("Password", password, 8),
  ]);
  if (valError) {
    toast.error(valError);
    return;
  }

  const appUser = await AppNetworkRest.login(args);

  if (appUser) {
    await appUser.cache();
    toast.success("Logged in.");
  }

  return appUser;
});

const resetPassword = createAsyncThunk("auth/resetPassword", async (args) => {
  const { old_password, new_password } = args;

  const valError = validate([
    () => nullValidator("Old Password", old_password),
    () => lengthValidator("New Password", new_password, 8),
  ]);
  if (valError) {
    toast.error(valError);
    return;
  }

  const str = await AppNetworkRest.resetPassword(args);

  if (str) {
    toast.success(str);
    return true;
  }

  return false;
});

const forgotPassword1 = createAsyncThunk(
  "auth/forgotPassword1",
  async (args) => {
    const { email } = args;

    const valError = validate([() => emailValidator("Email", email)]);
    if (valError) {
      toast.error(valError);
      return;
    }

    const str = await AppNetworkRest.forgotPassword1({
      email,
    });

    if (str) {
      toast.success(str);
      return true;
    }

    return false;
  }
);

const forgotPassword2 = createAsyncThunk(
  "auth/forgotPassword2",
  async (args) => {
    const { email, code } = args;

    const valError = validate([
      () => nullValidator("Email", email),
      () => nullValidator("Code", code),
    ]);
    if (valError) {
      toast.error(valError);
      return;
    }

    const str = await AppNetworkRest.forgotPassword2({
      email,
      code,
    });

    if (str) {
      toast.success(str);
      return true;
    }

    return false;
  }
);

const forgotPassword3 = createAsyncThunk(
  "auth/forgotPassword3",
  async (args) => {
    const { email, password } = args;

    const valError = validate([
      () => nullValidator("Email", email),
      () => lengthValidator("Password", password, 8),
    ]);
    if (valError) {
      toast.error(valError);
      return;
    }

    const str = await AppNetworkRest.forgotPassword3({
      email,
      password,
    });

    if (str) {
      toast.success(str);
      return true;
    }

    return false;
  }
);

const logout = createAsyncThunk("auth/logout", async (args) => {
  await localforage.removeItem("appUser");
  return;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRouter: (state, action) => {
      state.router = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.router = AppRoutes.userRouter;
      }
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.router = AppRoutes.authRouter;
      window.location = "/";
    });
  },
});

export const { setRouter } = authSlice.actions;

export {
  login,
  resetPassword,
  forgotPassword1,
  forgotPassword2,
  forgotPassword3,
  logout,
};

export default authSlice.reducer;
