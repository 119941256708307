import { createBrowserRouter } from "react-router-dom";

import SSplash from "../screens/splash";
import SLogin from "../screens/login";
import SProjects from "../screens/projects";
import SProjectInfo from "../screens/project_info";
import SInspectionForm from "../screens/inspection_form";
import SInspectionImages from "../screens/inspection_images";
import SInstallationForm from "../screens/installation_form";
import SInstallationImages from "../screens/installation_images";
import SSettings from "../screens/settings";
import SResetPassword from "../screens/reset_password";
import SForgotPassword1 from "../screens/forgot_password_1";
import SForgotPassword2 from "../screens/forgot_password_2";
import SForgotPassword3 from "../screens/forgot_password_3";
import SMaintenanceProjects from "../screens/maintenance_projects";
import SMaintenanceProjectInfo from "../screens/maintenance_project_info";
import SMaintenanceForm from "../screens/maintenance_form";
import SMaintenanceImages from "../screens/maintenance_images";
import SAbout from "../screens/about";
import SOtherProjects from "../screens/other_projects";
import SOtherProjectInfo from "../screens/other_project_info";
import SOtherProjectInspectionForm from "../screens/other_project_inspection_form";
import SOtherProjectInspectionImages from "../screens/other_project_inspection_images";
import SOtherProjectInstallationForm from "../screens/other_project_installation_form";
import SOtherProjectInstallationImages from "../screens/other_project_installation_images";
import SNotifications from "../screens/notificataions";

const splashRouter = createBrowserRouter([
  {
    path: "*",
    element: <SSplash />,
  },
]);

const authRouter = createBrowserRouter([
  {
    path: "*",
    element: <SLogin />,
  },
  {
    path: "/login",
    element: <SLogin />,
  },
  {
    path: "/forgot_password_1",
    element: <SForgotPassword1 />,
  },
  {
    path: "/forgot_password_2",
    element: <SForgotPassword2 />,
  },
  {
    path: "/forgot_password_3",
    element: <SForgotPassword3 />,
  },
]);

const userRouter = createBrowserRouter([
  {
    path: "*",
    element: <SProjects />,
  },
  {
    path: "/",
    element: <SProjects />,
  },
  {
    path: "/projects",
    element: <SProjects />,
  },
  {
    path: "/project_info",
    element: <SProjectInfo />,
  },
  {
    path: "/inspection_form",
    element: <SInspectionForm />,
  },
  {
    path: "/inspection_images",
    element: <SInspectionImages />,
  },
  {
    path: "/installation_form",
    element: <SInstallationForm />,
  },
  {
    path: "/installation_images",
    element: <SInstallationImages />,
  },
  {
    path: "/settings",
    element: <SSettings />,
  },
  {
    path: "/about",
    element: <SAbout />,
  },
  {
    path: "/reset_password",
    element: <SResetPassword />,
  },
  {
    path: "/maintenance_projects",
    element: <SMaintenanceProjects />,
  },
  {
    path: "/maintenance_project_info",
    element: <SMaintenanceProjectInfo />,
  },
  {
    path: "/maintenance_form",
    element: <SMaintenanceForm />,
  },
  {
    path: "/maintenance_images",
    element: <SMaintenanceImages />,
  },
  {
    path: "/other_projects",
    element: <SOtherProjects />,
  },
  {
    path: "/other_project_info",
    element: <SOtherProjectInfo />,
  },
  {
    path: "/other_project_inspection_form",
    element: <SOtherProjectInspectionForm />,
  },
  {
    path: "/other_project_inspection_images",
    element: <SOtherProjectInspectionImages />,
  },
  {
    path: "/other_project_installation_form",
    element: <SOtherProjectInstallationForm />,
  },
  {
    path: "/other_project_installation_images",
    element: <SOtherProjectInstallationImages />,
  },
  {
    path: "/notifications",
    element: <SNotifications />,
  },
]);

export { splashRouter, authRouter, userRouter };
