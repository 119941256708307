import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import { i18n } from "../utils/i18n";
import { OtherProjectInspection } from "../utils/models";

const initialState = {
  isLoading: false,
  otherProjectInspectionDraft: null,
  otherProjectInspection: OtherProjectInspection.parse({}),
};

const readOtherProjectInspectionDraft = createAsyncThunk(
  "otherProjectInspectionDrafts/readOtherProjectInspectionDraft",
  async (args, { getState }) => {
    const { other_project_id, type } = args;

    try {
      const otherProjectInspectionDraft =
        await AppNetworkRest.readOtherProjectInspectionDraft(args);
      return otherProjectInspectionDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createOtherProjectInspectionDraft = createAsyncThunk(
  "otherProjectinspectionDrafts/createOtherProjectInspectionDraft",
  async (args, { getState }) => {
    try {
      const { orotund } = args ?? {};

      const otherProjectInspectionDraft =
        await AppNetworkRest.createOtherProjectInspection({
          ...args,
          draft: true,
        });

      if (otherProjectInspectionDraft && orotund) {
        toast.success(i18n.t("inspection_draft_saved_successfully."));
      }

      return otherProjectInspectionDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const otherProjectInspectionDraftsSlice = createSlice({
  name: "otherProjectInspectionDrafts",
  initialState,
  reducers: {
    setOtherProjectInspectionDraft: (state, action) => {
      state.otherProjectInspectionDraft = action.payload;
    },
    setOtherProjectInspection: (state, action) => {
      state.otherProjectInspection = action.payload;
    },
    setOtherProjectInspectionValues: (state, action) => {
      const keys = Object.keys(action.payload ?? {});
      const values = Object.values(action.payload ?? {});

      for (let i = 0; i < keys.length; i++) {
        state.otherProjectInspection[keys[i]] = values[i];
      }

      state.otherProjectInspection = OtherProjectInspection.parse(
        state.otherProjectInspection.toMap()
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      readOtherProjectInspectionDraft.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.otherProjectInspectionDraft = action.payload;
        }
      }
    );
    builder.addCase(
      createOtherProjectInspectionDraft.pending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      createOtherProjectInspectionDraft.fulfilled,
      (state, action) => {
        state.isLoading = false;
      }
    );
  },
});

export const {
  setOtherProjectInspectionDraft,
  setOtherProjectInspection,
  setOtherProjectInspectionValues,
} = otherProjectInspectionDraftsSlice.actions;

export { readOtherProjectInspectionDraft, createOtherProjectInspectionDraft };

export default otherProjectInspectionDraftsSlice.reducer;
