import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  createMaintenance,
  readMaintenanceProject,
} from "../controllers/maintenance_projects";
import {
  createMaintenanceDraft,
  readMaintenanceDraft,
  setMaintenance,
  setMaintenanceDraft,
  setMaintenanceValues,
} from "../controllers/maintenance_drafts";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";
import CAddImage from "../components/add_image";
import CDialog from "../components/dialog";
import { useTranslation } from "react-i18next";

import SaveSVG from "../assets/images/save.svg";

import { mergeObjects, uploadNamedFileOrParseMaker } from "../utils/misc";
import { maintenanceTypes } from "../utils/constants";
import { Maintenance } from "../utils/models";

function SMaintenanceForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const maintenanceProjectID = searchParams.get("maintenance_project_id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.maintenanceProjects.isLoading);
  const project = useSelector(
    (state) => state.maintenanceProjects.maintenanceProject
  );
  const file = useSelector((state) => state.files.file);
  const isLoadingMaintenanceDraft = useSelector(
    (state) => state.maintenanceDrafts.isLoading
  );
  const [hasLoadedMaintenanceDraft, setHasLoadedMaintenanceDraft] =
    useState(false);
  const maintenanceDraft = useSelector(
    (state) => state.maintenanceDrafts.maintenanceDraft
  );
  const maintenanceState = useSelector(
    (state) => state.maintenanceDrafts.maintenance
  );

  const [isSavingMaintenanceDraft, setIsSavingMaintenanceDraft] =
    useState(false);

  const maintenance = useMemo(() => {
    return Maintenance.parse(maintenanceState.toMap());
  }, [maintenanceState]);

  useEffect(() => {
    if (maintenanceProjectID) {
      dispatch(
        readMaintenanceProject({
          id: maintenanceProjectID,
        })
      );
    }
  }, [maintenanceProjectID]);

  useEffect(() => {
    if (
      !hasLoadedMaintenanceDraft &&
      maintenanceDraft &&
      maintenanceDraft.type == maintenanceTypes.images
    ) {
      window.modal_maintenance_draft.showModal();
      setHasLoadedMaintenanceDraft(true);
    }
  }, [maintenanceDraft]);

  useEffect(() => {
    dispatch(
      readMaintenanceDraft({
        maintenance_project_id: maintenanceProjectID,
        type: maintenanceTypes.images,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      setMaintenanceDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${project?.client?.firstName} - ${project?.client?.lastName} - Maintenance`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingMaintenanceDraft(true);

    let page1 = await uploadNamedFileOrParse({
      file: maintenance.page1,
      name: t("page_1"),
    });

    dispatch(
      setMaintenanceValues({
        page1,
      })
    );

    const _maintenanceDraft = await dispatch(
      createMaintenanceDraft({
        ...maintenanceState.toBody(),
        orotund,
        type: maintenanceTypes.images,
        maintenance_project_id: maintenanceProjectID,
      })
    );

    dispatch(
      setMaintenance(
        Maintenance.parse(
          mergeObjects(_maintenanceDraft.payload.toMap(), maintenance.toMap())
        )
      )
    );

    setIsSavingMaintenanceDraft(false);

    return;
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("maintenance")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingMaintenanceDraft || isSavingMaintenanceDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedMaintenanceDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            {isLoading && file == maintenance.page1 ? (
              <div className="h-[40px] w-[40px]">
                <CLoader />
              </div>
            ) : null}
            <CAddImage
              label={t("page_1")}
              image={maintenance.page1}
              setImage={(_) =>
                dispatch(
                  setMaintenanceValues({
                    page1: _,
                  })
                )
              }
            />
            <div className="flex-grow" />
            <CButton
              className="w-[100%] my-[14px]"
              text={t("continue")}
              isLoading={isLoading}
              onClick={async () => {
                await saveAndLoadDraft();

                const res = await dispatch(
                  createMaintenance({
                    ...maintenanceState.toBody(),
                    type: maintenanceTypes.images,
                    maintenance_project_id: maintenanceProjectID,
                  })
                );

                if (res?.payload) {
                  navigate("/maintenance_projects");
                }
              }}
            />
            <CDialog
              name="modal_maintenance_draft"
              title={t("maintenance_draft_found")}
              description={t("do_you_want_to_load_data_from_the_saved_draft?")}
              approvalText={t("load_data")}
              onApproval={() => {
                dispatch(
                  setMaintenance(
                    Maintenance.parse(
                      mergeObjects(
                        maintenanceDraft.toMap(),
                        maintenance.toMap()
                      )
                    )
                  )
                );
                window.modal_maintenance_draft.close();
              }}
              rejectionText={t("delete_draft")}
              onRejection={() => {}}
            />
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SMaintenanceForm;
