import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import localforage from "localforage";

import { configDB } from "../utils/globals";
import { setRouter } from "../controllers/auth";
import { authRouter, userRouter } from "../utils/routes";
import initLocales from "../utils/i18n";

async function getRouter() {
  let appUser = await localforage.getItem("appUser");
  if (!appUser) {
    return authRouter;
  } else {
    return userRouter;
  }
}

function SMain() {
  const router = useSelector((state) => state.auth.router);

  const dispatch = useDispatch();

  useEffect(() => {
    configDB();
  }, []);

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    const pass = await initLocales();
    if (pass) {
      const _router = await getRouter();
      dispatch(setRouter(_router));
    }
  };

  return router ? <RouterProvider router={router} /> : <></>;
}

export default SMain;
