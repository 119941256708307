import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { createInstallation, readProject } from "../controllers/projects";
import { setFile, uploadFile } from "../controllers/files";
import {
  createInstallationDraft,
  readInstallationDraft,
  setInstallationDraft,
  setInstallation,
  setInstallationValues,
} from "../controllers/installation_drafts";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CInput from "../components/input";
import CButton from "../components/button";
import CDialog from "../components/dialog";
import { CDropDown, CDropDownMenu } from "../components/dropdown";
import CCollapse from "../components/collapse";
import CDateTimePicker from "../components/datetimepicker";
import CAddImage from "../components/add_image";
import CCarousel from "../components/carousel";

import CancelSVG from "../assets/images/cancel.svg";
import SaveSVG from "../assets/images/save.svg";

import {
  dataURLtoFile,
  mergeObjects,
  uploadNamedFileOrParseMaker,
} from "../utils/misc";
import {
  filesURLSplitSequence,
  installationTypes,
  locales,
} from "../utils/constants";
import { Installation, generateNullImagesObject } from "../utils/models";

const collapseNames = {
  end_of_works: "end_of_works",
  end_of_installation: "end_of_installation",
  declaration: "declaration",
  consent: "consent",
};

const locale = localStorage.getItem("locale") ?? locales.italian;

function SInstallationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get("project_id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.projects.isLoading);
  const project = useSelector((state) => state.projects.project);
  const file = useSelector((state) => state.files.file);
  const isLoadingInstallationDraft = useSelector(
    (state) => state.installationDrafts.isLoading
  );
  const installationDraft = useSelector(
    (state) => state.installationDrafts.installationDraft
  );
  const installationState = useSelector(
    (state) => state.installationDrafts.installation
  );

  const [openedCollapse, setOpenedCollapse] = useState(
    collapseNames.end_of_works
  );

  const _setOpenedCollapse = (name) => {
    if (name != openedCollapse) {
      setOpenedCollapse(name);
    } else {
      setOpenedCollapse(null);
    }
  };

  const [isSavingInstallationDraft, setIsSavingInstallationDraft] =
    useState(false);

  const installation = useMemo(() => {
    return Installation.parse(installationState.toMap());
  }, [installationState]);

  const [hasLoadedInstallationDraft, setHasLoadedInstallationDraft] =
    useState(false);

  const consentClientSignatureRef = useRef(null);

  // SelfTest images flow.
  useEffect(() => {
    if (["No", "NO"].includes(installation.eoiSelfTest)) {
      dispatch(
        setInstallationValues({
          eoiNoOfSelfTestImages: 0,
        })
      );
    }
  }, [installation.eoiSelfTest]);
  useEffect(() => {
    if (
      installation.eoiNoOfSelfTestImages !=
      Object.keys(installation.eoiSelfTestImages).length
    ) {
      const obj = generateNullImagesObject(installation.eoiNoOfSelfTestImages);
      dispatch(
        setInstallationValues({
          eoiSelfTestImages: obj,
        })
      );
    }
  }, [installation.eoiNoOfSelfTestImages]);

  useEffect(() => {
    if (projectID) {
      dispatch(
        readProject({
          id: projectID,
        })
      );
    }
  }, [projectID]);

  useEffect(() => {
    if (
      !hasLoadedInstallationDraft &&
      installationDraft &&
      installationDraft.type == installationTypes.form
    ) {
      window.modal_installation_draft.showModal();
      setHasLoadedInstallationDraft(true);
    }
  }, [installationDraft]);

  useEffect(() => {
    dispatch(
      readInstallationDraft({
        project_id: projectID,
        type: installationTypes.form,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      setInstallationDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${project?.client?.firstName} - ${project?.client?.lastName} - Installation`,
  });

  async function uploadImagesField(images, label) {
    if (images && typeof images == "object") {
      let _images = { ...images };

      const keys = Object.keys(images);
      const values = Object.values(images);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = values[i];
        if (value) {
          const image = await uploadNamedFileOrParse({
            file: value,
            name: `${label} - ${parseInt(key) + 1}`,
          });
          _images[key] = image;
        }
      }

      return _images;
    } else {
      return images;
    }
  }

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingInstallationDraft(true);

    let eoiPhotovoltaicFieldImages = await uploadImagesField(
      installation.eoiPhotovoltaicFieldImages,
      t("photovoltaic_field_images")
    );

    let eoiTechnicalRoomImages = await uploadImagesField(
      installation.eoiTechnicalRoomImages,
      t("technical_room_images")
    );

    let eoiPanelLabelImages = "";
    if (installation.eoiNoOfPanelsInstalled > 0) {
      eoiPanelLabelImages = await uploadImagesField(
        installation.eoiPanelLabelImages,
        t("panel_label_images")
      );
    }

    let eoiInverterLabelImages = await uploadImagesField(
      installation.eoiInverterLabelImages,
      t("inverter_label_images")
    );

    let eoiBatterySerialNoImages = "";
    if (["Yes", "SÌ"].includes(installation.eoiBatteryInstalled)) {
      eoiBatterySerialNoImages = await uploadImagesField(
        installation.eoiBatterySerialNoImages,
        t("battery_serial_number_images")
      );
    }

    let eoiSelfTestImages = "";
    if (["Yes", "SÌ"].includes(installation.eoiSelfTest)) {
      if (installation.eoiNoOfSelfTestImages > 0) {
        eoiSelfTestImages = await uploadImagesField(
          installation.eoiSelfTestImages,
          t("self_test_images")
        );
      }
    }

    dispatch(
      setInstallationValues({
        eoiPhotovoltaicFieldImages,
        eoiTechnicalRoomImages,
        eoiPanelLabelImages,
        eoiInverterLabelImages,
        eoiBatterySerialNoImages,
        eoiSelfTestImages,
      })
    );

    const _installationDraft = await dispatch(
      createInstallationDraft({
        ...installationState.toBody(),
        orotund,
        type: installationTypes.form,
        project_id: projectID,
      })
    );

    dispatch(
      setInstallation(
        Installation.parse(
          mergeObjects(_installationDraft.payload.toMap(), installation.toMap())
        )
      )
    );

    setIsSavingInstallationDraft(false);

    return;
  }

  async function getConsentClientSignature() {
    const consentClientSignature = consentClientSignatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    if (consentClientSignature) {
      const consentClientSignatureFile = dataURLtoFile(
        consentClientSignature,
        "signature.jpeg"
      );
      await dispatch(setFile(consentClientSignatureFile));
      const _consentClientSignatureFile = await dispatch(
        uploadFile({
          name: `${project?.client?.firstName} - ${
            project?.client?.lastName
          } - Installation - Client Signature - ${Date.now()}.jpeg`,
        })
      );

      if (_consentClientSignatureFile.payload) {
        _consentClientSignatureFile.payload =
          _consentClientSignatureFile.payload
            .split(filesURLSplitSequence)
            .pop();
      }

      return _consentClientSignatureFile.payload;
    } else {
      toast.error("Signature can't be empty.");
      return;
    }
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("installation")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingInstallationDraft || isSavingInstallationDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedInstallationDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          <CCollapse
            label={`1. ${t(collapseNames.end_of_works)}`}
            isSelected={openedCollapse == collapseNames.end_of_works}
            onClick={() => _setOpenedCollapse(collapseNames.end_of_works)}
          >
            <CDropDown
              label={t("customer_presence")}
              value={installation.eowCustomerPresent}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={installation.eowCustomerPresent == _}
                    onClick={() =>
                      dispatch(
                        setInstallationValues({
                          eowCustomerPresent: _,
                        })
                      )
                    }
                  />
                )
              )}
            </CDropDown>
            <p className="text-[12px]">{t("construction_end_date_and_time")}</p>
            <CDateTimePicker
              value={installation.eowConstructionEndDate}
              onChange={(_) =>
                dispatch(
                  setInstallationValues({
                    eowConstructionEndDate: _,
                  })
                )
              }
            />
            <div className="h-[2%]" />
            <CInput
              label={t("missing_jobs")}
              placeholder={t("enter_missing_jobs")}
              value={installation.eowMissingJobs}
              onChange={(e) =>
                dispatch(
                  setInstallationValues({
                    eowMissingJobs: e.target.value,
                  })
                )
              }
            />
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse
            label={`2. ${t(collapseNames.end_of_installation)}`}
            isSelected={openedCollapse == collapseNames.end_of_installation}
            onClick={() =>
              _setOpenedCollapse(collapseNames.end_of_installation)
            }
          >
            {isLoading &&
            Object.values(installation.eoiPhotovoltaicFieldImages).includes(
              file
            ) ? (
              <div className="h-[40px] w-[40px]">
                <CLoader />
              </div>
            ) : null}
            <p className="text-[12px]">{t("photovoltaic_field_images")}</p>
            <CCarousel>
              {Object.keys(installation.eoiPhotovoltaicFieldImages).map(
                (key) => (
                  <CAddImage
                    label={`${parseInt(key) + 1}.`}
                    image={installation.eoiPhotovoltaicFieldImages[key]}
                    setImage={(_) => {
                      const obj = {
                        ...installation.eoiPhotovoltaicFieldImages,
                      };
                      obj[key] = _;

                      dispatch(
                        setInstallationValues({
                          eoiPhotovoltaicFieldImages: obj,
                        })
                      );
                    }}
                  />
                )
              )}
            </CCarousel>
            {isLoading &&
            Object.values(installation.eoiTechnicalRoomImages).includes(
              file
            ) ? (
              <div className="h-[40px] w-[40px]">
                <CLoader />
              </div>
            ) : null}
            <p className="text-[12px]">{t("technical_room_images")}</p>
            <CCarousel>
              {Object.keys(installation.eoiTechnicalRoomImages).map((key) => (
                <CAddImage
                  label={`${parseInt(key) + 1}.`}
                  image={installation.eoiTechnicalRoomImages[key]}
                  setImage={(_) => {
                    const obj = {
                      ...installation.eoiTechnicalRoomImages,
                    };
                    obj[key] = _;

                    dispatch(
                      setInstallationValues({
                        eoiTechnicalRoomImages: obj,
                      })
                    );
                  }}
                />
              ))}
            </CCarousel>
            <CDropDown
              label={t("no_of_panels_Installed")}
              value={installation.eoiNoOfPanelsInstalled}
            >
              {[0, ...Array.from({ length: 50 }, (_, index) => index + 1)].map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={installation.eoiNoOfPanelsInstalled == _}
                    onClick={() => {
                      dispatch(
                        setInstallationValues({
                          eoiNoOfPanelsInstalled: _,
                        })
                      );

                      if (
                        _ !=
                        Object.keys(installation.eoiPanelLabelImages).length
                      ) {
                        const obj = generateNullImagesObject(_);
                        dispatch(
                          setInstallationValues({
                            eoiPanelLabelImages: obj,
                          })
                        );
                      }
                    }}
                  />
                )
              )}
            </CDropDown>
            {installation.eoiNoOfPanelsInstalled > 0 ? (
              <>
                {isLoading &&
                Object.values(installation.eoiPanelLabelImages).includes(
                  file
                ) ? (
                  <div className="h-[40px] w-[40px]">
                    <CLoader />
                  </div>
                ) : null}
                <p className="text-[12px]">{t("panel_label_images")}</p>
                <CCarousel>
                  {Object.keys(installation.eoiPanelLabelImages).map((key) => (
                    <CAddImage
                      label={`${parseInt(key) + 1}.`}
                      image={installation.eoiPanelLabelImages[key]}
                      setImage={(_) => {
                        const obj = {
                          ...installation.eoiPanelLabelImages,
                        };
                        obj[key] = _;

                        dispatch(
                          setInstallationValues({
                            eoiPanelLabelImages: obj,
                          })
                        );
                      }}
                    />
                  ))}
                </CCarousel>
              </>
            ) : null}
            {isLoading &&
            Object.values(installation.eoiInverterLabelImages).includes(
              file
            ) ? (
              <div className="h-[40px] w-[40px]">
                <CLoader />
              </div>
            ) : null}
            <p className="text-[12px]">{t("inverter_label_images")}</p>
            <CCarousel>
              {Object.keys(installation.eoiInverterLabelImages).map((key) => (
                <CAddImage
                  label={`${parseInt(key) + 1}.`}
                  image={installation.eoiInverterLabelImages[key]}
                  setImage={(_) => {
                    const obj = {
                      ...installation.eoiInverterLabelImages,
                    };
                    obj[key] = _;

                    dispatch(
                      setInstallationValues({
                        eoiInverterLabelImages: obj,
                      })
                    );
                  }}
                />
              ))}
            </CCarousel>
            <CDropDown
              label={t("battery_installed")}
              value={installation.eoiBatteryInstalled}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={installation.eoiBatteryInstalled == _}
                    onClick={() => {
                      dispatch(
                        setInstallationValues({
                          eoiBatteryInstalled: _,
                        })
                      );

                      if (
                        _ !=
                        Object.keys(installation.eoiBatterySerialNoImages)
                          .length
                      ) {
                        dispatch(
                          setInstallationValues({
                            eoiBatterySerialNoImages: generateNullImagesObject(
                              ["Yes", "SÌ"].includes(_) ? 2 : 0
                            ),
                          })
                        );
                      }
                    }}
                  />
                )
              )}
            </CDropDown>
            {["Yes", "SÌ"].includes(installation.eoiBatteryInstalled) ? (
              <>
                {isLoading &&
                Object.values(installation.eoiBatterySerialNoImages).includes(
                  file
                ) ? (
                  <div className="h-[40px] w-[40px]">
                    <CLoader />
                  </div>
                ) : null}
                <p className="text-[12px]">
                  {t("battery_serial_number_images")}
                </p>
                <CCarousel>
                  {Object.keys(installation.eoiBatterySerialNoImages).map(
                    (key) => (
                      <CAddImage
                        label={`${parseInt(key) + 1}.`}
                        image={installation.eoiBatterySerialNoImages[key]}
                        setImage={(_) => {
                          const obj = {
                            ...installation.eoiBatterySerialNoImages,
                          };
                          obj[key] = _;

                          dispatch(
                            setInstallationValues({
                              eoiBatterySerialNoImages: obj,
                            })
                          );
                        }}
                      />
                    )
                  )}
                </CCarousel>
              </>
            ) : null}
            <CDropDown label={t("self_test")} value={installation.eoiSelfTest}>
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={installation.eoiSelfTest == _}
                    onClick={() => {
                      dispatch(
                        setInstallationValues({
                          eoiSelfTest: _,
                        })
                      );
                    }}
                  />
                )
              )}
            </CDropDown>
            {["Yes", "SÌ"].includes(installation.eoiSelfTest) ? (
              <CDropDown
                label={t("no_of_self_test_images")}
                value={installation.eoiNoOfSelfTestImages}
              >
                {[
                  0,
                  ...Array.from({ length: 14 }, (_, index) => index + 2),
                ].map((_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={installation.eoiNoOfSelfTestImages == _}
                    onClick={() =>
                      dispatch(
                        setInstallationValues({
                          eoiNoOfSelfTestImages: _,
                        })
                      )
                    }
                  />
                ))}
              </CDropDown>
            ) : null}
            {installation.eoiNoOfSelfTestImages > 0 ? (
              <>
                {isLoading &&
                Object.values(installation.eoiSelfTestImages).includes(file) ? (
                  <div className="h-[40px] w-[40px]">
                    <CLoader />
                  </div>
                ) : null}
                <p className="text-[12px]">{t("self_test_images")}</p>
                <CCarousel>
                  {Object.keys(installation.eoiSelfTestImages).map((key) => (
                    <CAddImage
                      label={`${parseInt(key) + 1}.`}
                      image={installation.eoiSelfTestImages[key]}
                      setImage={(_) => {
                        const obj = {
                          ...installation.eoiSelfTestImages,
                        };
                        obj[key] = _;

                        dispatch(
                          setInstallationValues({
                            eoiSelfTestImages: obj,
                          })
                        );
                      }}
                    />
                  ))}
                </CCarousel>
              </>
            ) : null}
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse
            label={`3. ${t(collapseNames.declaration)}`}
            isSelected={openedCollapse == collapseNames.declaration}
            onClick={() => _setOpenedCollapse(collapseNames.declaration)}
          >
            <div className="flex flex-col justify-start items-start w-[100%]">
              <p className="text-[12px]">
                {t("notatory_declartions_of_end_of_work")}
              </p>
              <textarea
                className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[25dvh] resize-none"
                placeholder={t("enter_declarations_of_end_of_work")}
                value={installation.declarationNotatoryDeclarationsOfEndOfWork}
                onChange={(e) =>
                  dispatch(
                    setInstallationValues({
                      declarationNotatoryDeclarationsOfEndOfWork:
                        e.target.value,
                    })
                  )
                }
                type="text"
              />
            </div>
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse label={`4. ${t(collapseNames.consent)}`} isSelected={true}>
            <CInput
              label={t("client_name")}
              placeholder={t("enter_client_name")}
              value={installation.consentClientName}
              onChange={(e) =>
                dispatch(
                  setInstallationValues({
                    consentClientName: e.target.value,
                  })
                )
              }
            />
            <div className="h-[2%]" />
            <p className="text-[12px]">{t("acceptance_date")}</p>
            <CDateTimePicker
              value={installation.consentAcceptanceDate}
              onChange={(_) =>
                dispatch(
                  setInstallationValues({
                    consentAcceptanceDate: _,
                  })
                )
              }
            />
            <div className="flex flex-row items-center justify-between">
              <p className={`text-[12px]`}>{t("client_signature")}</p>
              <img
                src={CancelSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={() => {
                  consentClientSignatureRef?.current?.clear();
                }}
              />
            </div>
            <SignatureCanvas
              penColor="#3466AA"
              canvasProps={{
                className:
                  "min-h-[200px] w-[100%] border-[1px] border-[#000000] border-solid rounded-[10px]",
              }}
              ref={consentClientSignatureRef}
              clearOnResize={false}
            />
          </CCollapse>
          <div className="flex-grow" />
          <CButton
            className="w-[100%] my-[14px]"
            text={t("continue")}
            isLoading={isLoading}
            onClick={async () => {
              await saveAndLoadDraft();

              const res = await dispatch(
                createInstallation({
                  ...installationState.toBody(),
                  type: installationTypes.form,
                  project_id: projectID,
                  getConsentClientSignature: getConsentClientSignature,
                })
              );

              if (res?.payload) {
                navigate("/");
              }
            }}
          />
          <CDialog
            name="modal_installation_draft"
            title={t("installation_draft_found")}
            description={t("do_you_want_to_load_data_from_the_saved_draft?")}
            approvalText={t("load_data")}
            onApproval={() => {
              dispatch(
                setInstallation(
                  Installation.parse(
                    mergeObjects(
                      installationDraft.toMap(),
                      installation.toMap()
                    )
                  )
                )
              );
              window.modal_installation_draft.close();
            }}
            rejectionText={t("delete_draft")}
            onRejection={() => {}}
          />
        </div>
      </div>
    </CContainer>
  );
}

export default SInstallationForm;
