import React, { useRef } from "react";

import LeftSVG from "../assets/images/left.svg";
import RightSVG from "../assets/images/right.svg";

function CCarousel({ children }) {
  const ref = useRef(null);

  return (
    <>
      <div className="flex flex-row items-center justify-center w-[100%]">
        <img
          src={LeftSVG}
          className="h-[2.5rem] w-[2.5rem] cursor-pointer"
          onClick={() => {
            if (ref.current.scrollLeft === 0) {
              ref.current.scrollBy({
                left: ref.current.scrollWidth,
                behavior: "smooth",
              });
            } else {
              ref.current.scrollBy({
                left: -ref.current.offsetWidth,
                behavior: "smooth",
              });
            }
          }}
        />
        <div
          ref={ref}
          class="flex flex-row flex-no-wrap w-[100%] overflow-x-auto"
        >
          {children}
        </div>
        <img
          src={RightSVG}
          className="h-[2.5rem] w-[2.5rem] cursor-pointer"
          onClick={() => {
            if (
              ref.current.scrollLeft + ref.current.offsetWidth + 1 >=
              ref.current.scrollWidth
            ) {
              ref.current.scrollTo({
                left: 0,
                behavior: "smooth",
              });
            } else {
              ref.current.scrollBy({
                left: ref.current.offsetWidth,
                behavior: "smooth",
              });
            }
          }}
        />
      </div>
    </>
  );
}

export default CCarousel;
