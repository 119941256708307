import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CContainer from "../components/container";

import { resetPassword } from "../controllers/auth";

import CInput from "../components/input";
import CButton from "../components/button";

function SResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const onClick = async () => {
    const res = await dispatch(
      resetPassword({
        old_password: oldPassword,
        new_password: newPassword,
      })
    );
    if (res.payload) {
      navigate(-1);
    }
  };

  return (
    <CContainer>
      <div className="flex flex-col items-center justify-center h-[100%] w-[100%] bg-background">
        <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
          <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
            <div className="flex flex-col justify-start h-[100%] w-[100%]">
              <p className="text-[20px] font-[500] self-center">
                {t("reset_password")}
              </p>
              <div className="h-[20px]" />
              <CInput
                label={t("old_password")}
                type="password"
                className="mb-[14px] mt-[5px]"
                placeholder={t("enter_your_old_password")}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <div className="h-[2%]" />
              <CInput
                label={t("new_password")}
                type="password"
                className="mb-[14px] mt-[5px]"
                placeholder={t("enter_your_new_password")}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    onClick();
                  }
                }}
              />
              <div className="relative h-[100%] w-[100%]">
                <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
                  <CButton
                    className="my-[14px] w-[100%]"
                    text={t("continue")}
                    onClick={onClick}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SResetPassword;
