import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { setFile, uploadFile } from "../controllers/files";
import {
  createMaintenance,
  readMaintenanceProject,
} from "../controllers/maintenance_projects";
import {
  createMaintenanceDraft,
  readMaintenanceDraft,
  setMaintenanceDraft,
  setMaintenance,
  setMaintenanceValues,
} from "../controllers/maintenance_drafts";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CInput from "../components/input";
import CButton from "../components/button";
import CDialog from "../components/dialog";
import { CDropDown, CDropDownMenu } from "../components/dropdown";
import CCollapse from "../components/collapse";
import CDateTimePicker from "../components/datetimepicker";
import CAddImage from "../components/add_image";
import CCarousel from "../components/carousel";

import CancelSVG from "../assets/images/cancel.svg";
import SaveSVG from "../assets/images/save.svg";

import {
  dataURLtoFile,
  mergeObjects,
  uploadNamedFileOrParseMaker,
} from "../utils/misc";
import {
  filesURLSplitSequence,
  installationTypes,
  locales,
  maintenanceTypes,
} from "../utils/constants";
import {
  Installation,
  Maintenance,
  generateNullImagesObject,
} from "../utils/models";

const collapseNames = {
  generic_info: "generic_info",
  end_of_maintenance_checklist: "end_of_maintenance_checklist",
};

const locale = localStorage.getItem("locale") ?? locales.italian;

function SMaintenanceForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const maintenanceProjectID = searchParams.get("maintenance_project_id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.maintenanceProjects.isLoading);
  const maintenanceProject = useSelector(
    (state) => state.maintenanceProjects.maintenanceProject
  );
  const file = useSelector((state) => state.files.file);
  const isLoadingMaintenanceDraft = useSelector(
    (state) => state.maintenanceDrafts.isLoading
  );
  const maintenanceDraft = useSelector(
    (state) => state.maintenanceDrafts.maintenanceDraft
  );
  const maintenanceState = useSelector(
    (state) => state.maintenanceDrafts.maintenance
  );

  const [openedCollapse, setOpenedCollapse] = useState(
    collapseNames.generic_info
  );

  const _setOpenedCollapse = (name) => {
    if (name != openedCollapse) {
      setOpenedCollapse(name);
    } else {
      setOpenedCollapse(null);
    }
  };

  const [isSavingMaintenanceDraft, setIsSavingMaintenanceDraft] =
    useState(false);

  const maintenance = useMemo(() => {
    return Maintenance.parse(maintenanceState.toMap());
  }, [maintenanceState]);

  const [hasLoadedMaintenanceDraft, setHasLoadedMaintenanceDraft] =
    useState(false);

  const eomTechnicianSignatureRef = useRef(null);
  const eomClientSignatureRef = useRef(null);

  useEffect(() => {
    if (maintenanceProjectID) {
      dispatch(
        readMaintenanceProject({
          id: maintenanceProjectID,
        })
      );
    }
  }, [maintenanceProjectID]);

  useEffect(() => {
    if (
      !hasLoadedMaintenanceDraft &&
      maintenanceDraft &&
      maintenanceDraft.type == maintenanceTypes.form
    ) {
      window.modal_maintenance_draft.showModal();
      setHasLoadedMaintenanceDraft(true);
    }
  }, [maintenanceDraft]);

  useEffect(() => {
    dispatch(
      readMaintenanceDraft({
        maintenance_project_id: maintenanceProjectID,
        type: maintenanceTypes.form,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      setMaintenanceDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${maintenanceProject?.client?.firstName} - ${maintenanceProject?.client?.lastName} - Maintenance`,
  });

  async function uploadImagesField(images, label) {
    if (images && typeof images == "object") {
      let _images = { ...images };

      const keys = Object.keys(images);
      const values = Object.values(images);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = values[i];
        if (value) {
          const image = await uploadNamedFileOrParse({
            file: value,
            name: `${label} - ${parseInt(key) + 1}`,
          });
          _images[key] = image;
        }
      }

      return _images;
    } else {
      return images;
    }
  }

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingMaintenanceDraft(true);

    let causesOfFaultImage = await uploadNamedFileOrParse({
      file: maintenance.causesOfFaultImage,
      name: t("causes_of_fault_image"),
    });

    let eomPhotosOfWorkedCarriedBefore = "";
    if (maintenance.eomNoOfPhotosOfWorkedCarriedBefore > 0) {
      eomPhotosOfWorkedCarriedBefore = await uploadImagesField(
        maintenance.eomPhotosOfWorkedCarriedBefore,
        t("photos_of_worked_carried_before")
      );
    }

    let eomPhotosOfWorkedCarriedAfter = "";
    if (maintenance.eomNoOfPhotosOfWorkedCarriedAfter > 0) {
      eomPhotosOfWorkedCarriedAfter = await uploadImagesField(
        maintenance.eomPhotosOfWorkedCarriedAfter,
        t("photos_of_worked_carried_after")
      );
    }

    dispatch(
      setMaintenanceValues({
        causesOfFaultImage,
        eomPhotosOfWorkedCarriedBefore,
        eomPhotosOfWorkedCarriedAfter,
      })
    );

    const _maintenanceDraft = await dispatch(
      createMaintenanceDraft({
        ...maintenanceState.toBody(),
        orotund,
        type: maintenanceTypes.form,
        maintenance_project_id: maintenanceProjectID,
      })
    );

    dispatch(
      setMaintenance(
        Maintenance.parse(
          mergeObjects(_maintenanceDraft.payload.toMap(), maintenance.toMap())
        )
      )
    );

    setIsSavingMaintenanceDraft(false);

    return;
  }

  async function getEOMTechnicianSignature() {
    const eomTechnicianSignature = eomTechnicianSignatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    if (eomTechnicianSignature) {
      const eomTechnicianSignatureFile = dataURLtoFile(
        eomTechnicianSignature,
        "signature.jpeg"
      );
      await dispatch(setFile(eomTechnicianSignatureFile));
      const _eomTechnicianSignatureFile = await dispatch(
        uploadFile({
          name: `${maintenanceProject?.client?.firstName} - ${
            maintenanceProject?.client?.lastName
          } - Maintenance - Technician Signature - ${Date.now()}.jpeg`,
        })
      );

      if (_eomTechnicianSignatureFile.payload) {
        _eomTechnicianSignatureFile.payload =
          _eomTechnicianSignatureFile.payload
            .split(filesURLSplitSequence)
            .pop();
      }

      return _eomTechnicianSignatureFile.payload;
    } else {
      toast.error("Technician signature can't be empty.");
      return;
    }
  }

  async function getEOMClientSignature() {
    const eomClientSignature = eomClientSignatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    if (eomClientSignature) {
      const eomClientSignatureFile = dataURLtoFile(
        eomClientSignature,
        "signature.jpeg"
      );
      await dispatch(setFile(eomClientSignatureFile));
      const _eomClientSignatureFile = await dispatch(
        uploadFile({
          name: `${maintenanceProject?.client?.firstName} - ${
            maintenanceProject?.client?.lastName
          } - Maintenance - Client Signature - ${Date.now()}.jpeg`,
        })
      );

      if (_eomClientSignatureFile.payload) {
        _eomClientSignatureFile.payload = _eomClientSignatureFile.payload
          .split(filesURLSplitSequence)
          .pop();
      }

      return _eomClientSignatureFile.payload;
    } else {
      toast.error("Client signature can't be empty.");
      return;
    }
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("maintenance")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingMaintenanceDraft || isSavingMaintenanceDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedMaintenanceDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          <CCollapse
            label={`1. ${t(collapseNames.generic_info)}`}
            isSelected={openedCollapse == collapseNames.generic_info}
            onClick={() => _setOpenedCollapse(collapseNames.generic_info)}
          >
            <CInput
              label={t("departure_place")}
              placeholder={t("enter_departure_place")}
              value={maintenance.departurePlace}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    departurePlace: e.target.value,
                  })
                )
              }
            />
            <p className="text-[12px]">{t("departure_time")}</p>
            <CDateTimePicker
              value={maintenance.departureTime}
              onChange={(_) => {
                dispatch(
                  setMaintenanceValues({
                    departureTime: _,
                  })
                );
              }}
            />
            <p className="text-[12px]">{t("start_time")}</p>
            <CDateTimePicker
              value={maintenance.startTime}
              onChange={(_) => {
                dispatch(
                  setMaintenanceValues({
                    startTime: _,
                  })
                );
              }}
            />
            <p className="text-[12px]">{t("end_time")}</p>
            <CDateTimePicker
              value={maintenance.endTime}
              onChange={(_) => {
                dispatch(
                  setMaintenanceValues({
                    endTime: _,
                  })
                );
              }}
            />
            <CInput
              label={t("return_place")}
              placeholder={t("enter_return_place")}
              value={maintenance.returnPlace}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    returnPlace: e.target.value,
                  })
                )
              }
            />
            <p className="text-[12px]">{t("return_time")}</p>
            <CDateTimePicker
              value={maintenance.returnTime}
              onChange={(_) => {
                dispatch(
                  setMaintenanceValues({
                    returnTime: _,
                  })
                );
              }}
            />
            <CDropDown label={t("system_type")} value={maintenance.systemType}>
              {(locale == locales.italian
                ? ["Fotovoltaico - T.D.", "Rotex", "Altro"]
                : ["Photovoltaic - T.D.", "Rotex", "Other"]
              ).map((_) => (
                <CDropDownMenu
                  title={_}
                  isSelected={maintenance.systemType == _}
                  onClick={() =>
                    dispatch(
                      setMaintenanceValues({
                        systemType: _,
                      })
                    )
                  }
                />
              ))}
            </CDropDown>
            {maintenance.systemType == "Other" ||
            maintenance.systemType == "Altro" ? (
              <CInput
                label={t("system_type")}
                placeholder={t("enter_system_type")}
                value={maintenance.systemTypeOther}
                onChange={(e) =>
                  dispatch(
                    setMaintenanceValues({
                      systemTypeOther: e.target.value,
                    })
                  )
                }
              />
            ) : null}
            <CInput
              label={t("object_of_maintenance")}
              placeholder={t("enter_object_of_maintenance")}
              value={maintenance.objectOfMaintenance}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    objectOfMaintenance: e.target.value,
                  })
                )
              }
            />
            <CInput
              label={t("causes_of_fault")}
              placeholder={t("enter_causes_of_fault")}
              value={maintenance.causesOfFault}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    causesOfFault: e.target.value,
                  })
                )
              }
            />
            {isLoading && file == maintenance.causesOfFaultImage ? (
              <div className="h-[40px] w-[40px]">
                <CLoader />
              </div>
            ) : null}
            <CAddImage
              label={t("causes_of_fault_image")}
              image={maintenance.causesOfFaultImage}
              setImage={(_) =>
                dispatch(
                  setMaintenanceValues({
                    causesOfFaultImage: _,
                  })
                )
              }
            />
          </CCollapse>
          <div className="mb-[2%]" />
          <CCollapse
            label={`2. ${t(collapseNames.end_of_maintenance_checklist)}`}
            isSelected={true}
          >
            <CDropDown
              label={t("problem_resolved")}
              value={maintenance.eomProblemResolved}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={maintenance.eomProblemResolved == _}
                    onClick={() =>
                      dispatch(
                        setMaintenanceValues({
                          eomProblemResolved: _,
                        })
                      )
                    }
                  />
                )
              )}
            </CDropDown>
            <CInput
              label={t("problem_resolved_note")}
              placeholder={t("enter_problem_resolved_note")}
              value={maintenance.eomProblemResolvedNote}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    eomProblemResolvedNote: e.target.value,
                  })
                )
              }
            />
            <CDropDown
              label={t("another_maintenance_required")}
              value={maintenance.eomAnotherMaintenanceRequired}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={maintenance.eomAnotherMaintenanceRequired == _}
                    onClick={() =>
                      dispatch(
                        setMaintenanceValues({
                          eomAnotherMaintenanceRequired: _,
                        })
                      )
                    }
                  />
                )
              )}
            </CDropDown>
            <CInput
              label={t("another_maintenance_required_note")}
              placeholder={t("enter_another_maintenance_required_note")}
              value={maintenance.eomAnotherMaintenanceRequiredNote}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    eomAnotherMaintenanceRequiredNote: e.target.value,
                  })
                )
              }
            />
            <CDropDown
              label={t("replacement_material_used")}
              value={maintenance.eomReplacementMaterialUsed}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={maintenance.eomReplacementMaterialUsed == _}
                    onClick={() =>
                      dispatch(
                        setMaintenanceValues({
                          eomReplacementMaterialUsed: _,
                        })
                      )
                    }
                  />
                )
              )}
            </CDropDown>
            <CInput
              label={t("replacement_material_used_note")}
              placeholder={t("enter_replacement_material_used_note")}
              value={maintenance.eomReplacementMaterialUsedNote}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    eomReplacementMaterialUsedNote: e.target.value,
                  })
                )
              }
            />
            <CDropDown
              label={t("has_customer_seen_work")}
              value={maintenance.eomHasCustomerSeenWork}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={maintenance.eomHasCustomerSeenWork == _}
                    onClick={() =>
                      dispatch(
                        setMaintenanceValues({
                          eomHasCustomerSeenWork: _,
                        })
                      )
                    }
                  />
                )
              )}
            </CDropDown>
            <CInput
              label={t("has_customer_seen_work_note")}
              placeholder={t("enter_has_customer_seen_work_note")}
              value={maintenance.eomHasCustomerSeenWorkNote}
              onChange={(e) =>
                dispatch(
                  setMaintenanceValues({
                    eomHasCustomerSeenWorkNote: e.target.value,
                  })
                )
              }
            />
            <CDropDown
              label={t("no_of_photos_of_worked_carried_before")}
              value={maintenance.eomNoOfPhotosOfWorkedCarriedBefore}
            >
              {[0, ...Array.from({ length: 10 }, (_, index) => index + 1)].map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={
                      maintenance.eomNoOfPhotosOfWorkedCarriedBefore == _
                    }
                    onClick={() => {
                      dispatch(
                        setMaintenanceValues({
                          eomNoOfPhotosOfWorkedCarriedBefore: _,
                        })
                      );

                      if (
                        _ !=
                        Object.keys(maintenance.eomPhotosOfWorkedCarriedBefore)
                          .length
                      ) {
                        const obj = generateNullImagesObject(_);
                        dispatch(
                          setMaintenanceValues({
                            eomPhotosOfWorkedCarriedBefore: obj,
                          })
                        );
                      }
                    }}
                  />
                )
              )}
            </CDropDown>
            {maintenance.eomNoOfPhotosOfWorkedCarriedBefore > 0 ? (
              <>
                {isLoading &&
                Object.values(
                  maintenance.eomPhotosOfWorkedCarriedBefore
                ).includes(file) ? (
                  <div className="h-[40px] w-[40px]">
                    <CLoader />
                  </div>
                ) : null}
                <p className="text-[12px]">
                  {t("photos_of_worked_carried_before")}
                </p>
                <CCarousel>
                  {Object.keys(maintenance.eomPhotosOfWorkedCarriedBefore).map(
                    (key) => (
                      <CAddImage
                        label={`${parseInt(key) + 1}.`}
                        image={maintenance.eomPhotosOfWorkedCarriedBefore[key]}
                        setImage={(_) => {
                          const obj = {
                            ...maintenance.eomPhotosOfWorkedCarriedBefore,
                          };
                          obj[key] = _;

                          dispatch(
                            setMaintenanceValues({
                              eomPhotosOfWorkedCarriedBefore: obj,
                            })
                          );
                        }}
                      />
                    )
                  )}
                </CCarousel>
              </>
            ) : null}
            <CDropDown
              label={t("no_of_photos_of_worked_carried_after")}
              value={maintenance.eomNoOfPhotosOfWorkedCarriedAfter}
            >
              {[0, ...Array.from({ length: 10 }, (_, index) => index + 1)].map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={
                      maintenance.eomNoOfPhotosOfWorkedCarriedAfter == _
                    }
                    onClick={() => {
                      dispatch(
                        setMaintenanceValues({
                          eomNoOfPhotosOfWorkedCarriedAfter: _,
                        })
                      );

                      if (
                        _ !=
                        Object.keys(maintenance.eomPhotosOfWorkedCarriedAfter)
                          .length
                      ) {
                        const obj = generateNullImagesObject(_);
                        dispatch(
                          setMaintenanceValues({
                            eomPhotosOfWorkedCarriedAfter: obj,
                          })
                        );
                      }
                    }}
                  />
                )
              )}
            </CDropDown>
            {maintenance.eomNoOfPhotosOfWorkedCarriedAfter > 0 ? (
              <>
                {isLoading &&
                Object.values(
                  maintenance.eomPhotosOfWorkedCarriedAfter
                ).includes(file) ? (
                  <div className="h-[40px] w-[40px]">
                    <CLoader />
                  </div>
                ) : null}
                <p className="text-[12px]">
                  {t("photos_of_worked_carried_after")}
                </p>
                <CCarousel>
                  {Object.keys(maintenance.eomPhotosOfWorkedCarriedAfter).map(
                    (key) => (
                      <CAddImage
                        label={`${parseInt(key) + 1}.`}
                        image={maintenance.eomPhotosOfWorkedCarriedAfter[key]}
                        setImage={(_) => {
                          const obj = {
                            ...maintenance.eomPhotosOfWorkedCarriedAfter,
                          };
                          obj[key] = _;

                          dispatch(
                            setMaintenanceValues({
                              eomPhotosOfWorkedCarriedAfter: obj,
                            })
                          );
                        }}
                      />
                    )
                  )}
                </CCarousel>
              </>
            ) : null}
            <div className="w-[100%] flex flex-row items-center justify-between">
              <p className={`text-[12px]`}>{t("technician_signature")}</p>
              <img
                src={CancelSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={() => {
                  eomTechnicianSignatureRef?.current?.clear();
                }}
              />
            </div>
            <SignatureCanvas
              penColor="#F1622C"
              canvasProps={{
                className:
                  "min-h-[200px] w-[100%] border-[1px] border-[#000000] border-solid rounded-[10px]",
              }}
              ref={eomTechnicianSignatureRef}
            />
            <div className="w-[100%] flex flex-row items-center justify-between">
              <p className={`text-[12px]`}>{t("client_signature")}</p>
              <img
                src={CancelSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={() => {
                  eomClientSignatureRef?.current?.clear();
                }}
              />
            </div>
            <SignatureCanvas
              penColor="#F1622C"
              canvasProps={{
                className:
                  "min-h-[200px] w-[100%] border-[1px] border-[#000000] border-solid rounded-[10px]",
              }}
              ref={eomClientSignatureRef}
            />
          </CCollapse>
          <div className="flex-grow" />
          <CButton
            className="w-[100%] my-[14px]"
            text={t("continue")}
            isLoading={isLoading}
            onClick={async () => {
              await saveAndLoadDraft();

              const res = await dispatch(
                createMaintenance({
                  ...maintenanceState.toBody(),
                  type: maintenanceTypes.form,
                  maintenance_project_id: maintenanceProjectID,
                  getEOMClientSignature: getEOMClientSignature,
                  getEOMTechnicianSignature: getEOMTechnicianSignature,
                })
              );

              if (res?.payload) {
                navigate("/maintenance_projects");
              }
            }}
          />
          <CDialog
            name="modal_maintenance_draft"
            title={t("maintenance_draft_found")}
            description={t("do_you_want_to_load_data_from_the_saved_draft?")}
            approvalText={t("load_data")}
            onApproval={() => {
              dispatch(
                setMaintenance(
                  Maintenance.parse(
                    mergeObjects(maintenanceDraft.toMap(), maintenance.toMap())
                  )
                )
              );
              window.modal_maintenance_draft.close();
            }}
            rejectionText={t("delete_draft")}
            onRejection={() => {}}
          />
        </div>
      </div>
    </CContainer>
  );
}

export default SMaintenanceForm;
