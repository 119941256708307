import axios from "axios";
import toast from "react-hot-toast";
import localforage from "localforage";
import Compressor from "compressorjs";

import {
  AppResponse,
  AppUser,
  Project,
  MaintenanceProject,
  Inspection,
  Installation,
  Maintenance,
  PagesInfo,
  OtherProject,
  OtherProjectInspection,
  OtherProjectInstallation,
  Notification,
} from "./models";
import { appResponseActions } from "./constants";

const RequestTypes = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

// const serverURL = "http://localhost:4001";
const serverURL = "https://ultrasolar-dev-server.umarbinayaz.com";
// const serverURL = "https://server.ultrasolar.app";

async function login({ email, password }) {
  try {
    const url = `${serverURL}/auth/login`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { email, password },
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readProjects({ page_no }) {
  try {
    const url = `${serverURL}/projects/read/technicians`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const projects = res.data.array.map((_) => Project.parse(_));

      return [pagesInfo, projects];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readProject({ id }) {
  try {
    const url = `${serverURL}/projects/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const project = Project.parse(res.data);
      return project;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createInspection({
  draft,
  type,
  project_id,
  property_type,
  property_type_2,
  property_use,
  property_use_other,
  property_landscaping,
  es_meter,
  es_supply,
  es_supply_other,
  es_earthing_system,
  es_earthing_system_other,
  es_complaint_electrical_system,
  es_complaint_electrical_system_other,
  installation_type,
  installation_type_other,
  installation_deviation_from_south,
  installation_panel_inclination,
  cota_type,
  cota_type_other,
  cota_attic_type,
  cota_attic_type_other,
  cota_attic_height,
  cota_roof_access,
  cota_roof_access_other,
  cota_roof_measurement,
  cota_roof_picture,
  dotrapl_image,
  mr_no_of_profiles,
  mr_ac_cable,
  mr_dc_cable,
  mr_solar_cable,
  mr_blast_number,
  mr_no_of_triangles,
  mr_no_of_breakers_and_power,
  mr_extra,
  psf_power,
  psf_no_of_panels_and_types,
  psf_dc_cable,
  psf_dc_cable_no_of_strings,
  psf_ac_cable,
  psf_inverter_type,
  psf_inverter_position_image,
  psf_inverter_phase_type,
  psf_niche_for_production_counter,
  psf_system_connection_type,
  psf_system_anomalies_type,
  psf_system_anomalies,
  psf_battery_location_image,
  psf_ta_cable_length,
  psf_internet_connection,
  psf_internet_connection_type,
  psf_internet_connection_notes,
  psf_wifi_signals_near_inverter,
  pf_alternative_solution,
  extra_work,
  extra_images,
  consent_provider,
  consent_acceptance_date,
  consent_delegate_name,
  consent_delegate_attachment,
  consent_client_signature,
  page_1,
  page_2,
  page_3,
}) {
  try {
    const url = `${serverURL}/inspections/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        draft,
        type,
        project_id,
        property_type,
        property_type_2,
        property_use,
        property_use_other,
        property_landscaping,
        es_meter,
        es_supply,
        es_supply_other,
        es_earthing_system,
        es_earthing_system_other,
        es_complaint_electrical_system,
        es_complaint_electrical_system_other,
        installation_type,
        installation_type_other,
        installation_deviation_from_south,
        installation_panel_inclination,
        cota_type,
        cota_type_other,
        cota_attic_type,
        cota_attic_type_other,
        cota_attic_height,
        cota_roof_access,
        cota_roof_access_other,
        cota_roof_measurement,
        cota_roof_picture,
        dotrapl_image,
        mr_no_of_profiles,
        mr_ac_cable,
        mr_dc_cable,
        mr_solar_cable,
        mr_blast_number,
        mr_no_of_triangles,
        mr_no_of_breakers_and_power,
        mr_extra,
        psf_power,
        psf_no_of_panels_and_types,
        psf_dc_cable,
        psf_dc_cable_no_of_strings,
        psf_ac_cable,
        psf_inverter_type,
        psf_inverter_position_image,
        psf_inverter_phase_type,
        psf_niche_for_production_counter,
        psf_system_connection_type,
        psf_system_anomalies_type,
        psf_system_anomalies,
        psf_battery_location_image,
        psf_ta_cable_length,
        psf_internet_connection,
        psf_internet_connection_type,
        psf_internet_connection_notes,
        psf_wifi_signals_near_inverter,
        pf_alternative_solution,
        extra_work,
        extra_images,
        consent_provider,
        consent_acceptance_date,
        consent_delegate_name,
        consent_delegate_attachment,
        consent_client_signature,
        page_1,
        page_2,
        page_3,
      },
    });

    if (res.success) {
      return draft ? Inspection.parse(res.data) : res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createInstallation({
  draft,
  project_id,
  type,
  eow_customer_present,
  eow_construction_end_date,
  eow_missing_jobs,
  eoi_photovoltaic_field_images,
  eoi_technical_room_images,
  eoi_no_of_panels_installed,
  eoi_panel_label_images,
  eoi_inverter_label_images,
  eoi_battery_installed,
  eoi_battery_serial_no_images,
  eoi_self_test,
  eoi_no_of_self_test_images,
  eoi_self_test_images,
  declaration_notatory_declarations_of_end_of_work,
  consent_client_name,
  consent_acceptance_date,
  consent_client_signature,
  page_1,
}) {
  try {
    const url = `${serverURL}/installations/create`;

    const headers = await _headers();

    const body = {
      draft,
      project_id,
      type,
      eow_customer_present,
      eow_construction_end_date,
      eow_missing_jobs,
      eoi_photovoltaic_field_images,
      eoi_technical_room_images,
      eoi_no_of_panels_installed,
      eoi_panel_label_images,
      eoi_inverter_label_images,
      eoi_battery_installed,
      eoi_battery_serial_no_images,
      eoi_self_test,
      eoi_no_of_self_test_images,
      eoi_self_test_images,
      declaration_notatory_declarations_of_end_of_work,
      consent_client_name,
      consent_acceptance_date,
      consent_client_signature,
      page_1,
    };

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: body,
    });

    if (res.success) {
      return draft ? Installation.parse(res.data) : res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function compress(image) {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 0.4,
      success: resolve,
      error: reject,
    });
  });
}

async function uploadFile({ name, file }) {
  try {
    const url = `${serverURL}/files/upload`;

    const headers = await _headers();

    const _file = await compress(file);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", _file);

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: formData,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readInspectionDraft({ project_id, type }) {
  try {
    const url = `${serverURL}/inspection_drafts/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { project_id, type },
    });

    if (res.success) {
      if (res.data) {
        const inspectionDraft = Inspection.parse(res.data);
        return inspectionDraft;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readInstallationDraft({ project_id, type }) {
  try {
    const url = `${serverURL}/installation_drafts/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { project_id, type },
    });

    if (res.success) {
      if (res.data) {
        const installationDraft = Installation.parse(res.data);
        return installationDraft;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function resetPassword({ old_password, new_password }) {
  try {
    const url = `${serverURL}/auth/reset_password`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { old_password, new_password },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword1({ email }) {
  try {
    const url = `${serverURL}/auth/forgot_password_1`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        email,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword2({ email, code }) {
  try {
    const url = `${serverURL}/auth/forgot_password_2`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        email,
        code,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword3({ email, password }) {
  try {
    const url = `${serverURL}/auth/forgot_password_3`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        email,
        password,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readMaintenanceDraft({ maintenance_project_id, type }) {
  try {
    const url = `${serverURL}/maintenance_drafts/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { maintenance_project_id, type },
    });

    if (res.success) {
      if (res.data) {
        const maintenanceDraft = Maintenance.parse(res.data);
        return maintenanceDraft;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createMaintenance({
  draft,
  maintenance_project_id,
  type,
  departure_place,
  departure_time,
  start_time,
  end_time,
  return_place,
  return_time,
  system_type,
  system_type_other,
  object_of_maintenance,
  causes_of_fault,
  causes_of_fault_image,
  eom_problem_resolved,
  eom_problem_resolved_note,
  eom_another_maintenance_required,
  eom_another_maintenance_required_note,
  eom_replacement_material_used,
  eom_replacement_material_used_note,
  eom_has_customer_seen_work,
  eom_has_customer_seen_work_note,
  eom_no_of_photos_of_worked_carried_before,
  eom_photos_of_worked_carried_before,
  eom_no_of_photos_of_worked_carried_after,
  eom_photos_of_worked_carried_after,
  eom_technician_signature,
  eom_client_signature,
  page_1,
}) {
  try {
    const url = `${serverURL}/maintenances/create`;

    const headers = await _headers();

    const body = {
      draft,
      maintenance_project_id,
      type,
      departure_place,
      departure_time,
      start_time,
      end_time,
      return_place,
      return_time,
      system_type,
      system_type_other,
      object_of_maintenance,
      causes_of_fault,
      causes_of_fault_image,
      eom_problem_resolved,
      eom_problem_resolved_note,
      eom_another_maintenance_required,
      eom_another_maintenance_required_note,
      eom_replacement_material_used,
      eom_replacement_material_used_note,
      eom_has_customer_seen_work,
      eom_has_customer_seen_work_note,
      eom_no_of_photos_of_worked_carried_before,
      eom_photos_of_worked_carried_before,
      eom_no_of_photos_of_worked_carried_after,
      eom_photos_of_worked_carried_after,
      eom_technician_signature,
      eom_client_signature,
      page_1,
    };

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: body,
    });

    if (res.success) {
      return draft ? Maintenance.parse(res.data) : res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readMaintenanceProjects({ page_no }) {
  try {
    const url = `${serverURL}/maintenance_projects/read/technicians`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const maintenanceProjects = res.data.array.map((_) =>
        MaintenanceProject.parse(_)
      );

      return [pagesInfo, maintenanceProjects];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readMaintenanceProject({ id }) {
  try {
    const url = `${serverURL}/maintenance_projects/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const maintenanceProject = MaintenanceProject.parse(res.data);
      return maintenanceProject;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readOtherProjects({ page_no }) {
  try {
    const url = `${serverURL}/other_projects/read/technicians`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const otherProjects = res.data.array.map((_) => OtherProject.parse(_));

      return [pagesInfo, otherProjects];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readOtherProject({ id }) {
  try {
    const url = `${serverURL}/other_projects/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const otherProject = OtherProject.parse(res.data);
      return otherProject;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createOtherProjectInspection({
  draft,
  type,
  other_project_id,
  property_type,
  property_type_2,
  property_use,
  property_use_other,
  property_landscaping,
  it_type,
  it_type_other,
  tr_images,
  extra_work,
  extra_images,
  consent_provider,
  consent_acceptance_date,
  consent_delegate_name,
  consent_delegate_attachment,
  consent_client_signature,
  page_1,
  page_2,
  page_3,
}) {
  try {
    const url = `${serverURL}/other_project_inspections/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        draft,
        type,
        other_project_id,
        property_type,
        property_type_2,
        property_use,
        property_use_other,
        property_landscaping,
        it_type,
        it_type_other,
        tr_images,
        extra_work,
        extra_images,
        consent_provider,
        consent_acceptance_date,
        consent_delegate_name,
        consent_delegate_attachment,
        consent_client_signature,
        page_1,
        page_2,
        page_3,
      },
    });

    if (res.success) {
      return draft ? OtherProjectInspection.parse(res.data) : res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createOtherProjectInstallation({
  draft,
  other_project_id,
  type,
  eow_customer_present,
  eow_construction_end_date,
  eow_missing_jobs,
  images,
  notes,
  declaration_notatory_declarations_of_end_of_work,
  consent_client_name,
  consent_acceptance_date,
  consent_client_signature,
  page_1,
}) {
  try {
    const url = `${serverURL}/other_project_installations/create`;

    const headers = await _headers();

    const body = {
      draft,
      other_project_id,
      type,
      eow_customer_present,
      eow_construction_end_date,
      eow_missing_jobs,
      images,
      notes,
      declaration_notatory_declarations_of_end_of_work,
      consent_client_name,
      consent_acceptance_date,
      consent_client_signature,
      page_1,
    };

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: body,
    });

    if (res.success) {
      return draft ? OtherProjectInstallation.parse(res.data) : res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readOtherProjectInspectionDraft({ other_project_id, type }) {
  try {
    const url = `${serverURL}/other_project_inspection_drafts/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { other_project_id, type },
    });

    if (res.success) {
      if (res.data) {
        const otherProjectInspectionDraft = OtherProjectInspection.parse(
          res.data
        );
        return otherProjectInspectionDraft;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readOtherProjectInstallationDraft({ other_project_id, type }) {
  try {
    const url = `${serverURL}/other_project_installation_drafts/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { other_project_id, type },
    });

    if (res.success) {
      if (res.data) {
        const otherProjectInstallationDraft = OtherProjectInstallation.parse(
          res.data
        );
        return otherProjectInstallationDraft;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readNotifications({ page_no, is_archived }) {
  try {
    const url = `${serverURL}/notifications/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const notifications = res.data.array.map((_) => Notification.parse(_));

      return [pagesInfo, notifications];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateNotification({ id, is_archived }) {
  try {
    const url = `${serverURL}/notifications/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function _request({ requestType, url, headers, body, orotund = true }) {
  let appResponse;

  try {
    const res = await axios({
      method: requestType,
      url: url,
      data: body,
      headers: headers,
    });

    const data = res.data;

    appResponse = AppResponse.parse(data);

    if (appResponse.action == appResponseActions.logout) {
      localStorage.removeItem("AppStorage/appUser");
      window.location = "/";
      toast.error("Session expired. Login again.");
    }
  } catch (e) {
    appResponse = AppResponse.failure();
  }

  if (!appResponse.success && orotund) {
    toast.error(appResponse.errorMessage);
  }

  return appResponse;
}

async function _headers(props = { parseAppUser: true }) {
  const obj = {};

  obj["x-access-token"] = `Basic ${process.env.REACT_APP_X_ACCESS_TOKEN}`;

  if (props.parseAppUser) {
    const appUser = await localforage.getItem("appUser");

    if (appUser != null) {
      obj["authorization"] = `Bearer ${appUser.token}`;
    }
  }

  return obj;
}

export {
  login,
  readProjects,
  readMaintenanceProjects,
  readProject,
  readMaintenanceProject,
  createInspection,
  createInstallation,
  createMaintenance,
  uploadFile,
  readInspectionDraft,
  readInstallationDraft,
  readMaintenanceDraft,
  resetPassword,
  forgotPassword1,
  forgotPassword2,
  forgotPassword3,
  readOtherProjects,
  readOtherProject,
  createOtherProjectInspection,
  createOtherProjectInstallation,
  readOtherProjectInspectionDraft,
  readOtherProjectInstallationDraft,
  readNotifications,
  updateNotification,
};
