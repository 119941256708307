import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";

import { forgotPassword1, forgotPassword2 } from "../controllers/auth";

import CButton from "../components/button";
import toast from "react-hot-toast";

const timers = [];

function SForgotPassword2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const inputElement = useRef(null);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [inputElement]);

  const locationData = location.state ?? {};

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [code, setCode] = useState(null);
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    if (!locationData.email) {
      navigate(-1);
      return;
    }

    initTimer();
  };

  const initTimer = () => {
    const timer = setInterval(() => {
      setSeconds((s) => s - 1);
    }, 1000);
    timers.push(timer);
  };

  useEffect(() => {
    if (seconds <= 0) {
      for (let i = 0; i < timers.length; i++) {
        clearInterval(timers[0]);
      }
      setSeconds(0);
    }
  }, [seconds]);

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">
          {t("forgot_password")}
        </p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <div className="h-[2%]" />
          <p className="text-[14px] font-[600] self-center">
            {t("please_enter_the_code_that_has_been_emailed_to_you.")}
          </p>
          <div className="h-[10%]" />
          <PinInput
            length={4}
            initialValue={code}
            onChange={(value, index) => {
              setCode(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "10px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            inputStyle={{
              borderColor: "#F1622C",
              borderRadius: "5px",
              margin: "0 5%",
            }}
            inputFocusStyle={{
              borderColor: "#F1622C",
              borderRadius: "5px",
            }}
            autoSelect={true}
            ref={inputElement}
          />
          <div className="h-[10%]" />
          <p
            className="text-[14px] text-primary font-[600] self-center cursor-pointer"
            onClick={async () => {
              if (seconds == 0) {
                const res = await dispatch(
                  forgotPassword1({
                    email: locationData.email,
                  })
                );
                setSeconds(59);
                initTimer();
              }
            }}
          >
            {`${t("resend")} ${seconds == 0 ? "" : `in ${seconds}s`}`}
          </p>
          <div className="flex-grow" />
          <CButton
            className="w-[100%] my-[14px]"
            text={t("continue")}
            onClick={async () => {
              if (code.length < 4) {
                toast.error(t("Code can't be empty."));
                return;
              }
              const res = await dispatch(
                forgotPassword2({
                  email: locationData.email,
                  code: code,
                })
              );
              if (res.payload) {
                navigate("/forgot_password_3", {
                  state: { email: locationData.email },
                });
              }
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default SForgotPassword2;
