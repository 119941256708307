import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  createOtherProjectInspection,
  readOtherProject,
} from "../controllers/other_projects";
import {
  readOtherProjectInspectionDraft,
  setOtherProjectInspectionDraft,
  setOtherProjectInspection,
  setOtherProjectInspectionValues,
  createOtherProjectInspectionDraft,
} from "../controllers/other_project_inspection_drafts";

import PlusSVG from "../assets/images/plus.svg";
import SaveSVG from "../assets/images/save.svg";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CAddImage from "../components/add_image";
import CButton from "../components/button";
import CDialog from "../components/dialog";

import { otherProjectInspectionTypes } from "../utils/constants";
import { uploadNamedFileOrParseMaker, mergeObjects } from "../utils/misc";
import { OtherProjectInspection } from "../utils/models";

function SOtherProjectInspectionImages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const otheProjectID = searchParams.get("other_project_id");

  const [
    isSavingOtherProjectInspectionDraft,
    setIsSavingOtherProjectInspectionDraft,
  ] = useState(false);

  const isLoading = useSelector((state) => state.otherProjects.isLoading);
  const otherProject = useSelector((state) => state.otherProjects.otherProject);
  const file = useSelector((state) => state.files.file);
  const isLoadingInspectionDraft = useSelector(
    (state) => state.otherProjectInspectionDrafts.isLoading
  );
  const otherProjectInspectionDraft = useSelector(
    (state) => state.otherProjectInspectionDrafts.otherProjectInspectionDraft
  );
  const otherProjectInspectionState = useSelector(
    (state) => state.otherProjectInspectionDrafts.otherProjectInspection
  );

  const otherProjectInspection = useMemo(() => {
    return OtherProjectInspection.parse(otherProjectInspectionState.toMap());
  }, [otherProjectInspectionState]);

  const [hasLoadedInspectionDraft, setHasLoadedInspectionDraft] =
    useState(false);

  // Load data
  useEffect(() => {
    if (otheProjectID) {
      dispatch(
        readOtherProjectInspectionDraft({
          other_project_id: otheProjectID,
          type: otherProjectInspectionTypes.images,
        })
      );
      dispatch(
        readOtherProject({
          id: otheProjectID,
        })
      );
    }
  }, [otheProjectID]);

  // Display load draft data
  useEffect(() => {
    if (
      otherProjectInspectionDraft &&
      otherProjectInspectionDraft.type == otherProjectInspectionTypes.images
    ) {
      window.modal_inspection_draft.showModal();
    }
  }, [otherProjectInspectionDraft]);

  // Display load draft data
  useEffect(() => {
    if (
      !hasLoadedInspectionDraft &&
      otherProjectInspectionDraft &&
      otherProjectInspectionDraft.type == otherProjectInspectionTypes.images
    ) {
      window.modal_inspection_draft.showModal();
      setHasLoadedInspectionDraft(true);
    }
  }, [otherProjectInspectionDraft]);

  // Dismount
  useEffect(() => {
    return () => {
      setOtherProjectInspectionDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${otherProject?.client?.firstName} - ${otherProject?.client?.lastName} - Other Project Inspection`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingOtherProjectInspectionDraft(true);

    const page1 = await uploadNamedFileOrParse({
      file: otherProjectInspection.page1,
      name: "Page 1",
    });
    const page2 = await uploadNamedFileOrParse({
      file: otherProjectInspection.page2,
      name: "Page 2",
    });
    const page3 = await uploadNamedFileOrParse({
      file: otherProjectInspection.page3,
      name: "Page 3",
    });

    dispatch(
      setOtherProjectInspectionValues({
        page1,
        page2,
        page3,
      })
    );

    const _otherProjectInspectionDraft = await dispatch(
      createOtherProjectInspectionDraft({
        ...otherProjectInspectionState.toBody(),
        orotund,
        type: otherProjectInspectionTypes.images,
        other_project_id: otheProjectID,
      })
    );

    dispatch(
      setOtherProjectInspection(
        OtherProjectInspection.parse(
          mergeObjects(
            _otherProjectInspectionDraft.payload.toMap(),
            otherProjectInspection.toMap()
          )
        )
      )
    );

    setIsSavingOtherProjectInspectionDraft(false);

    return;
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("inspection")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingInspectionDraft ||
              isSavingOtherProjectInspectionDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedInspectionDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          {isLoading && file == otherProjectInspection.page1 ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("page_1")}
            image={otherProjectInspection.page1}
            setImage={(_) =>
              dispatch(
                setOtherProjectInspectionValues({
                  page1: _,
                })
              )
            }
          />
          <div className="h-[2%]" />
          {isLoading && file == otherProjectInspection.page2 ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("page_2")}
            image={otherProjectInspection.page2}
            setImage={(_) =>
              dispatch(
                setOtherProjectInspectionValues({
                  page2: _,
                })
              )
            }
          />
          <div className="h-[2%]" />
          {isLoading && file == otherProjectInspection.page3 ? (
            <div className="h-[40px] w-[40px]">
              <CLoader />
            </div>
          ) : null}
          <CAddImage
            label={t("page_3")}
            image={otherProjectInspection.page3}
            setImage={(_) =>
              dispatch(
                setOtherProjectInspectionValues({
                  page3: _,
                })
              )
            }
          />
          <div className="flex-grow" />
          <CButton
            className="w-[100%] my-[14px]"
            text="Continue"
            isLoading={isLoading}
            onClick={async () => {
              await saveAndLoadDraft();

              const res = await dispatch(
                createOtherProjectInspection({
                  ...otherProjectInspectionState.toBody(),
                  type: otherProjectInspectionTypes.images,
                  other_project_id: otheProjectID,
                })
              );

              if (res?.payload) {
                navigate("/");
              }
            }}
          />
          <CDialog
            name="modal_inspection_draft"
            title={t("inspection_draft_found")}
            description={t("do_you_want_to_load_data_from_the_saved_draft?")}
            approvalText={t("load_data")}
            onApproval={() => {
              dispatch(
                setOtherProjectInspection(
                  OtherProjectInspection.parse(
                    mergeObjects(
                      otherProjectInspectionDraft.toMap(),
                      otherProjectInspection.toMap()
                    )
                  )
                )
              );

              window.modal_inspection_draft.close();
            }}
            rejectionText={t("delete_draft")}
            onRejection={() => {}}
          />
        </div>
      </div>
    </CContainer>
  );
}

export default SOtherProjectInspectionImages;
