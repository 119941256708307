import localforage from "localforage";

import { filesURLSplitSequence, splitSequence } from "./constants";
import { arrayToObject, booleanToString } from "./misc";

class AppResponse {
  success;
  data;
  error;
  errorMessage;
  action;

  constructor(props) {
    Object.assign(this, props);
  }

  static failure({ errorMessage = "Something went off the rails." }) {
    const obj = {
      success: false,
      errorMessage: errorMessage,
    };

    return new AppResponse(obj);
  }

  static parse(map = {}) {
    const obj = {
      success: map["success"] ?? false,
      data: map["data"],
      error: map["error"],
      errorMessage: map["error_message"],
      action: map["action"],
    };

    return new AppResponse(obj);
  }
}

class AppUser {
  id;
  firstName;
  lastName;
  email;
  uuid;
  token;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map = {}) {
    const obj = {
      id: map["id"],
      firstName: map["first_name"],
      lastName: map["last_name"],
      email: map["email"],
      uuid: map["uuid"],
      token: map["token"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new AppUser(obj);
  }

  cache = async () => {
    await localforage.setItem("appUser", this);
  };
}

class Project {
  id;
  address;
  powerKit;
  title;
  description;
  installationKit;
  estimate;
  inspection;
  installation;
  status;
  uuid;
  createdAt;
  updatedAt;

  as;
  client;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map = {}) {
    const obj = {
      id: map["id"],
      address: map["address"],
      powerKit: map["power_kit"],
      title: map["title"],
      description: map["description"],
      installationKit: map["installation_kit"],
      estimate: map["estimate"],
      inspection: map["inspection"],
      installation: map["installation"],
      status: map["status"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      as: map["as"],
      client: map["client"] ? Client.parse(map["client"]) : null,
    };

    return new Project(obj);
  }
}

class Client {
  id;
  firstName;
  lastName;
  email;
  phone;
  uuid;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map = {}) {
    const obj = {
      id: map["id"],
      firstName: map["first_name"],
      lastName: map["last_name"],
      email: map["email"],
      phone: map["phone"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new Client(obj);
  }
}

class Inspection {
  id;
  type;
  projectID;
  propertyType;
  propertyType2;
  propertyUse;
  propertyUseOther;
  propertyLandscaping;
  esMeter;
  esSupply;
  esSupplyOther;
  esEarthingSystem;
  esEarthingSystemOther;
  esComplaintElectricalSystem;
  esComplaintElectricalSystemOther;
  installationType;
  installationTypeOther;
  installationDeviationFromSouth;
  installationPanelInclination;
  cotaType;
  cotaTypeOther;
  cotaAtticType;
  cotaAtticTypeOther;
  cotaAtticHeight;
  cotaRoofAccess;
  cotaRoofAccessOther;
  cotaRoofMeasurement;
  cotaRoofPicture;
  dotraplImage;
  mrNoOfProfiles;
  mrACCable;
  mrDCCable;
  mrSolarCable;
  mrBlastNumber;
  mrNoOfTriangles;
  mrNoOfBreakersAndPower;
  mrExtra;
  psfPower;
  psfNoOfPanelsAndTypes;
  psfDCCable;
  psfDCCableNoOfStrings;
  psfACCable;
  psfInverterType;
  psfInverterPositionImage;
  psfInverterPhaseType;
  psfNicheForProductionCounter;
  psfSystemConnectionType;
  psfSystemAnomaliesType;
  psfSystemAnomalies;
  psfBatteryLocationImage;
  psfTACableLength;
  psfInternetConnection;
  psfInternetConnectionType;
  psfInternetConnectionNotes;
  psfWifiSignalsNearInverter;
  pfAlternativeSolution;
  extraWork;
  consentProvider;
  consentAcceptanceDate;
  consentDelegateName;
  consentDelegateAttachment;
  extraImages;
  page1;
  page2;
  page3;
  uuid;
  createdAt;
  updatedAt;

  project;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map = {}) {
    const obj = {
      id: map["id"],
      type: map["type"],
      projectID: map["project_id"],
      propertyType: map["property_type"],
      propertyType2: map["property_type_2"],
      propertyUse: map["property_use"],
      propertyUseOther: map["property_use_other"],
      propertyLandscaping: map["property_landscaping"],
      esMeter: map["es_meter"],
      esSupply: map["es_supply"],
      esSupplyOther: map["es_supply_other"],
      esEarthingSystem: map["es_earthing_system"],
      esEarthingSystemOther: map["es_earthing_system_other"],
      esComplaintElectricalSystem: map["es_complaint_electrical_system"],
      esComplaintElectricalSystemOther:
        map["es_complaint_electrical_system_other"],
      installationType: map["installation_type"],
      installationTypeOther: map["installation_type_other"],
      installationDeviationFromSouth: map["installation_deviation_from_south"],
      installationPanelInclination: map["installation_panel_inclination"],
      cotaType: map["cota_type"],
      cotaTypeOther: map["cota_type_other"],
      cotaAtticType: map["cota_attic_type"],
      cotaAtticTypeOther: map["cota_attic_type_other"],
      cotaAtticHeight: map["cota_attic_height"],
      cotaRoofAccess: map["cota_roof_access"],
      cotaRoofAccessOther: map["cota_roof_access_other"],
      cotaRoofMeasurement: map["cota_roof_measurement"],
      cotaRoofPicture: map["cota_roof_picture"],
      dotraplImage: map["dotrapl_image"],
      mrNoOfProfiles: map["mr_no_of_profiles"],
      mrACCable: map["mr_ac_cable"],
      mrDCCable: map["mr_dc_cable"],
      mrSolarCable: map["mr_solar_cable"],
      mrBlastNumber: map["mr_blast_number"],
      mrNoOfTriangles: map["mr_no_of_triangles"],
      mrNoOfBreakersAndPower: map["mr_no_of_breakers_and_power"],
      mrExtra: map["mr_extra"],
      psfPower: map["psf_power"],
      psfNoOfPanelsAndTypes: map["psf_no_of_panels_and_types"],
      psfDCCable: map["psf_dc_cable"],
      psfDCCableNoOfStrings: map["psf_dc_cable_no_of_strings"],
      psfACCable: map["psf_ac_cable"],
      psfInverterType: map["psf_inverter_type"],
      psfInverterPositionImage: map["psf_inverter_position_image"],
      psfInverterPhaseType: map["psf_inverter_phase_type"],
      psfNicheForProductionCounter: map["psf_niche_for_production_counter"],
      psfSystemConnectionType: map["psf_system_connection_type"],
      psfSystemAnomaliesType: map["psf_system_anomalies_type"],
      psfSystemAnomalies: map["psf_system_anomalies"],
      psfBatteryLocationImage: map["psf_battery_location_image"],
      psfTACableLength: map["psf_ta_cable_length"],
      psfInternetConnection: map["psf_internet_connection"],
      psfInternetConnectionType: map["psf_internet_connection_type"],
      psfInternetConnectionNotes: map["psf_internet_connection_notes"],
      psfWifiSignalsNearInverter: map["psf_wifi_signals_near_inverter"],
      pfAlternativeSolution: map["pf_alternative_solution"],
      extraWork: map["extra_work"],
      extraImages: map["extra_images"] ?? [],
      consentProvider: map["consent_provider"],
      consentAcceptanceDate: map["consent_acceptance_date"],
      consentDelegateName: map["consent_delegate_name"],
      consentDelegateAttachment: map["consent_delegate_attachment"],
      page1: map["page_1"],
      page2: map["page_2"],
      page3: map["page_3"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      project: map["project"] ? Project.parse(map["project"]) : null,
    };

    return new Inspection(obj);
  }

  toMap() {
    return {
      id: this.id,
      type: this.type,
      project_id: this.projectID,
      property_type: this.propertyType,
      property_type_2: this.propertyType2,
      property_use: this.propertyUse,
      property_use_other: this.propertyUseOther,
      property_landscaping: this.propertyLandscaping,
      es_meter: this.esMeter,
      es_supply: this.esSupply,
      es_supply_other: this.esSupplyOther,
      es_earthing_system: this.esEarthingSystem,
      es_earthing_system_other: this.esEarthingSystemOther,
      es_complaint_electrical_system: this.esComplaintElectricalSystem,
      es_complaint_electrical_system_other:
        this.esComplaintElectricalSystemOther,
      installation_type: this.installationType,
      installation_type_other: this.installationTypeOther,
      installation_deviation_from_south: this.installationDeviationFromSouth,
      installation_panel_inclination: this.installationPanelInclination,
      cota_type: this.cotaType,
      cota_type_other: this.cotaTypeOther,
      cota_attic_type: this.cotaAtticType,
      cota_attic_type_other: this.cotaAtticTypeOther,
      cota_attic_height: this.cotaAtticHeight,
      cota_roof_access: this.cotaRoofAccess,
      cota_roof_access_other: this.cotaRoofAccessOther,
      cota_roof_measurement: this.cotaRoofMeasurement,
      cota_roof_picture: this.cotaRoofPicture,
      dotrapl_image: this.dotraplImage,
      mr_no_of_profiles: this.mrNoOfProfiles,
      mr_ac_cable: this.mrACCable,
      mr_dc_cable: this.mrDCCable,
      mr_solar_cable: this.mrSolarCable,
      mr_blast_number: this.mrBlastNumber,
      mr_no_of_triangles: this.mrNoOfTriangles,
      mr_no_of_breakers_and_power: this.mrNoOfBreakersAndPower,
      mr_extra: this.mrExtra,
      psf_power: this.psfPower,
      psf_no_of_panels_and_types: this.psfNoOfPanelsAndTypes,
      psf_dc_cable: this.psfDCCable,
      psf_dc_cable_no_of_strings: this.psfDCCableNoOfStrings,
      psf_ac_cable: this.psfACCable,
      psf_inverter_type: this.psfInverterType,
      psf_inverter_position_image: this.psfInverterPositionImage,
      psf_inverter_phase_type: this.psfInverterPhaseType,
      psf_niche_for_production_counter: this.psfNicheForProductionCounter,
      psf_system_connection_type: this.psfSystemConnectionType,
      psf_system_anomalies_type: this.psfSystemAnomaliesType,
      psf_system_anomalies: this.psfSystemAnomalies,
      psf_battery_location_image: this.psfBatteryLocationImage,
      psf_ta_cable_length: this.psfTACableLength,
      psf_internet_connection: this.psfInternetConnection,
      psf_internet_connection_type: this.psfInternetConnectionType,
      psf_internet_connection_notes: this.psfInternetConnectionNotes,
      psf_wifi_signals_near_inverter: this.psfWifiSignalsNearInverter,
      pf_alternative_solution: this.pfAlternativeSolution,
      extra_work: this.extraWork,
      extra_images: this.extraImages,
      consent_provider: this.consentProvider,
      consent_acceptance_date: this.consentAcceptanceDate,
      consent_delegate_name: this.consentDelegateName,
      consent_delegate_attachment: this.consentDelegateAttachment,
      page_1: this.page1,
      page_2: this.page2,
      page_3: this.page3,
    };
  }

  toBody() {
    return {
      ...this.toMap(),
      cota_roof_picture: this.cotaRoofPicture
        ?.split(filesURLSplitSequence)
        ?.pop(),
      dotrapl_image: this.dotraplImage?.split(filesURLSplitSequence)?.pop(),
      psf_inverter_position_image: this.psfInverterPositionImage
        ?.split(filesURLSplitSequence)
        ?.pop(),
      psf_battery_location_image: this.psfBatteryLocationImage
        ?.split(filesURLSplitSequence)
        ?.pop(),
      extra_images: this.extraImages.map((_) => ({
        ..._,
        image: _.image.split(filesURLSplitSequence)[1],
      })),
      consent_delegate_attachment: this.consentDelegateAttachment
        ?.split(filesURLSplitSequence)
        ?.pop(),
      page_1: this.page1?.split(filesURLSplitSequence)?.pop(),
      page_2: this.page2?.split(filesURLSplitSequence)?.pop(),
      page_3: this.page3?.split(filesURLSplitSequence)?.pop(),
    };
  }
}

class Installation {
  id;
  type;
  projectID;
  eowCustomerPresent;
  eowConstructionEndDate;
  eowMissingJobs;
  eoiPhotovoltaicFieldImages;
  eoiTechnicalRoomImages;
  eoiNoOfPanelsInstalled;
  eoiPanelLabelImages;
  eoiInverterLabelImages;
  eoiBatteryInstalled;
  eoiBatterySerialNoImages;
  eoiSelfTest;
  eoiNoOfSelfTestImages;
  eoiSelfTestImages;
  declarationNotatoryDeclarationsOfEndOfWork;
  consentClientName;
  consentAcceptanceDate;
  consentClientSignature;
  page1;
  uuid;
  createdAt;
  updatedAt;

  project;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map = {}) {
    const obj = {
      id: map["id"],
      type: map["type"],
      projectID: map["project_id"],
      eowCustomerPresent: map["eow_customer_present"],
      eowConstructionEndDate: map["eow_construction_end_date"],
      eowMissingJobs: map["eow_missing_jobs"],
      eoiPhotovoltaicFieldImages: map["eoi_photovoltaic_field_images"]
        ? parseImagesField(map["eoi_photovoltaic_field_images"], 2)
        : generateNullImagesObject(2),
      eoiTechnicalRoomImages: map["eoi_technical_room_images"]
        ? parseImagesField(map["eoi_technical_room_images"], 2)
        : generateNullImagesObject(2),
      eoiNoOfPanelsInstalled: map["eoi_no_of_panels_installed"] ?? 0,
      eoiPanelLabelImages: map["eoi_panel_label_images"]
        ? parseImagesField(
            map["eoi_panel_label_images"],
            map["eoi_no_of_panels_installed"]
          )
        : generateNullImagesObject(map["eoi_no_of_panels_installed"] ?? 0),
      eoiInverterLabelImages: map["eoi_inverter_label_images"]
        ? parseImagesField(map["eoi_inverter_label_images"], 2)
        : generateNullImagesObject(2),
      eoiBatteryInstalled: map["eoi_battery_installed"],
      eoiBatterySerialNoImages: map["eoi_battery_serial_no_images"]
        ? parseImagesField(
            map["eoi_battery_serial_no_images"],
            ["Yes", "SÌ"].includes(map["eoi_battery_installed"]) ? 2 : 0
          )
        : generateNullImagesObject(
            ["Yes", "SÌ"].includes(map["eoi_battery_installed"]) ? 2 : 0
          ),
      eoiSelfTest: map["eoi_self_test"],
      eoiNoOfSelfTestImages: map["eoi_no_of_self_test_images"] ?? 0,
      eoiSelfTestImages: map["eoi_self_test_images"]
        ? parseImagesField(
            map["eoi_self_test_images"],
            map["eoi_no_of_self_test_images"]
          )
        : generateNullImagesObject(map["eoi_no_of_self_test_images"] ?? 0),
      declarationNotatoryDeclarationsOfEndOfWork:
        map["declaration_notatory_declarations_of_end_of_work"],
      consentClientName: map["consent_client_name"],
      consentAcceptanceDate: map["consent_acceptance_date"],
      consentClientSignature: map["consent_client_signature"],
      page1: map["page_1"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      project: map["project"] ? Project.parse(map["project"]) : null,
    };

    return new Installation(obj);
  }

  toMap() {
    return {
      id: this.id,
      type: this.type,
      project_id: this.projectID,
      eow_customer_present: this.eowCustomerPresent,
      eow_construction_end_date: this.eowConstructionEndDate,
      eow_missing_jobs: this.eowMissingJobs,
      eoi_photovoltaic_field_images: this.eoiPhotovoltaicFieldImages,
      eoi_technical_room_images: this.eoiTechnicalRoomImages,
      eoi_no_of_panels_installed: this.eoiNoOfPanelsInstalled,
      eoi_panel_label_images: this.eoiPanelLabelImages,
      eoi_inverter_label_images: this.eoiInverterLabelImages,
      eoi_battery_installed: this.eoiBatteryInstalled,
      eoi_battery_serial_no_images: this.eoiBatterySerialNoImages,
      eoi_self_test: this.eoiSelfTest,
      eoi_no_of_self_test_images: this.eoiNoOfSelfTestImages,
      eoi_self_test_images: this.eoiSelfTestImages,
      declaration_notatory_declarations_of_end_of_work:
        this.declarationNotatoryDeclarationsOfEndOfWork,
      consent_client_name: this.consentClientName,
      consent_acceptance_date: this.consentAcceptanceDate,
      consent_client_signature: this.consentClientSignature,
      page_1: this.page1,
    };
  }

  toBody() {
    return {
      ...this.toMap(),
      eoi_photovoltaic_field_images: parseImagesForBody(
        this.eoiPhotovoltaicFieldImages
      ),
      eoi_technical_room_images: parseImagesForBody(
        this.eoiTechnicalRoomImages
      ),
      eoi_panel_label_images: parseImagesForBody(this.eoiPanelLabelImages),
      eoi_inverter_label_images: parseImagesForBody(
        this.eoiInverterLabelImages
      ),
      eoi_battery_serial_no_images: parseImagesForBody(
        this.eoiBatterySerialNoImages
      ),
      eoi_self_test_images: parseImagesForBody(this.eoiSelfTestImages),
      page_1: this.page1?.split(filesURLSplitSequence)?.pop(),
    };
  }
}

class MaintenanceProject {
  id;
  address;
  title;
  description;
  maintenance;
  status;
  uuid;
  isArchived;
  createdAt;
  updatedAt;

  project;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map = {}) {
    const obj = {
      id: map["id"],
      address: map["address"],
      title: map["title"],
      description: map["description"],
      maintenance: map["maintenance"],
      status: map["status"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      client: map["client"] ? Client.parse(map["client"]) : null,
    };

    return new MaintenanceProject(obj);
  }
}

class Maintenance {
  id;
  type;
  departurePlace;
  departureTime;
  startTime;
  endTime;
  returnPlace;
  returnTime;
  systemType;
  systemTypeOther;
  objectOfMaintenance;
  causesOfFault;
  causesOfFaultImage;
  eomProblemResolved;
  eomProblemResolvedNote;
  eomAnotherMaintenanceRequired;
  eomAnotherMaintenanceRequiredNote;
  eomReplacementMaterialUsed;
  eomReplacementMaterialUsedNote;
  eomHasCustomerSeenWork;
  eomHasCustomerSeenWorkNote;
  eomNoOfPhotosOfWorkedCarriedBefore;
  eomPhotosOfWorkedCarriedBefore;
  eomNoOfPhotosOfWorkedCarriedAfter;
  eomPhotosOfWorkedCarriedAfter;
  eomTechnicianSignature;
  eomClientSignature;
  page1;
  uuid;
  createdAt;
  updatedAt;

  maintenanceProject;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      type: map["type"],
      departurePlace: map["departure_place"],
      departureTime: map["departure_time"],
      startTime: map["start_time"],
      endTime: map["end_time"],
      returnPlace: map["return_place"],
      returnTime: map["return_time"],
      systemType: map["system_type"],
      systemTypeOther: map["system_type_other"],
      objectOfMaintenance: map["object_of_maintenance"],
      causesOfFault: map["causes_of_fault"],
      causesOfFaultImage: map["causes_of_fault_image"],
      eomProblemResolved: map["eom_problem_resolved"],
      eomProblemResolvedNote: map["eom_problem_resolved_note"],
      eomAnotherMaintenanceRequired: map["eom_another_maintenance_required"],
      eomAnotherMaintenanceRequiredNote:
        map["eom_another_maintenance_required_note"],
      eomReplacementMaterialUsed: map["eom_replacement_material_used"],
      eomReplacementMaterialUsedNote: map["eom_replacement_material_used_note"],
      eomHasCustomerSeenWork: map["eom_has_customer_seen_work"],
      eomHasCustomerSeenWorkNote: map["eom_has_customer_seen_work_note"],
      eomNoOfPhotosOfWorkedCarriedBefore:
        map["eom_no_of_photos_of_worked_carried_before"],
      eomPhotosOfWorkedCarriedBefore: map["eom_photos_of_worked_carried_before"]
        ? parseImagesField(
            map["eom_photos_of_worked_carried_before"],
            map["eom_no_of_photos_of_worked_carried_before"]
          )
        : generateNullImagesObject(
            map["eom_no_of_photos_of_worked_carried_before"] ?? 0
          ),
      eomNoOfPhotosOfWorkedCarriedAfter:
        map["eom_no_of_photos_of_worked_carried_after"],
      eomPhotosOfWorkedCarriedAfter: map["eom_photos_of_worked_carried_after"]
        ? parseImagesField(
            map["eom_photos_of_worked_carried_after"],
            map["eom_photos_of_worked_carried_after"]
          )
        : generateNullImagesObject(
            map["eom_photos_of_worked_carried_after"] ?? 0
          ),
      eomTechnicianSignature: map["eom_technician_Signature"],
      eomClientSignature: map["eom_client_signature"],
      page1: map["page_1"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      maintenanceProject: map["maintenance_project"]
        ? MaintenanceProject.parse(map["maintenance_project"])
        : null,
    };

    return new Maintenance(obj);
  }

  toMap() {
    return {
      id: this.id,
      type: this.type,
      departure_place: this.departurePlace,
      departure_time: this.departureTime,
      start_time: this.startTime,
      end_time: this.endTime,
      return_place: this.returnPlace,
      return_time: this.returnTime,
      system_type: this.systemType,
      system_type_other: this.systemTypeOther,
      object_of_maintenance: this.objectOfMaintenance,
      causes_of_fault: this.causesOfFault,
      causes_of_fault_image: this.causesOfFaultImage,
      eom_problem_resolved: this.eomProblemResolved,
      eom_problem_resolved_note: this.eomProblemResolvedNote,
      eom_another_maintenance_required: this.eomAnotherMaintenanceRequired,
      eom_another_maintenance_required_note:
        this.eomAnotherMaintenanceRequiredNote,
      eom_replacement_material_used: this.eomReplacementMaterialUsed,
      eom_replacement_material_used_note: this.eomReplacementMaterialUsedNote,
      eom_has_customer_seen_work: this.eomHasCustomerSeenWork,
      eom_has_customer_seen_work_note: this.eomHasCustomerSeenWorkNote,
      eom_no_of_photos_of_worked_carried_before:
        this.eomNoOfPhotosOfWorkedCarriedBefore,
      eom_photos_of_worked_carried_before: this.eomPhotosOfWorkedCarriedBefore,
      eom_no_of_photos_of_worked_carried_after:
        this.eomNoOfPhotosOfWorkedCarriedAfter,
      eom_photos_of_worked_carried_after: this.eomPhotosOfWorkedCarriedAfter,
      eom_technician_Signature: this.eomTechnicianSignature,
      eom_client_signature: this.eomClientSignature,
      page_1: this.page1,
    };
  }

  toBody() {
    return {
      ...this.toMap(),
      causes_of_fault_image: this.causesOfFaultImage
        ?.split(filesURLSplitSequence)
        ?.pop(),
      eom_photos_of_worked_carried_before: parseImagesForBody(
        this.eomPhotosOfWorkedCarriedBefore
      ),
      eom_photos_of_worked_carried_after: parseImagesForBody(
        this.eomPhotosOfWorkedCarriedAfter
      ),
      eom_technician_Signature: this.eomTechnicianSignature
        ?.split(filesURLSplitSequence)
        ?.pop(),
      eom_client_signature: this.eomClientSignature
        ?.split(filesURLSplitSequence)
        ?.pop(),
      page_1: this.page1?.split(filesURLSplitSequence)?.pop(),
    };
  }
}

class PagesInfo {
  current;
  total;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      current: map["current"],
      total: map["total"],
    };

    return new PagesInfo(obj);
  }
}

function parseImagesField(field, length = 0) {
  let parsedField;

  if (!field) {
    parsedField = null;
  } else if (typeof field == "object") {
    return field;
  } else if (typeof field == "string") {
    parsedField = arrayToObject(field.split(splitSequence));

    if (length) {
      const objLength = Object.keys(parsedField).length;

      if (length > objLength) {
        const nullValuesLength = length - objLength;

        for (let i = nullValuesLength; i < length; i++) {
          parsedField[i] = null;
        }
      }
    }
  }

  return parsedField;
}

function generateNullImagesObject(length) {
  const obj = {};
  for (let i = 0; i < length; i++) {
    obj[i] = null;
  }
  return obj;
}

function parseImagesForBody(images) {
  if (!images) {
    return null;
  }

  const obj = Object.values(images)
    .map((_) => _?.split(filesURLSplitSequence)?.pop())
    .join(splitSequence);

  return obj;
}

class OtherProject {
  id;
  address;
  powerKit;
  title;
  description;
  installationKit;
  estimate;
  inspection;
  installation;
  status;
  uuid;
  isArchived;
  createdAt;
  updatedAt;

  as;
  client;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      address: map["address"],
      powerKit: map["power_kit"],
      title: map["title"],
      description: map["description"],
      installationKit: map["installation_kit"],
      estimate: map["estimate"],
      inspection: map["inspection"] ? new Date(map["inspection"]) : null,
      installation: map["installation"] ? new Date(map["installation"]) : null,
      status: map["status"],
      uuid: map["uuid"],
      isArchived: map["is_archived"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,

      as: map["as"],
      client: map["client"] ? Client.parse(map["client"]) : null,
    };

    return new OtherProject(obj);
  }
}

class OtherProjectInspection {
  id;
  type;
  propertyType;
  propertyType2;
  propertyUse;
  propertyUseOther;
  propertyLandscaping;
  itType;
  itTypeOther;
  trImages;
  extraWork;
  extraImages;
  consentProvider;
  consentAcceptanceDate;
  consentDelegateName;
  consentDelegateAttachment;
  consentClientSignature;
  page1;
  page2;
  page3;
  uuid;
  createdAt;
  updatedAt;

  otherProject;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      type: map["type"],
      propertyType: map["property_type"],
      propertyType2: map["property_type_2"],
      propertyUse: map["property_use"],
      propertyUseOther: map["property_use_other"],
      propertyLandscaping: map["property_landscaping"],
      itType: map["it_type"],
      itTypeOther: map["it_type_other"],
      trImages: map["tr_images"] ?? [],
      extraWork: map["extra_work"],
      extraImages: map["extra_images"] ?? [],
      consentProvider: map["consent_provider"],
      consentAcceptanceDate: map["consent_acceptance_date"]
        ? new Date(map["consent_acceptance_date"])
        : null,
      consentDelegateName: map["consent_delegate_name"],
      consentDelegateAttachment: map["consent_delegate_attachment"],
      consentClientSignature: map["consent_client_signature"],
      page1: map["page_1"],
      page2: map["page_2"],
      page3: map["page_3"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      otherProject: map["other_project"]
        ? OtherProject.parse(map["other_project"])
        : null,
    };

    return new OtherProjectInspection(obj);
  }

  toMap() {
    return {
      id: this.id,
      type: this.type,
      project_id: this.projectID,
      property_type: this.propertyType,
      property_type_2: this.propertyType2,
      property_use: this.propertyUse,
      property_use_other: this.propertyUseOther,
      property_landscaping: this.propertyLandscaping,
      it_type: this.itType,
      it_type_other: this.itTypeOther,
      tr_images: this.trImages,
      extra_work: this.extraWork,
      extra_images: this.extraImages,
      consent_provider: this.consentProvider,
      consent_acceptance_date: this.consentAcceptanceDate,
      consent_delegate_name: this.consentDelegateName,
      consent_delegate_attachment: this.consentDelegateAttachment,
      page_1: this.page1,
      page_2: this.page2,
      page_3: this.page3,
    };
  }

  toBody() {
    return {
      ...this.toMap(),
      tr_images: this.trImages.map((_) => ({
        ..._,
        image: _.image.split(filesURLSplitSequence)[1],
      })),
      extra_images: this.extraImages.map((_) => ({
        ..._,
        image: _.image.split(filesURLSplitSequence)[1],
      })),
      consent_delegate_attachment: this.consentDelegateAttachment
        ?.split(filesURLSplitSequence)
        ?.pop(),
      page_1: this.page1?.split(filesURLSplitSequence)?.pop(),
      page_2: this.page2?.split(filesURLSplitSequence)?.pop(),
      page_3: this.page3?.split(filesURLSplitSequence)?.pop(),
    };
  }
}

class OtherProjectInstallation {
  id;
  type;
  eowCustomerPresent;
  eowConstructionEndDate;
  eowMissingJobs;
  images;
  notes;
  declarationNotatoryDeclarationsOfEndOfWork;
  consentClientName;
  consentAcceptanceDate;
  consentClientSignature;
  page1;
  uuid;
  createdAt;
  updatedAt;

  otherProject;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      type: map["type"],
      eowCustomerPresent: map["eow_customer_present"],
      eowConstructionEndDate: map["eow_construction_end_date"],
      eowMissingJobs: map["eow_missing_jobs"],
      images: map["images"] ?? [],
      notes: map["notes"],
      declarationNotatoryDeclarationsOfEndOfWork:
        map["declaration_notatory_declarations_of_end_of_work"],
      consentClientName: map["consent_client_name"],
      consentAcceptanceDate: map["consent_acceptance_date"],
      consentClientSignature: map["consent_client_signature"],
      page1: map["page_1"],
      uuid: map["uuid"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      otherProject: map["other_project"]
        ? OtherProject.parse(map["other_project"])
        : null,
    };

    return new OtherProjectInstallation(obj);
  }

  toMap() {
    return {
      id: this.id,
      type: this.type,
      project_id: this.projectID,
      eow_customer_present: this.eowCustomerPresent,
      eow_construction_end_date: this.eowConstructionEndDate,
      eow_missing_jobs: this.eowMissingJobs,
      images: this.images,
      notes: this.notes,
      declaration_notatory_declarations_of_end_of_work:
        this.declarationNotatoryDeclarationsOfEndOfWork,
      consent_client_name: this.consentClientName,
      consent_acceptance_date: this.consentAcceptanceDate,
      consent_client_signature: this.consentClientSignature,
      page_1: this.page1,
    };
  }

  toBody() {
    return {
      ...this.toMap(),
      images: this.images.map((_) => ({
        ..._,
        image: _.image.split(filesURLSplitSequence)[1],
      })),
      page_1: this.page1?.split(filesURLSplitSequence)?.pop(),
    };
  }
}

class Notification {
  id;
  title;
  type;
  inspectionAssignmentProjectID;
  installationAssignmentProjectID;
  isRead;
  isArchived;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      type: map["type"],
      inspectionAssignmentProjectID: map["inspection_assignment_project_id"],
      installationAssignmentProjectID:
        map["installation_assignment_project_id"],
      isRead: map["is_read"],
      isArchived: map["is_archived"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,
    };

    return new Notification(obj);
  }
}

class NotificationsFilter {
  isArchived;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse({ title = null, isArchived = null }) {
    let _isArchived = isArchived;
    if (typeof _isArchived == "boolean") {
      _isArchived = booleanToString(isArchived);
    }

    const obj = {
      isArchived: _isArchived,
    };

    return new NotificationsFilter(obj);
  }
}

export {
  AppResponse,
  AppUser,
  Project,
  Inspection,
  Installation,
  parseImagesField,
  generateNullImagesObject,
  MaintenanceProject,
  Maintenance,
  PagesInfo,
  OtherProject,
  OtherProjectInspection,
  OtherProjectInstallation,
  Notification,
  NotificationsFilter,
};
