import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import { createInstallation, readProject } from "../controllers/projects";
import {
  createInstallationDraft,
  readInstallationDraft,
  setInstallation,
  setInstallationDraft,
  setInstallationValues,
} from "../controllers/installation_drafts";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";
import CAddImage from "../components/add_image";
import CDialog from "../components/dialog";
import { useTranslation } from "react-i18next";

import SaveSVG from "../assets/images/save.svg";

import { mergeObjects, uploadNamedFileOrParseMaker } from "../utils/misc";
import { filesURLSplitSequence, installationTypes } from "../utils/constants";
import { Installation } from "../utils/models";

function SInstallationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get("project_id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.projects.isLoading);
  const project = useSelector((state) => state.projects.project);
  const file = useSelector((state) => state.files.file);
  const isLoadingInstallationDraft = useSelector(
    (state) => state.installationDrafts.isLoading
  );
  const [hasLoadedInstallationDraft, setHasLoadedInstallationDraft] =
    useState(false);
  const installationDraft = useSelector(
    (state) => state.installationDrafts.installationDraft
  );
  const installationState = useSelector(
    (state) => state.installationDrafts.installation
  );

  const [isSavingInstallationDraft, setIsSavingInstallationDraft] =
    useState(false);

  const installation = useMemo(() => {
    return Installation.parse(installationState.toMap());
  }, [installationState]);

  useEffect(() => {
    if (projectID) {
      dispatch(
        readProject({
          id: projectID,
        })
      );
    }
  }, [projectID]);

  useEffect(() => {
    if (
      !hasLoadedInstallationDraft &&
      installationDraft &&
      installationDraft.type == installationTypes.images
    ) {
      window.modal_installation_draft.showModal();
      setHasLoadedInstallationDraft(true);
    }
  }, [installationDraft]);

  useEffect(() => {
    dispatch(
      readInstallationDraft({
        project_id: projectID,
        type: installationTypes.images,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      setInstallationDraft(null);
    };
  }, []);

  const uploadNamedFileOrParse = uploadNamedFileOrParseMaker({
    dispatch,
    preName: `${project?.client?.firstName} - ${project?.client?.lastName} - Installation`,
  });

  async function saveAndLoadDraft({ orotund = false } = {}) {
    setIsSavingInstallationDraft(true);

    let page1 = await uploadNamedFileOrParse({
      file: installation.page1,
      name: t("page_1"),
    });

    dispatch(
      setInstallationValues({
        page1,
      })
    );

    const _installationDraft = await dispatch(
      createInstallationDraft({
        ...installationState.toBody(),
        orotund,
        type: installationTypes.images,
        project_id: projectID,
      })
    );

    dispatch(
      setInstallation(
        Installation.parse(
          mergeObjects(_installationDraft.payload.toMap(), installation.toMap())
        )
      )
    );

    setIsSavingInstallationDraft(false);

    return;
  }

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll relative">
          <div class="flex flex-row items-center justify-between bg-background px-[1rem] py-[1rem] fixed sm:w-[76.8%] w-[96%] z-[10]">
            <p className="text-[20px] font-[500] self-center">
              {t("installation")}
            </p>
            <div className="flex flex-row items-center justify-center">
              {isLoadingInstallationDraft || isSavingInstallationDraft ? (
                <>
                  <div className="flex flex-row items-center justify-center w-[30px] h-[30px]">
                    <span className="loading loading-ring loading-md"></span>
                  </div>
                  <div className="ml-[0.5rem]" />
                </>
              ) : null}
              <img
                src={SaveSVG}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={async () => {
                  setHasLoadedInstallationDraft(true);
                  saveAndLoadDraft({
                    orotund: true,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-[4rem]" />
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            {isLoading && file == installation.page1 ? (
              <div className="h-[40px] w-[40px]">
                <CLoader />
              </div>
            ) : null}
            <CAddImage
              label={t("page_1")}
              image={installation.page1}
              setImage={(_) =>
                dispatch(
                  setInstallationValues({
                    page1: _,
                  })
                )
              }
            />
            <div className="flex-grow" />
            <CButton
              className="w-[100%] my-[14px]"
              text={t("continue")}
              isLoading={isLoading}
              onClick={async () => {
                await saveAndLoadDraft();

                const res = await dispatch(
                  createInstallation({
                    ...installationState.toBody(),
                    type: installationTypes.images,
                    project_id: projectID,
                  })
                );

                if (res?.payload) {
                  navigate("/");
                }
              }}
            />
            <CDialog
              name="modal_installation_draft"
              title={t("installation_draft_found")}
              description={t("do_you_want_to_load_data_from_the_saved_draft?")}
              approvalText={t("load_data")}
              onApproval={() => {
                dispatch(
                  setInstallation(
                    mergeObjects(
                      installationDraft.toMap(),
                      installation.toMap()
                    )
                  )
                );
                window.modal_installation_draft.close();
              }}
              rejectionText={t("delete_draft")}
              onRejection={() => {}}
            />
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SInstallationForm;
