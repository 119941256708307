function CInput({
  label,
  placeholder,
  value,
  enableEyes,
  className,
  pClassName,
  inputClassName,
  style,
  onChange,
  onKeyDown,
  type,
  disabled,
}) {
  return (
    <div
      className={`flex flex-col justify-start items-start w-[100%] ${
        className ?? ""
      }`}
      style={style}
    >
      {label ? <p className={`text-sm ${pClassName ?? ""}`}>{label}</p> : <></>}
      <input
        className={`w-[100%] bg-[#F3F6FF] mt-[0.3rem] mb-[0.6rem] p-[0.6rem] text-[0.8rem] outline-0 rounded-[1rem] ${
          inputClassName ?? ""
        }`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
    </div>
  );
}

export default CInput;
