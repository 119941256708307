import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import { i18n } from "../utils/i18n";
import { Inspection } from "../utils/models";

const initialState = {
  isLoading: false,
  inspectionDraft: null,
  inspection: Inspection.parse({}),
};

const readInspectionDraft = createAsyncThunk(
  "inspectionDrafts/readInspectionDraft",
  async (args, { getState }) => {
    const { project_id, type } = args;

    try {
      const inspectionDraft = await AppNetworkRest.readInspectionDraft(args);
      return inspectionDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createInspectionDraft = createAsyncThunk(
  "inspectionDrafts/createInspectionDraft",
  async (args, { getState }) => {
    try {
      const { orotund } = args ?? {};

      const inspectionDraft = await AppNetworkRest.createInspection({
        ...args,
        draft: true,
      });

      if (inspectionDraft && orotund) {
        toast.success(i18n.t("inspection_draft_saved_successfully."));
      }

      return inspectionDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const inspectionDraftsSlice = createSlice({
  name: "inspectionDrafts",
  initialState,
  reducers: {
    setInspectionDraft: (state, action) => {
      state.inspectionDraft = action.payload;
    },
    setInspection: (state, action) => {
      state.inspection = action.payload;
    },
    setInspectionValues: (state, action) => {
      const keys = Object.keys(action.payload ?? {});
      const values = Object.values(action.payload ?? {});

      for (let i = 0; i < keys.length; i++) {
        state.inspection[keys[i]] = values[i];
      }

      state.inspection = Inspection.parse(state.inspection.toMap());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readInspectionDraft.fulfilled, (state, action) => {
      if (action.payload) {
        state.inspectionDraft = action.payload;
      }
    });
    builder.addCase(createInspectionDraft.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createInspectionDraft.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setInspectionDraft, setInspection, setInspectionValues } =
  inspectionDraftsSlice.actions;

export { readInspectionDraft, createInspectionDraft };

export default inspectionDraftsSlice.reducer;
