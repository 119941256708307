import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import { i18n } from "../utils/i18n";
import { Maintenance } from "../utils/models";

const initialState = {
  isLoading: false,
  maintenanceDraft: null,
  maintenance: Maintenance.parse({}),
};

const readMaintenanceDraft = createAsyncThunk(
  "maintenanceDrafts/readMaintenanceDraft",
  async (args, { getState }) => {
    const { maintenance_project_id, type } = args;

    try {
      const maintenanceDraft = await AppNetworkRest.readMaintenanceDraft(args);
      return maintenanceDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createMaintenanceDraft = createAsyncThunk(
  "maintenanceDrafts/createMaintenanceDraft",
  async (args, { getState }) => {
    try {
      const { orotund } = args ?? {};

      const maintenanceDraft = await AppNetworkRest.createMaintenance({
        draft: true,
        ...args,
      });

      if (maintenanceDraft && orotund) {
        toast.success(i18n.t("maintenance_draft_saved_successfully."));
      }

      return maintenanceDraft;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const maintenanceDraftsSlice = createSlice({
  name: "maintenanceDrafts",
  initialState,
  reducers: {
    setMaintenanceDraft: (state, action) => {
      state.maintenanceDraft = action.payload;
    },
    setMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    setMaintenanceValues: (state, action) => {
      const keys = Object.keys(action.payload ?? {});
      const values = Object.values(action.payload ?? {});

      for (let i = 0; i < keys.length; i++) {
        state.maintenance[keys[i]] = values[i];
      }

      state.maintenance = Maintenance.parse(state.maintenance.toMap());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readMaintenanceDraft.fulfilled, (state, action) => {
      if (action.payload) {
        state.maintenanceDraft = action.payload;
      }
    });
    builder.addCase(createMaintenanceDraft.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createMaintenanceDraft.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setMaintenanceDraft, setMaintenance, setMaintenanceValues } =
  maintenanceDraftsSlice.actions;

export { readMaintenanceDraft, createMaintenanceDraft };

export default maintenanceDraftsSlice.reducer;
