function CButton({ text, icon, onClick, isLoading, className, style }) {
  return (
    <div
      className={`flex flex-row items-center justify-center bg-primary text-white rounded-[2rem] px-[0.5rem] py-[0.5rem] cursor-pointer ${className}`}
      onClick={!isLoading ? onClick : null}
      style={style}
    >
      {!isLoading ? (
        <div className="flex flex-row items-center justify-center">
          {icon ? (
            <>
              <img src={icon} className="h-[1.2rem]" />{" "}
              <div className="mr-[0.5rem]" />
            </>
          ) : null}
          <p className="text-[1rem] whitespace-nowrap">{text}</p>
        </div>
      ) : (
        <div className="loading loading-spinner loading-sm"></div>
      )}
    </div>
  );
}

export default CButton;
