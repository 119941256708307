import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  maintenanceProjectStatuses,
  dateTimeFormatter,
} from "../utils/constants";

import { setSelectedRouteIndex } from "../controllers/control_bar";
import { readMaintenanceProject } from "../controllers/maintenance_projects";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import MapSVG from "../assets/images/map.svg";
import FormSVG from "../assets/images/form.svg";
import ImageSVG from "../assets/images/image.svg";

function SMaintenanceProjectInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.maintenanceProjects.isLoading);
  const maintenanceProject = useSelector(
    (state) => state.maintenanceProjects.maintenanceProject
  );

  useEffect(() => {
    if (id) {
      dispatch(
        readMaintenanceProject({
          id,
        })
      );
    }
  }, [id]);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(1));
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">
            {t("maintenance_info")}
          </p>
          <div className="h-[20px]" />
          {!maintenanceProject ? (
            <CLoader />
          ) : (
            <CMaintenanceProject maintenanceProject={maintenanceProject} />
          )}
        </div>
        <MMaintenanceType maintenanceProject={maintenanceProject} />
      </div>
    </CContainer>
  );
}

function CMaintenanceProject({ maintenanceProject }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col p-[10px] w-[100%] bg-slate-100 rounded-[10px] cursor-pointer">
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[600]">{maintenanceProject.title}</p>
        <p
          className={`text-[16px] font-[600] ${
            maintenanceProject.status == maintenanceProjectStatuses.created
              ? "text-[#FF9900]"
              : maintenanceProject.status ==
                maintenanceProjectStatuses.submitted
              ? "text-[#4A86E8]"
              : ""
          }`}
        >
          {maintenanceProject.status.toUpperCase()}
        </p>
      </div>
      <div className="flex flex-row items-center justify-between w-[100%]">
        <p className="text-[16px] font-[600] w-[80%]">
          {maintenanceProject.address}
        </p>
        <img
          src={MapSVG}
          className="h-[30px] w-[30px]"
          onClick={() => {
            window.open(
              `https://maps.google.com/?q=${maintenanceProject.address}`
            );
          }}
        />
      </div>
      <div className="h-[18dvh] overflow-scroll">
        <p className="text-[18px] font-[500]">
          {maintenanceProject.description}
        </p>
      </div>
      <div className="h-[0.5px] w-[100%] bg-black" />
      <div className="flex flex-row justify-between items-center w-[100%]">
        <p className="text-[18px] font-[500]">{t("maintenance")}</p>
        <p className="text-[16px] font-[500]">
          {maintenanceProject.maintenance
            ? dateTimeFormatter.format(new Date(maintenanceProject.maintenance))
            : "N/A"}
        </p>
      </div>
      {maintenanceProject.status == maintenanceProjectStatuses.created ||
      maintenanceProject.status == maintenanceProjectStatuses.assigned ? (
        <>
          <div className="h-[20px]" />
          <CButton
            text={t("submit_maintenance")}
            onClick={() => {
              window.modal_maintenance_type.showModal();
            }}
          />
        </>
      ) : null}
    </div>
  );
}

const MMaintenanceType = ({ maintenanceProject }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <dialog id="modal_maintenance_type" className="modal">
      <form
        method="dialog"
        className="flex items-center justify-center modal-box bg-white min-h-[60dvh] min-w-[40dvw]"
      >
        <div className="flex flex-col items-start justify-center bg-white p-[2%] overflow-scroll h-[100%] w-[100%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(
                  `/maintenance_form?maintenance_project_id=${maintenanceProject.id}`
                );
              }}
            >
              <img src={FormSVG} className="h-[50px] w-[50px]" />
              <p>{t("fill_out")}</p>
            </div>
            <div className="w-[20px]" />
            <div
              className="flex flex-col items-center justify-center h-[40dvh] w-[40dvw] rounded-[10px] bg-[#D3D3D3]"
              onClick={() => {
                navigate(
                  `/maintenance_images?maintenance_project_id=${maintenanceProject.id}`
                );
              }}
            >
              <img src={ImageSVG} className="h-[50px] w-[50px]" />
              <p>{t("images")}</p>
            </div>
          </div>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default SMaintenanceProjectInfo;
