import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { login } from "../controllers/auth";

import CInput from "../components/input";
import CButton from "../components/button";

function SLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const onClick = async () => {
    const res = await dispatch(
      login({
        email,
        password,
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">{t("login")}</p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <div className="h-[2%]" />
          <CInput
            label={t("email")}
            type="email"
            className="mb-[14px] mt-[5px]"
            placeholder={t("enter_your_email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="h-[2%]" />
          <CInput
            label={t("password")}
            type="password"
            className="mb-[14px] mt-[5px]"
            placeholder={t("enter_your_password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                onClick();
              }
            }}
          />
          <div className="flex-grow" />
          <CButton
            className="my-[14px] w-[100%]"
            text={t("continue")}
            onClick={onClick}
            isLoading={isLoading}
          />
          <div className="mt-[2%]" />
          <p
            className="text-[14px] text-primary font-[700] self-center cursor-pointer"
            onClick={async () => {
              navigate("/forgot_password_1");
            }}
          >
            {t("forgot_password")}?
          </p>
          <div className="mt-[2%]" />
        </div>
      </div>
    </div>
  );
}

export default SLogin;
